<template>
    <!-- Cabeza -->
    <section id="logInSection">
        <div class="m-0 p-0 background_Alava d-flex flex-column fontAdn" style="min-height: 100vh; height: 100%;">
            <div class="d-flex col-lg-12 col-md-12 col-11 text-start p-3 flex-column flex-sm-row">
                <div class="col-sm-2 d-flex align-items-center justify-content-center" id="logo">
                    <img title="Locker" src="@/assets/img/logo-ADN.png" @click="this.$router.push('/')" class="img-fluid m-auto"
                        style="max-width: 193px; max-height: 60px; aspect-ratio: auto 193 / 100;">
                </div>
                <div id="topMenu" class="border-bottom col-sm-10 d-flex align-items-center justify-content-sm-end justify-content-center p-3"
                    style="border-color: #d8d8d8;">
                    <div>
                        <ion-icon id="home" name="home-outline" @click="this.$router.push('/')" class="tx-28 pt-1 pe-1 tx-black text-end"></ion-icon>
                    </div>
                    <div v-for="(language, index) in languages" :key="index">
                        <span v-if="language !== sessionLanguage" @click="toggleLanguage(language)"
                            class="text-end text-black tx-25 text-uppercase p-1 language">{{ language }}</span>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center ">
                <div v-if="!orderDetails" class="box justify-content-center align-items-center col-lg-8 col-md-12 col-12 m-0 p-3 pt-0 pb-0 pt-0 d-none d-md-block">
                    <div class="row justify-content-center align-items-center m-0 p-0">
                        <img title="Logo" src="@/assets/img/locker-personas.png" class="img-fluid m-auto"   style="max-height: 700px; max-width: 700px;">
                    </div>
                </div>

                <div v-if="orderDetails" id="orderResume" class="col-lg-8 col-md-12 col-12 p-3 pt-4-md mt-4-md  pt-4-lg mt-4-lg ">
                    <div class="login-box border-1 border-black box rounded-5">
                        <div class="card-body pt-2 p-3 rounded-5 ">
                            <div class="col-md-12">
                                <div class="ms-auto me-auto mt-3 form-group text-center rounded-5">
                                    <h5 class="text-black text-decoration-underline">{{ dictionary["Su pedido"][sessionLanguage] }}</h5>
                                </div>
                                <div class="ms-auto me-auto mt-3 form-group text-center rounded-5">
                                    <p class="text-muted tx-12">{{ dictionary["Situación de su pedido"][sessionLanguage] }}</p>
                                </div>
                            </div>
                            <!-- Table  -->
                            <div id="formLogIn" class="form-horizontal text-center">
                                <div class="form-group p-3 mt-0">
                                    <span class="tx-14 text-black">{{ errorMsg }}</span><br>
                                    <div class="col-xs-10">
                                        <!-- Tabla para mostrar los datos -->
                                        <table class="table mt-3 text-black">
                                            <tbody>
                                                <tr >
                                                    <td class="text-end" >{{ dictionary["Localización"][sessionLanguage] }}</td>
                                                    <td>{{ orderDetails.lockerEmplacement!=""? orderDetails.lockerEmplacement: "-" }}</td>
                                                </tr>
                                                <tr v-if="orderDetails.lockerLabel">
                                                    <td class="text-end">{{ dictionary["Locker"][sessionLanguage] }}</td>
                                                    <td>{{ orderDetails.lockerLabel }}</td>
                                                </tr>
                                                <tr v-if="orderDetails.idOrder">
                                                    <td class="text-end">{{ dictionary["ID pedido"][sessionLanguage] }}</td>
                                                    <td>{{ orderDetails.idOrder }}</td>
                                                </tr>
                                                <tr v-if="orderDetails.id">
                                                    <td class="text-end">{{ dictionary["ID reserva"][sessionLanguage] }}</td>
                                                    <td>{{ orderDetails.id }}</td>
                                                </tr>
                                                <tr v-if="orderDetails.scheduledDate">
                                                    <td class="text-end">{{ dictionary["Fecha prevista"][sessionLanguage] }}</td>
                                                    <td>{{ orderDetails.scheduledDate }}</td>
                                                </tr>
                                                <tr>
                                                    <td style="border: none;"></td>
                                                    <td style="border: none;"></td>
                                                </tr>
                                                <tr>
                                                    <td style="border: none;"></td>
                                                    <td style="border: none;"></td>
                                                </tr>
                                                <tr>
                                                    <td style="border: none;"></td>
                                                    <td style="border: none;"></td>
                                                </tr>
                                               
                                                <tr>
                                                    <td style="border: none;"></td>
                                                    <td style="border: none;"></td>
                                                </tr>
                                                <tr>
                                                    <td style="border: none;"></td>
                                                    <td style="border: none;"></td>
                                                </tr>
                                                <tr>
                                                    <td style="border: none;"></td>
                                                    <td style="border: none;"></td>
                                                </tr>
                                                <tr v-if="orderDetails.numBundle">
                                                    <td class="text-end">{{ dictionary["Número de bultos"][sessionLanguage] }}</td>
                                                    <td>{{ orderDetails.numBundle }}</td>
                                                </tr>
                                                <tr v-if="orderDetails.providerEmail">
                                                    <td class="text-end">{{ dictionary["E-mail"][sessionLanguage] }} {{ dictionary["Proveedor"][sessionLanguage] }}</td>
                                                    <td>{{ orderDetails.providerEmail }}</td>
                                                </tr>
                                                <tr v-if="orderDetails.observations">
                                                    <td class="text-end">{{ dictionary["Observaciones"][sessionLanguage] }}</td>
                                                    <td>{{ orderDetails.observations }}</td>
                                                </tr>
                                                <tr>
                                                    <td style="border: none;"></td>
                                                    <td style="border: none;"></td>
                                                </tr>
                                                <tr>
                                                    <td style="border: none;"></td>
                                                    <td style="border: none;"></td>
                                                </tr>
                                                <tr>
                                                    <td style="border: none;"></td>
                                                    <td style="border: none;"></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2" style="border: none;">
                                                        <div class="d-flex justify-content-center align-items-center">
                                                            <ion-icon id="print" name="print-outline" @click="captureAndPrint('orderResume')" class="tx-30 pt-1 pe-1 tx-black"></ion-icon>
                                                            
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-4 col-12 m-0 p-3 pt-4-md mt-4-md pt-4-lg mt-4-lg align-items-center">
                    <div class="login-box border-1 border-black box rounded-5">
                        <div class="card-body pt-2 p-3 rounded-5 ">
                            
                            <div class="col-md-12">
                                <div class="ms-auto me-auto mt-3 form-group text-center rounded-5">
                                    <h5 class="text-black text-decoration-underline text-uppercase">{{ dictionary['Seguimiento de pedido'][sessionLanguage] }}</h5>
                                </div>
                            </div>
                            <!-- FORM Iniciar Sesion -->
                            <form id="formLogIn" class="form-horizontal text-center">
                                <div class="form-group p-3 mt-0">
                                    <span class="tx-14 tx-black">{{ errorMsg }}</span><br>
                                    <div class="col-xs-10"> 
                                        <input v-model="idOrder" id="orderId" type="text" autofocus class="p-2 text-black form-control"
                                            :placeholder="dictionary['Introduce ID de pedido'][sessionLanguage]">
                                    </div>
                                </div>
                                <div class="form-group text-center mt-3">
                                    <div class="col-xs-12 justify-content-center d-flex">
                                        <button id="btnLogin" @click="onOrderDetails" type="button" class="button-ok mt-1 rounded-5">
                                            <span>
                                                <span class="highlight-bg"></span>
                                                <span class="button-text">
                                                    {{ dictionary["Buscar pedido"][sessionLanguage] }}
                                                    
                                                </span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <!-- Detalles del pedido -->
            </div>
            <!-- Pié -->
            <div class="row mt-5 pt-4 border-top border-black justify-content-around">
        <div
          class="col-lg-3 col-12">
          <div class="mb-2" >
            <img 
              src="https://landa-merkataritza.araba.eus/wp-content/uploads/elementor/thumbs/logo-next-generation-qkddho5k5s75luq79e139c9pk83j6wxi3oxo58bfae.png"
              title="Logo Next Generation EU" alt="Logo Next Generation EU" loading="lazy">
          </div>
        </div>
        <div
          class="col-lg-3 col-12">
          <div class="mb-2">
            <img
              src="https://landa-merkataritza.araba.eus/wp-content/uploads/elementor/thumbs/logo-mece-footer-qn4gxitwqkhp5nxysgu3t83ua2eqr8cqmvpkg29qte.png"
              title="logo-mece-footer" alt="logo-mece-footer" loading="lazy">
          </div>
        </div>
        <div
          class="col-lg-3 col-12">
          <div class="mb-2">
            <img
              src="https://landa-merkataritza.araba.eus/wp-content/uploads/elementor/thumbs/logo-PRTR-qkddfh73q5oq21l41b0y0azneglq9zq8hihy6lzlhm.png"
              title="Logo Plan de Recuperación, Transformación y Resiliencia"
              alt="Logo Plan de Recuperación, Transformación y Resiliencia" loading="lazy">
          </div>
        </div>
        <div
          class="col-lg-3 col-12">
          <div class="mb-2" >
            <img
              src="https://landa-merkataritza.araba.eus/wp-content/uploads/elementor/thumbs/logo-DFA-qkddbz8qrwenauzuhxgf5rz8285ikwc0niptiwr908.png"
              title="logo Diputación Foral de Alava" alt="logo Diputación Foral de Alava" loading="lazy">
          </div>
        </div>
      </div>
      <p class="tx-14 pt-3">©2024 Todos los derechos reservados. <a style="text-decoration: none !important; color:black !important; font-weight: 500 !important" href="https://web.araba.eus/es/aviso-legal&quot;" target="_blank">Aviso Legal</a> - <a style="text-decoration: none !important; color:black !important; font-weight: 500 !important" href="https://web.araba.eus/es/politica-de-privacidad" target="_blank">Política de Privacidad</a> - <a style="text-decoration: none !important; color:black !important; font-weight: 500 !important" href="https://web.araba.eus/es/accesibilidad" target="_blank">Accesibilidad</a> - <a style="text-decoration: none !important; color:black !important; font-weight: 500 !important" href="https://centinela.lefebvre.es/public/concept/1834447?access=48fp5sSMiQN8FTC62cVteVTU46KcYkxONFKwGn0LYAU%3D" target="_blank">Canal de denuncias</a></p>

        </div>
    </section>
</template>


<script>
import api from '@/services/api';

export default {
    name: "LogInView",
    props: {
        session: {
            type: Object,
            required: true,
        },
        dictionary: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            errorMsg: "",
            idOrder: "",
            orderDetails: "",
            languages: ["es", "en", "eus"], //add here available languages
            sessionLanguage: '',
            visibility: {
                formLogIn: true,
                formPasswordLost: false,
                formValidateClient: false,
            }
        };
    },
    created() {

        this.sessionLanguage = this.session.user.language;
    },
    methods: {
        // DISPLAY
        toggleLanguage(language) {
            this.sessionLanguage = language;
        },       
        // GET
        async onOrderDetails() {
            try {
                this.errorMsg=""
                
               let response = await api.getOrderDetails(this.idOrder);
               if(response["status-code"] === "200"){
                this.orderDetails = api.parsePublicOrderFromResponse(response);
                this.idOrder = "";
                this.orderDetails.scheduledDate= api.parseDateUserTimezone(this.orderDetails.scheduledDate,this.session.user, this.$dayjs).split(' ')[0]
                } else {
                this.errorMsg = this.dictionary["Error al obtener detalles del pedido."][this.sessionLanguage];
                this.idOrder = "";
                return;
                }                   
                
                
            } catch (error) {
                console.error("Error fetching order details:", error);
                this.errorMsg = this.dictionary["Error al obtener detalles del pedido."][this.sessionLanguage];
            }
        },
       
        captureAndPrint(content) {
            var mywindow = window.open('', 'PRINT', 'height=400,width=600');
            mywindow.document.write(`
        <html>
        <head>
            <title>${this.dictionary["Imprimir"][this.sessionLanguage]} ${this.dictionary["ID reserva"][this.sessionLanguage]}: ${this.orderDetails.id}</title>
        </head>
        <body>
    `);
            mywindow.document.write(document.getElementById(content).innerHTML);
            mywindow.document.write(`
        </body>
        </html>
    `);
            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10
            mywindow.print();
            mywindow.close();
            return true;
        }
    }
}
</script>

<style>
.background_Alava {
  background-image: url("@/assets/img/inea-separacion-amarilla-gr.png"); 
  background-position-x: 50%;
  background-position-y: 50%;
  background-repeat: repeat-x;
}
.fontAdn {
  font-family: 'Montserrat', 'Segoe UI', sans-serif !important;
}

.language {
  cursor: pointer;
}

.language:hover, #home:hover {
  color: #FED54F !important;
}

#btnLogin:hover {
  color: black !important;
  background-color: #FED54F !important;
  border-color: #FED54F !important;
}
#print:hover{
   color: #FED54F !important;
   cursor: pointer;
}

@media (max-width: 1000px) {
  #topMenu {
    border-bottom: none !important;
  }
}
</style>