<template>
<!-- MODAL New Company -->
    <div id="newCompanyModal" class="col-12 justify-content-center pe-6"  style="padding: 15vh 25%">
      <div class="col-12 m-0 p-0 section-container rounded-5 sombra-modal">
  
<!-- HEAD New Company -->
        <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 bg-clomos-black">
          <div class="col-10">
            <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
              <ion-icon name="briefcase-outline" class="tx-18 me-2 align-bottom"></ion-icon>
              {{dictionary['Nueva empresa'][session.user.language]}}
            </h6>
          </div>
  
          <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleNewCompanyModal')">	
            <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
          </div>
        </div>
  
<!-- CONTENT New Company -->
        <div class="col-12 bg-caja w-100 m-0 p-0 rounded-bottom-5 pe-3">
          <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
            <div class="row m-0 p-2 card-body">

<!--   BLOQUE 1   -->
              <div class="col-xl-7 col-lg-6 col-12 m-0 p-2 overflow-hidden">
                <form class="col-12 border rounded m-0 p-3 pt-1">
                  <div class="row">
                    <div class="border border-black text-black p-1 m-0 rounded"> 
                      {{dictionary['Datos de contacto'][session.user.language]}}
                    </div>
<!-- E-MAIL -->
                    <div class="form-group col-12 text-start">
                      <label class="tx-13 text-dark tx-bold pt-2 text-nowrap">
                        {{dictionary["E-mail de la empresa"][session.user.language]}}
                      </label>
                      <br>
                      <input v-model="newCompanyData.email" id="inputNewCompanyEmail" type="email"
                      class="form-control p-1 text-black">
                    </div>
<!-- TELÉFONO -->
                    <div class="form-group col-12 text-start">
                      <label class="tx-13 text-dark tx-bold pt-2 text-nowrap">
                        {{dictionary["Teléfono de la empresa"][session.user.language]}}
                      </label>
                      <br>
                      <div class="col-12 d-flex flex-row p-0">
                        <div class="ps-0 pe-1">
                          <select v-model="newCompanyData.telephonePrefix" id="selectNewCompanyTelephonePrefix"
                          class="form-control p-1 me-2 text-black" >
                            <option v-for="country in countries" :key="country['dialCode']" :value="country['dialCode']">
                              {{country['code']+' '+country['dialCode']}}
                            </option>
                          </select>
                        </div>
                        <div class="w-100 ms-0">
                          <input v-model="newCompanyData.telephone" id="inputNewCompanyTelephone" type="tel"
                          class="form-control p-1 me-2 text-black">
                        </div>
                      </div>
                    </div>
         
<!-- DIRECCIÓN -->
                    <div class="form-group col-12" style="text-align: left;">
                      <label class="tx-13 text-dark tx-bold pt-2 text-nowrap">
                        {{dictionary["Dirección"][session.user.language]}}
                      </label>
                      <textarea v-model="newCompanyData.adress" id="inputNewCompanyAdress" name="adress" rows="2"
                      class="form-control" style="color: black; padding: 0.7rem;"/>
                    </div>

<!-- PAIS -->     
                    <div class="form-group col-12" style="text-align: left;">
                      <label class="tx-13 text-dark tx-bold pt-2 text-nowrap">
                        {{dictionary["Seleccione el país"][session.user.language]}}
                      </label>
                      <br>
                      <select v-model="newCompanyData.country" id="selectNewCompanyCountry"
                      class="form-control p-1 text-black">
                        <option v-for="country in countries" :key="country['code']" :value="country['code']">
                          {{country['name'][session.user.language]}}
                        </option>
                      </select>
                    </div>

                  </div>
                </form>
              </div>

<!--   BLOQUE PRIMERO   -->
              <div class="col-xl-5 col-lg-6 col-12 m-0 p-2 overflow-hidden">
                <div class="col-12 border rounded m-0 p-3 pt-1">
                  <div class="row">
                    <div class="border border-black text-black p-1 m-0 rounded"> 
                      {{dictionary['Empresa'][session.user.language]}}
                    </div>
<!-- NIF/CIF -->
                    <div class="form-group col-12 text-start">
                      <label class="tx-13 text-dark tx-bold pt-2 text-nowrap">
                        {{dictionary["NIF/CIF"][session.user.language]}}*
                      </label>
                      <br>
                      <input v-model="newCompanyData.nifCif" id="inputNewCompanyNifCif" type="text"
                      class="form-control p-1 text-black">
                    </div>
  
<!-- NOMBRE -->
                    <div class="form-group col-12 text-start">								
                      <label class="tx-13 text-dark tx-bold pt-2 text-nowrap">
                        {{dictionary["Nombre de la empresa"][session.user.language]}}
                      </label><br>
                      <input v-model="newCompanyData.name" id="inputNewCompanyName" type="text"
                      class="form-control p-1 text-black">
                    </div>

<!-- SECTOR -->
                    <div class="form-group col-12 text-start">								
                      <label class="tx-13 text-dark tx-bold pt-2 text-nowrap">
                        {{dictionary["Sector"][session.user.language]}}
                      </label><br>
                      
                      <select v-model="newCompanyData.sector" id="selectNewCompanySector"
                      class="form-control p-1 tx-13 text-dark">
                        <option value="aeronautica">
                          {{dictionary["Aeronáutica"][session.user.language]}}
                        </option>
                        <option value="agroalimentario">
                          {{dictionary["Agroalimentario"][session.user.language]}}
                        </option>
                        <option value="automotriz">
                          {{dictionary["Automotriz"][session.user.language]}}
                        </option>
                        <option value="construccion">
                          {{dictionary["Obra civil"][session.user.language]}}
                        </option>
                        <option value="quimica">
                          {{dictionary["Química"][session.user.language]}}
                        </option>
                        <option value="militar">
                          {{dictionary["Defensa/Militar"][session.user.language]}}
                        </option>
                        <option value="electronica">
                          {{dictionary["Electrónica"][session.user.language]}}
                        </option>
                        <option value="energia">
                          {{dictionary["Energía"][session.user.language]}}
                        </option>
                        <option value="it">
                          {{dictionary["Tecnologías de la información"][session.user.language]}}
                        </option>
                        <option value="industria 4.0">
                          {{dictionary["Industria 4.0"][session.user.language]}}
                        </option>
                        <option value="farma-medico">
                          {{dictionary["Farma-médico"][session.user.language]}}
                        </option>
                        <option value="logistica">
                          {{dictionary["Transporte y logística"][session.user.language]}}
                        </option>
                        <option value="almacenamiento">
                          {{dictionary["Manejo y almacenamiento de materiales"][session.user.language]}}
                        </option>
                        <option value="canteras">
                          {{dictionary["Canteras, cemento, mármoles"][session.user.language]}}
                        </option>
                        <option value="papeleras">
                          {{dictionary["Fábricas de papel y celulosa"][session.user.language]}}
                        </option>
                        <option value="gas">
                          {{dictionary["Petróleo y gas"][session.user.language]}}
                        </option>
                        <option value="puertos">
                          {{dictionary["Industria Portuaria"][session.user.language]}}
                        </option>
                        <option value="puertos deportivos">
                          {{dictionary["Puertos deportivos"][session.user.language]}}
                        </option>
                        <option value="astilleros">
                          {{dictionary["Astilleros"][session.user.language]}}
                        </option>
                        <option value="telecomunicaciones">
                          {{dictionary["Telecomunicaciones"][session.user.language]}}
                        </option>
                        <option value="hierro y acero">
                          {{dictionary["Hierro y acero"][session.user.language]}}
                        </option>
                        <option value="reciclaje">
                          {{dictionary["Tratamiento de residuos/Reciclaje"][session.user.language]}}
                        </option>
                        <option value="otro">
                          {{dictionary["Otro"][session.user.language]}}
                        </option>
                      </select>
                    </div>

                    <!-- SUBMIT -->
                    <div class="form-group col-12 pb-0 m-auto mb-0">		
                      <button type="button" @click="onCreateNewCompany" id="btnSubmitCreateNewUser" class="button-ok bg-clomos-black mt-3 rounded-3">
                        <span class="button-text text-white">
                          <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                          <span class="text-white text-nowrap mt-0">{{dictionary["Crear nueva empresa"][session.user.language]}}</span>
                        </span>
                      </button>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
  
      </div>
    </div>
  
  </template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "NewCompanyModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
  },
  data() {
    return {
      newCompanyData: {
        nifCif: "",
        name: "",
        email: "",
        telephonePrefix: "",
        telephone: "",
        country: "",
        adress: "",
        sector: "",
      },
    }
  },
  emits: ["toggleNewCompanyModal"],
  methods: {
    // SET
    async onCreateNewCompany() {
      let valid = true;
      if (this.newCompanyData.nifCif === "") {
        $("#inputNewCompanyNifCif").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newCompanyData.name === "") {
        $("#inputNewCompanyName").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newCompanyData.email === "") {
        $("#inputNewCompanyEmail").get()[0].classList.add("missing")
        valid = false;
      }
      if (!valid) {
        setTimeout(() => {
          $("#inputNewCompanyNifCif").get()[0].classList.remove("missing")
          $("#inputNewCompanyName").get()[0].classList.remove("missing")
          $("#inputNewCompanyEmail").get()[0].classList.remove("missing")
        }, 3000);
        return;
      }
      let response = await api.postNewCompany(this.newCompanyData);
      if (response["status-code"] === "200") {
        this.$emit('toggleNewCompanyModal');
        window.alert("La empresa ha sido creada satisfactoriamente.");
      }
      else {
        window.alert("Error al crear la empresa.");
      }
    }
  }
}
</script>

<style>

#newCompanyModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>