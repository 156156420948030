<template>
<!-- SECTION Lockings-->
  <div id="LockingsSection" class="row col-12 m-0 p-0">



    <div class="row col-12 p-2 m-0 section-container justify-content-center">
      
<!-- HEADER Lockings -->

      <div class="col-lg-2 col-md-4 col-12 m-0 p-2 text-white rounded-5" >
        <div class=" border-1 rounded-5 box sombra" >
          
          <div class="col-12 overflow-hidden justify-content-center">
            <h6 class="tx-left tx-clomos-black text-center mt-2 mb-2 ps-1 pt-1">
            <ion-icon name="lock-open" class="tx-18 me-2 align-bottom"></ion-icon>
              {{dictionary["Pedidos"][session.user.language]}}
            </h6>
          </div>

          <div class="p-3 pt-0">
<!-- Selector de Sistema -->
            <div class="col-12 m-0 p-0">	
              <hr class="m-0 p-0 bg-clomos-black" >
              <label v-if="usersList.length > 1" class="col-12 m-0 p-0  tx-black tx-12">
              {{dictionary['Usuarios'][session.user.language]}}
            </label>
              <select v-if="usersList.length > 1"
                    v-model="userSelected"
                    class="form-control mt-1 mb-3 h-100 text-nowrap bg-white" 
                    style="border: solid 2px black; color: black;"
                    @change="onCurrentUserChanged">
              <option value="showAll">
                {{ dictionary['Mostrar todo'][session.user.language] }}
              </option>
              <option v-for="user in usersList" :key="user.idUsuario" :value="user.idUsuario">
                {{ (user.idUsuario=== session.user.id) ? "✪ " + user.idUsuario : user.idUsuario }} 
              </option>
            </select>
            <hr v-if="usersList.length > 1" class="m-0 p-0 mb-3 bg-clomos-black">

            <div class="row justify-content-around m-0 p-0" :class="usersList.length <= 1 ? 'mt-3' : 'mt-0'">
              <div class="col-3 m-0 mb-3 p-0">
                <button @click="onChangeDisplayType('dashboard')"
                  :style="(displayType === 'locker') ? 'opacity=1;' : 'opacity: 0.7'"
                  class="border-black col-12 header-tab-button border-2 btn  rounded-5 p-0  text-nowrap"
                  style="background-color:white">
                  <ion-icon name="stats-chart-outline" :style="(displayType === 'dashboard') ? 'opacity=1;' : 'opacity: 0.5'"
                    class="border-black  text-black tx-23 pt-3 pb-3" style="vertical-align: middle;"></ion-icon>
                </button>
              </div>
              <div class="col-3 m-0 mb-3 p-0">
                <button @click="onChangeDisplayType('table')"
                  :style="(displayType === 'table') ? 'opacity=1;' : 'opacity: 0.7'"
                  class="border-black col-12 header-tab-button border-2 btn  rounded-5 p-0 text-nowrap"
                  style="background-color: white">
                  <ion-icon name="list-outline" :style="(displayType === 'table') ? 'opacity=1;' : 'opacity: 0.5'"
                    class="border-black  text-black tx-23 pt-3 pb-3" style="vertical-align: middle;"></ion-icon>
                </button>
              </div>
             
            </div>
              <hr class="m-0 p-0 bg-clomos-back" >

              <div class="col-12 p-0 justify-content-center d-flex">
                <button id="btnDisplayNewLockingsModal" type="button" class="button-ok bg-clomos-black mt-3 rounded-3" 
                @click="toggleNewLockingModal" >
                  <span class="button-text text-white">
                    <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                    <span class="text-white mt-0 text-nowrap">{{dictionary["Añadir reserva"][session.user.language]}}</span>
                  </span>
                </button>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>

      <div class="col-lg-10 col-md-8 col-12 m-0 p-2 overflow-show justify-content-center">
        <div class="col-12 table-responsive bg-caja rounded-top-5 m-0 p-0 sombra">
          <div class="position-sticky col-12 m-0 pt-2 pb-2  text-white border-1 bg-clomos-black"  >
            <ion-icon name="lock-open-outline" class="tx-16 mb-1 me-2 align-bottom"></ion-icon>
            {{dictionary["Pedidos"][session.user.language]}}
          </div>
          <!-- Dashboard Lockings--> 
        <div class="pt-3" v-show="displayType === 'dashboard'">
          <div class="row p-0 m-0">
            <!-- Resumen de datos -->
            <div class="row mb-3 p-0 m-0 justify-content-around">
              <div class="col-md-2 mb-4">
                <div class="card text-white " style="background-color:#5470c6">
                  <div class="card-body">
                    <h5 class="card-title">{{dictionary["Pendiente deposito"][session.user.language]}}</h5>
                    <p class="card-text">{{ pedidosCreados }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-2 mb-4">
                <div class="card text-white" style="background-color:#73c0de">
                  <div class="card-body">
                    <h5 class="card-title">{{dictionary["Pendiente recoger"][session.user.language]}}</h5>
                    <p class="card-text">{{ pedidosAnulando }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-2 mb-4">
                <div class="card text-white" style="background-color:#fac858">
                  <div class="card-body">
                    <h5 class="card-title">{{dictionary["Depositados"][session.user.language]}}</h5>
                    <p class="card-text">{{ pedidosDepositados }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-2 mb-4">
                <div class="card text-white" style="background-color:#ee6666">
                  <div class="card-body">
                    <h5 class="card-title">{{dictionary["Cancelados"][session.user.language]}}</h5>
                    <p class="card-text">{{ pedidosCancelados }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-2 mb-4">
                <div class="card text-white" style="background-color:#91cc75">
                  <div class="card-body">
                    <h5 class="card-title">{{dictionary["Entregados"][session.user.language]}}</h5>
                    <p class="card-text">{{ pedidosEntregados }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-9"></div>
            <div class="d-flex justify-content-center mb-3   border border-2 border-dark rounded-pill p-2 shadow bg-light col-2">
      <div class="form-check form-switch d-flex align-items-center">
        <input 
          class="form-check-input larger-switch" 
          type="checkbox" 
          role="switch" 
          id="flexSwitchCheckChecked" 
          v-model="isChecked" 
          @change="handleToggleChange"
        > 
        <label class="form-check-label ms-2" for="flexSwitchCheckChecked">
          {{ selectedDays === 7 ? 
            dictionary["Cambiar a 30 dias"][session.user.language]  :
            dictionary["Cambiar a 7 dias"][session.user.language]
          }}
        </label>
      </div>
    </div>
            <div class="row mt-4">
            <!-- Gráfica de Pedidos por Día -->
            <div class="col-md-6 mb-4">
              <VueEcharts :option="barChartOptions" id="Chart1Canvas" class="w-100" style="height: 300px;"></VueEcharts>
            </div>
            <!-- Gráfica de Estados de Pedidos -->
            <div class="col-md-6 mb-4">
              <VueEcharts :option="pieChartOptions" id="Chart2Canvas" class="w-100" style="height: 300px;"></VueEcharts>
            </div>
          </div>
         
          </div>
        </div> 
            <!-- End of Dashboard Lockings-->
<!-- TABLE Lockings -->
          <div class="m-0 p-3 overflow-auto" v-show="displayType === 'table'">
            <table id="LockingsDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
              <thead class=" tx-14 text-white text-center overflow-hidden bg-clomos-black">
                <tr>
                  <th v-for="column in lockingColumns" :key="column.data"
                  :width="column.width" class="fw-medium text-nowrap p-2">
                    {{dictionary[column.text][session.user.language]}}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
     
    </div>
  </div>

<!-- MODAL New Locking -->
  <NewLockingModal
  v-if="display.newLockingModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :availableCompanies="availableCompanies"
  v-on:toggleNewLockingModal="toggleNewLockingModal">
  </NewLockingModal> 
  
<!--MODAL Edit Locking-->
  <EditLockingModal
  v-if="display.editLockingModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :availableCompanies="availableCompanies"
  :lockingId="targetLockingId"
  v-on:toggleEditLockingModal="toggleEditLockingModal">
  </EditLockingModal> 

  <!-- MODAL Cancel Text Alert -->
  <TextAlertModal
  v-if="display.cancelLockingModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertHeader="dictionary['Pedido a cancelar'][session.user.language] + ': '"
  :alertTarget="targetLockingId"
  :alertTexts="idPedido"
  v-on:acceptAlert="onCancelLocking"
  v-on:dismissAlert="toggleCancelLockingModal">
  </TextAlertModal>

<!-- MODAL Delete Text Alert -->
  <TextAlertModal
  v-if="display.deleteLockingModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertHeader="dictionary['Pedido a eliminar'][session.user.language] + ': '"
  :alertTarget="targetLockingId"
  :alertTexts="idPedido"
  v-on:acceptAlert="onDeleteLocking"
  v-on:dismissAlert="toggleDeleteLockingModal">
  </TextAlertModal>

</template>

<script>
import $ from 'jquery';
import NewLockingModal from '@/components/lockers/NewLockingModal';
import EditLockingModal from '@/components/lockers/EditLockingModal';
import TextAlertModal from '@/components/other/TextAlertModal';
// Clomos
import api from '@/services/api';

// Import charts

import { VueEcharts } from 'vue3-echarts';

export default {
  name: "LockingsComponent",
  components: {  
    NewLockingModal, 
    TextAlertModal, 
    EditLockingModal, 
    VueEcharts
  },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
    dataTableOptions: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      displayType:"dashboard",
      lockingColumns: [
        {text:"Albarán", data: "lockingId", width: "5%",
          render: (data) => {
            return "<ion-icon name='list-circle-outline' data-id="+data+" class='btnDetailLocking btn tx-24 tx-primary-adn  p-0 m-0'></ion-icon>";
          }
        },
        {
          text: "Identificador",
          data: "lockingId",
          width: "15%",
          className: "tx-left align-middle dt-nowrap",
          render: (data) => {
            let dataShorted = data;
            if (data.length > 8) {
              dataShorted = data.substring(0, 8) + '...';
            }
            return "<ion-icon name='copy-outline' data-id='" + data + "' class='btnCopyId btn tx-18 tx-clomos-midtone' ></ion-icon>" +
                   "<span data-id='" + data + "' class='btnTargetProduct tx-bold tx-clomos-midtone text-nowrap align-middle' " +
                   "title='" + data + "'>" + dataShorted + "</span>";
          }
        },
        {text:"Pedido", data: "lockingParams", width: "15%", className:"tx-left align-middle dt-nowrap",
          render: (data) => {
            return "<span data-id="+data.idPedido+" class='btnTargetProduct tx-bold tx-clomos-midtone text-nowrap align-middle'>"+data.idPedido+"</span>";
          }
        },
        {
  text: "Estado",
  data: "lockingStatus",
  width: "10%",
  className: "tx-left align-middle",
  render: (data) => {
    const executionDate = new Date(data.executionDateTime);
    const maxHoldingDays = parseInt(data.maxHoldingDays);
    const endDate = new Date(executionDate) ;
    endDate.setDate(executionDate.getDate() + maxHoldingDays);
    let result;
    switch (data.status) {
      case "0":
        result = this.dictionary["Creada"][this.session.user.language];
        break;
      case "1":
        result = this.dictionary["Planificada"][this.session.user.language];
        break;
      case "2":
        result = this.dictionary["Depositado"][this.session.user.language];
        if (new Date() > new Date(endDate)) {         
          result += " <ion-icon class='tx-20' name='alert' color='danger'></ion-icon>";         
        }
        break;
      case "3":
        result = this.dictionary["Anulando"][this.session.user.language];
        break;
      case "4":
        result = this.dictionary["Finalizada"][this.session.user.language];
        break;
      case "5":
        result = this.dictionary["Cancelada"][this.session.user.language];
        break;
      default:
        result = data.status;
    }
    return result;
  }
},
        {text:"Fecha de creación", data: "lockingCreationDateTime", width: "10%", className:"tx-left align-middle",
          render: (data) => {   
            return (data!="")
              ?"<span style='display: none;'>" + data.getTime() + "</span> <spam>" + api.parseDateUserTimezone(data, this.session.user, this.$dayjs) + "</spam>"
              :"<span style='display: none;'></span> <spam>-</spam>";
          }
        },
        {text:"Modificar", data: "lockingStatus", width: "5%", className:" align-middle",
          render: (data) => {
            return (
              data.status==="4"||data.status==="5"?"<ion-icon name='settings-outline' data-id="+data.id+" class='btn tx-24 tx-primary-adn m-0 p-0 opacity-50' disabled></ion-icon>": "<ion-icon name='settings-outline' data-id="+data.id+" class='btnModifyLocking btn tx-24 tx-black m-0 p-0'></ion-icon>"
            )
          }
        },
        {text:"Anular", data: "lockingStatus", width: "5%", className:"align-middle",
          render: (data) => {
            return (
              data.status==="0"||data.status==="2"?
              "<ion-icon name='close-circle-outline' data-id='" + data.id + "' data-idOrder='" + data.idOrder +"' data-idLocker='" + data.idLocker + "' class='btnCancelLocking btn tx-24 tx-danger m-0 p-0'></ion-icon>":
              "<ion-icon name='close-circle-outline' data-id='" + data.id + "' data-idOrder='" + data.idOrder +"' data-idLocker='" + data.idLocker + "' class=' btn tx-24 tx-danger opacity-50 m-0 p-0 disabled></ion-icon>"
            )
          }
        },
        {text:"Eliminar", data: "lockingStatus", width: "5%", className:"align-middle",
          render: (data) => {
            return (
              data.status==="4"||data.status==="5"?
              "<ion-icon name='trash-outline' data-id='" + data.id + "' data-idOrder='" + data.idOrder + "' class='btnDeleteLocking btn tx-24 tx-danger m-0 p-0'></ion-icon>":
              "<ion-icon name='trash-outline' data-id='" + data.id + "' data-idOrder='" + data.idOrder + "' class='btn tx-24 tx-danger opacity-50 m-0 p-0 disabled'></ion-icon>"
            )
          }
        }
      ],
      targetLockingId: "",
      idPedido:[],
      idLocker:"",
      lockingList: [],
      lockingData:[],
      alertValue: '',
      bulgeInput: {
        volume: {
          height: 0,
          width: 0,
          depth: 0
        },
        units: 0
      },
      bulgeList: [],
      display: {
        newLockingModal: false,       
        deleteLockingModal: false,
        editLockingModal: false,
        cancelLockingModal: false,
      },
      usersList:[],
      userSelected:"",
      
      // Datos para las gráficas y el resumen
      totalPedidos: 0,
      pedidosEntregados: 0,
      pedidosCancelados: 0,
      pedidosDepositados:0,
      pedidosCreados:0,
      pedidosAnulando:0,
      barChartOptions: {},
      pieChartOptions: {},
      isChecked: false,
      selectedDays:7,
    };
  },
  computed: {
    targetLocking() {
      for (let i in this.lockingList) {
        if (this.lockingList[i].id === this.targetLockingId)
          return this.lockingList[i];
      }
      return {};
    },
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    onChangeDisplayType(type) {
      this.displayType = type;      
    },
    handleToggleChange(){     
      this.selectedDays===30?this.selectedDays=7:this.selectedDays=30
      this.displayOrdersFilters();
    },
    setDefaultUser(){
      const userExists = this.usersList.some(user => user.idUsuario === this.session.user.id);
      if (userExists) {
        this.userSelected = this.session.user.id;
      }
    },
    async onCurrentUserChanged() {  
      await this.getLockingList();
      this.displayLockingsDataTable();
      this.displayOrdersFilters()      
    },
    displayLockingsDataTable() {
      $("#LockingsDataTable").DataTable().clear()
      for (let i in this.lockingList) {
        $("#LockingsDataTable").DataTable().rows.add([{
          lockingId: this.lockingList[i].id, 
          lockingType: this.lockingList[i].type,
          lockingParams: this.lockingList[i].params,
          locking: this.lockingList[i],
          lockingStatus: {
          id: this.lockingList[i].id,
          idOrder: this.lockingList[i].params.idPedido,
          idLocker:this.lockingList[i].params.idLocker,
          status: this.lockingList[i].status, plannedDateTime: (this.lockingList[i].plannedDateTime !== undefined && this.lockingList[i].plannedDateTime !== "") ? this.lockingList[i].plannedDateTime : "", 
          executionDateTime: (this.lockingList[i].executionDateTime !== undefined && this.lockingList[i].executionDateTime !== "") ? this.lockingList[i].executionDateTime : "",
          maxHoldingDays:  this.lockingList[i].params.maxDiasEspera 
        },
          lockingCreationDateTime: (this.lockingList[i].creationDateTime != undefined && this.lockingList[i].creationDateTime != "") ? new Date(this.lockingList[i].creationDateTime) : "",
          lockingPlannedDateTime: (this.lockingList[i].plannedDateTime != undefined && this.lockingList[i].plannedDateTime != "") ? new Date(this.lockingList[i].plannedDateTime) : "",
          lockingExecutionEndDateTime: (this.lockingList[i].executionEndDateTime != undefined && this.lockingList[i].executionEndDateTime != "") ? new Date(this.lockingList[i].executionEndDateTime) : ""
        }]);
      }
      $("#LockingsDataTable").DataTable().draw();
      this.setButtonFunctions();
      if($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn)=> { btn.addEventListener('click', this.setButtonFunctions)});
    },
    async toggleNewLockingModal() {
      if (this.display.newLockingModal) {
        await this.getLockingList();
        this.displayLockingsDataTable();
        this.displayOrdersFilters();
      }
      this.display.newLockingModal = !this.display.newLockingModal;
    },
    async toggleEditLockingModal() {
      if (this.display.editLockingModal) {
        await this.getLockingList();
        this.displayLockingsDataTable();
        this.displayOrdersFilters();
      }
      this.display.editLockingModal = !this.display.editLockingModal;
    },
    async toggleDeleteLockingModal() {
      if (this.display.deleteLockingModal) {
        await this.getLockingList();
        this.displayLockingsDataTable();
        this.displayOrdersFilters();
      }
      this.display.deleteLockingModal = !this.display.deleteLockingModal;
    },
    async toggleCancelLockingModal() {
      if (this.display.cancelLockingModal) {
        await this.getLockingList();
        this.displayLockingsDataTable();
        this.displayOrdersFilters();
      }
      this.display.cancelLockingModal = !this.display.cancelLockingModal;
    },
    displayOrdersFilters() {
      this.pedidosCreados = 0;
    this.pedidosDepositados = 0;
    this.pedidosEntregados = 0;
    this.pedidosCancelados = 0;
    this.pedidosAnulando = 0;
    this.totalPedidos = this.lockingList.length; 
    const today = new Date();
    const lastDays = [];
    const pedidosPorDia = Array(this.selectedDays).fill(0);
    const pedidosPorDiaCreados = Array(this.selectedDays).fill(0);
    const pedidosPorDiaDepositados = Array(this.selectedDays).fill(0);
    const pedidosPorDiaEntregados = Array(this.selectedDays).fill(0);
    const pedidosPorDiaCancelados = Array(this.selectedDays).fill(0);
    const pedidosPorDiaAnulando= Array(this.selectedDays).fill(0);
    

    for (let i = 0; i < this.selectedDays; i++) {
        const day = new Date(today);
        day.setDate(today.getDate() - i);
        lastDays.unshift(day.toLocaleDateString('es-ES')); 
    }
 
    for (let i = 0; i < this.lockingList.length; i++) {
      const creationDate = new Date(this.lockingList[i].creationDateTime);
        const creationDayIndex = lastDays.indexOf(creationDate.toLocaleDateString('es-ES'));
      
        if (creationDayIndex !== -1) {
            pedidosPorDia[creationDayIndex]++;
        }
        const status = this.lockingList[i].status;      
       
        if (status === "0" || status === "1") {
            this.pedidosCreados++;
            if(lastDays.indexOf(creationDate.toLocaleDateString('es-ES'))!== -1)  {
            pedidosPorDiaCreados[creationDayIndex]++;
            }
        } else if (status === "2") {
            this.pedidosDepositados++;
            if(lastDays.indexOf(creationDate.toLocaleDateString('es-ES'))!== -1)  {
            pedidosPorDiaDepositados[creationDayIndex]++;
            }
            
        } else if (status === "3") {
            this.pedidosAnulando++;
            if(lastDays.indexOf(creationDate.toLocaleDateString('es-ES'))!== -1)  {
              pedidosPorDiaAnulando[creationDayIndex]++;
          }
        } else if (status === "4") {
            this.pedidosEntregados++;
            if(lastDays.indexOf(creationDate.toLocaleDateString('es-ES'))!== -1)  {
            pedidosPorDiaEntregados[creationDayIndex]++;
          }
        } else if (status === "5") {
            this.pedidosCancelados++;
            if(lastDays.indexOf(creationDate.toLocaleDateString('es-ES'))!== -1)  {
            pedidosPorDiaCancelados[creationDayIndex]++;
            }
        }
      
    }
    //display bar chart
    this.barChartOptions= {
        title: {
          text: this.selectedDays===7?this.dictionary["Pedidos ultimos 7 dias"][this.session.user.language]:this.dictionary["Pedidos ultimos 30 dias"][this.session.user.language],
          left: 'center'
        },
        tooltip: {
        },
        xAxis: {
            data: lastDays,
            axisLabel: {
            interval: this.selectedDays === 7 ? 0 : 'auto',
            rotate: 45 
        }
        },        
        yAxis: {},
        series: [{
            name: this.dictionary["Creados"][this.session.user.language],
            type: 'bar',
            stack: 'total',
            itemStyle: { color: ' #5470c6' },
            data: pedidosPorDiaCreados
        },
        {
            name: this.dictionary["Depositados"][this.session.user.language],
            type: 'bar',
            stack: 'total',
            itemStyle: { color: '#fac858' },
            data: pedidosPorDiaDepositados
        },
        {
            name: this.dictionary['Entregados'][this.session.user.language],
            type: 'bar',
            stack: 'total',
            itemStyle:{color:'#91cc75'},
            data: pedidosPorDiaEntregados
        },
        {
            name: this.dictionary['Cancelados'][this.session.user.language],
            type: 'bar',
            stack: 'total',
            itemStyle:{color:'#ee6666'},
            data: pedidosPorDiaCancelados
        },
        {
            name: this.dictionary['Anulando'][this.session.user.language],
            type: 'bar',
            stack: 'total',
            itemStyle: { color: ' #73c0de' },
            data: pedidosPorDiaAnulando
        }
      ]
      }


    //display pie chart
    this.pieChartOptions = {
    title: {
      text: `${this.dictionary["Estado de pedidos últimos"][this.session.user.language]}  ${this.selectedDays} ${this.dictionary["Dias"][this.session.user.language].toLowerCase()}`,
      left: 'center'
    },
    tooltip: {
      trigger: 'item'
    },
    legend: {
      orient: 'vertical',
      right: 'right',
      bottom: '30px',
    },
    series: [
      {
        name: 'Estado',
        type: 'pie',
        radius: '50%',
        data: [
          { value: this.pedidosCreados, name: this.dictionary["Creados"][this.session.user.language], itemStyle: { color: ' #5470c6' } },
          { value: this.pedidosDepositados, name: this.dictionary["Depositados"][this.session.user.language] , itemStyle: { color: '#fac858' }},
          { value: this.pedidosAnulando, name: this.dictionary["Anulando"][this.session.user.language] , itemStyle: { color: ' #73c0de' } },
          { value: this.pedidosCancelados, name: this.dictionary["Cancelados"][this.session.user.language], itemStyle:{color:'#ee6666'} },
          { value: this.pedidosEntregados, name: this.dictionary["Entregados"][this.session.user.language], itemStyle:{color:'#91cc75'} } ,             
        ].filter(item => item.value > 0),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };
},
    // BUTTONS
    setButtonFunctions() {
      setTimeout(()=>{
        $(".btnTargetLocking").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetLocking);
        });
        $(".btnDetailLocking").get().forEach(btn => {
          btn.addEventListener('click', this.onDetailLocking)
        });
        $(".btnModifyLocking").get().forEach(btn => {
          btn.addEventListener('click', this.onModifyLocking);
        });
        $(".btnCancelLocking").get().forEach(btn => {
          btn.addEventListener('click', this.onCancelLocking);
        });
        $(".btnDeleteLocking").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteLocking);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    // GET
    async getLockingList(callback) {
      let request
      let response
      if(this.userSelected==="showAll"){
        request = {
        nifCif: this.currentCompany.nifCif,
        
      };     
      response = await api.getLockingList(request);
       
      }else {
        
         request = {
        userId: this.userSelected?this.userSelected:this.session.user.id,
      };
      response = await api.getLockingListByUser(request);
      }
      
      this.lockingList = api.parseOrdersFromResponse(response)
      if (callback && typeof callback === "function") callback(this.lockingList);
    },
    // TARGET 
    onTargetLocking(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetLockingId = event.target.getAttribute('data-id');
      this.targetLockingOrder=  event.target.getAttribute('data-id');
    },
    // PLAN
    onUpdateAlertValue(value) {
      this.alertValue = value;
    },
    async onModifyLocking (event){
      console.log('MODIFY WHERE :');
      console.log(event.target.getAttribute('data-id'));
      this.targetLockingId = event.target.getAttribute('data-id');
      this.toggleEditLockingModal();
    },
    // DETAILS
    async onDetailLocking(event) {
      console.log('DETAIL WHERE ID:');
      console.log(event.target.getAttribute('data-id'),this.targetLocking);
      this.targetLockingId = event.target.getAttribute('data-id');
      let response = await api.getLockerData(this.targetLocking.params.idLocker);
      if (response["status-code"] === "200") {
        let locker = api.parseMachineFromResponse(response);
        this.targetLocking.params.lockerLabel=locker.label
        this.targetLocking.params.lockerEmplacement= locker.emplacement?locker.emplacement: "-"
      }
      var tr = $(event.target).closest('tr');
      var row = $("#LockingsDataTable").DataTable().row(tr);
      if (row.child.isShown()) {
        event.target.name = "list-circle-outline";
        row.child.hide();
        tr.removeClass('shown');
      } else {
        event.target.name = "chevron-up-circle-outline";
        let content = '' +
      '<div id="resumenPedido" class="border border-3 p-2" style="background-color: white!important; border-color: #FED54F !important; ">' +
      ' <h2 class="mt-2">' + this.dictionary["Resumen de la Reserva"][this.session.user.language] + 
      '<ion-icon class="btn tx-24 text-black m-0 p-0" id="pdf-icon" name="print-outline"></ion-icon>' +
      '</h2>' +
      '<table class="p-1" style="border-collapse: collapse; width: 100%;">' +
      '<tr>' +
      '<td colspan="2">' +
      '<div class="container">' +
      '<ol class="progress-meter">' +
      '<li class="progress-point done ">'+this.dictionary["Creada"][this.session.user.language]+': '+ api.parseDateUserTimezone(this.targetLocking.creationDateTime, this.session.user, this.$dayjs).split(' ')[0] +'</li>' +
      (this.targetLocking.executionDateTime ? '<li class="progress-point done">'+this.dictionary["Depositado"][this.session.user.language]+': '+  api.parseDateUserTimezone(this.targetLocking.executionDateTime, this.session.user, this.$dayjs).split(' ')[0] +'</li>' : '') +
      (this.targetLocking.endDateTime ? '<li class="progress-point done">'+this.dictionary["Finalizada"][this.session.user.language]+': '+  api.parseDateUserTimezone(this.targetLocking.creationDateTime, this.session.user, this.$dayjs).split(' ')[0] +'</li>' : '') +
      (this.targetLocking.executionEndDateTime ? '<li class="progress-point done">'+this.dictionary["Anulando"][this.session.user.language]+': '+  api.parseDateUserTimezone(this.targetLocking.creationDateTime, this.session.user, this.$dayjs).split(' ')[0] +'</li>' : '')+
      (this.targetLocking.cancelDateTime ? '<li class="progress-point done">'+this.dictionary["Cancelada"][this.session.user.language]+': '+  api.parseDateUserTimezone(this.targetLocking.creationDateTime, this.session.user, this.$dayjs).split(' ')[0] +'</li>' : '')+
      (this.targetLocking.creationDateTime&&!this.targetLocking.executionDateTime&&!this.targetLocking.cancelDateTime&&!this.targetLocking.endDateTime?'<li class="progress-point todo">Depositado</li>': '') +
      (this.targetLocking.executionEndDateTime&&!this.targetLocking.cancelDateTime?'<li class="progress-point todo">'+this.dictionary["Cancelada"][this.session.user.language]+'</li>': '') +
      (!this.targetLocking.endDateTime&&!this.targetLocking.cancelDateTime&&!this.targetLocking.executionEndDateTime?'<li class="progress-point todo">'+this.dictionary["Finalizado"][this.session.user.language]+'</li>': '') +
      '</ol>' +
      '</div>' +
      '</td>' +
      '</tr>' +
      '<tr>' +
      '<th style="padding: 8px; text-align: left; font-size: 1.5rem; background-color: #f2f2f2;" colspan="2">' + this.dictionary["Detalles de la reserva"][this.session.user.language] + '</th>' +
      '</tr>' +
      '<tr>' +
      '<td style="padding: 8px; text-align: left;">' + this.dictionary["ID reserva"][this.session.user.language] + '</td>' +
      '<td style="padding: 8px; text-align: left;">' + this.targetLocking.id + '</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="padding: 8px; text-align: left;">' + this.dictionary["ID pedido"][this.session.user.language] + '</td>' +
      '<td style="padding: 8px; text-align: left;">' + this.targetLocking.params.idPedido + '</td>' +
      '</tr>' +     
      '<tr>' +
      '<td style="padding: 8px; text-align: left;">' + this.dictionary["Locker"][this.session.user.language] + '</td>' +
      '<td style="padding: 8px; text-align: left;">' + this.targetLocking.params.lockerLabel + '</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="padding: 8px; text-align: left;">' + this.dictionary["Localización"][this.session.user.language] + '</td>' +
      '<td style="padding: 8px; text-align: left;">' + this.targetLocking.params.lockerEmplacement+ '</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="padding: 8px; text-align: left;">' + this.dictionary["Fecha prevista"][this.session.user.language] + '</td>' +
      '<td style="padding: 8px; text-align: left;">' + api.parseDateUserTimezone(this.targetLocking.params.fechaProgramada, this.session.user, this.$dayjs).split(' ')[0] + '</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="padding: 8px; text-align: left;">' + this.dictionary["Días de espera"][this.session.user.language] + '</td>' +
      '<td style="padding: 8px; text-align: left;">' + this.targetLocking.params.maxDiasEspera + '</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="padding: 8px; text-align: left;">' + this.dictionary['E-mail'][this.session.user.language] + ' ' + this.dictionary['Transportista'][this.session.user.language].toLowerCase() + '</td>' +
      '<td style="padding: 8px; text-align: left;">' + this.targetLocking.params.emailTransportista + '</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="padding: 8px; text-align: left;">' + this.dictionary['E-mail'][this.session.user.language] + ' ' + this.dictionary['Cliente'][this.session.user.language].toLowerCase() + '</td>' +
      '<td style="padding: 8px; text-align: left;">' + this.targetLocking.params.emailConsumidor + '</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="padding: 8px; text-align: left;">' + this.dictionary["Observaciones"][this.session.user.language] + '</td>' +
      '<td style="padding: 8px; text-align: left;">' + this.targetLocking.params.observaciones + '</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="padding: 8px; text-align: left;" colspan="2">' +
      '<h4 style="font-size: 1.5rem;">' + this.dictionary["Detalle de los paquetes"][this.session.user.language] + '</h4>' +
      '<table>' +
      '<tr>' +
      '<th style="padding: 8px; text-align: left; background-color: #f2f2f2;">' + this.dictionary["Nº de paquete"][this.session.user.language] + '</th>' +
      '<th style="padding: 8px; text-align: left; background-color: #f2f2f2;">' + this.dictionary["Alto"][this.session.user.language] + '</th>' +
      '<th style="padding: 8px; text-align: left; background-color: #f2f2f2;">' + this.dictionary["Ancho"][this.session.user.language] + '</th>' +
      '<th style="padding: 8px; text-align: left; background-color: #f2f2f2;">' + this.dictionary["Fondo"][this.session.user.language] + '</th>' +
      '<th style="padding: 8px; text-align: left; background-color: #f2f2f2;">' + this.dictionary["Peso"][this.session.user.language] + '</th>' +
      '<th style="padding: 8px; text-align: left; background-color: #f2f2f2;">' + this.dictionary["Tipo"][this.session.user.language] + '</th>' +
      '<th style="padding: 8px; text-align: left; background-color: #f2f2f2;">' + this.dictionary["Accesible"][this.session.user.language] + '</th>' +
      '</tr>';
        for (let i = 0; i < this.targetLocking.params.bultos.length; i++) {
          content += '<tr>' +
          '<td style= "padding: 8px; text-align: center;">' + this.targetLocking.params.bultos[i].id + '</td>' +
          '<td style= "padding: 8px; text-align: center;">' + this.targetLocking.params.bultos[i].alto + ' mm' + '</td>' +
          '<td style= "padding: 8px; text-align: center;">' + this.targetLocking.params.bultos[i].ancho + ' mm' + '</td>' +
          '<td style= "padding: 8px; text-align: center;">' + this.targetLocking.params.bultos[i].fondo + ' mm' + '</td>' +
          '<td style= "padding: 8px; text-align: center;">' + ((this.targetLocking.params.bultos[i].peso!=undefined)?this.targetLocking.params.bultos[i].peso:'-') + ' g' + '</td>' +
          '<td style= "padding: 8px; text-align: center;">' + ((this.targetLocking.params.bultos[i].tipo!=undefined)?this.dictionary[this.targetLocking.params.bultos[i].tipo][this.session.user.language]:'-') + '</td>' +
          '<td style= "padding: 8px; text-align: center;">' + ((this.targetLocking.params.bultos[i].accesible!=undefined)?this.dictionary[this.targetLocking.params.bultos[i].accesible][this.session.user.language]:'-') + '</td>' +
          '</tr>';
        }
        content += '</table>' +
        '</td>' +
        '</tr>' +
        '</table>' +
        '</div>';
        row.child(content).show();
        if (this.targetLockingId) {
          let detailTable = document.getElementById('detailTable' + this.targetLockingId);
          if (detailTable && detailTable.parentNode) {
              detailTable.parentNode.style.padding = 0;
          }
        }
        tr.addClass('shown');
        document.getElementById('pdf-icon').addEventListener('click', function () {
            var content = 'resumenPedido';
            captureAndPrint(content);
        });

        }
              function captureAndPrint(content)
        {
            var mywindow = window.open('', 'PRINT', 'height=400,width=600');

            mywindow.document.write('<html><head><title>' +"Impresión de Pedido"+ '</title>');
            mywindow.document.write('</head><body >');
            
            mywindow.document.write(document.getElementById(content).innerHTML);
            mywindow.document.write('</body></html>');

            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/

            mywindow.print();
            mywindow.close();

            return true;
        }
      
    },
    // DELETE
    async onDeleteLocking(event) {
      if (!this.display.deleteLockingModal) {
        console.log('DELETE WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetLockingId = event.target.getAttribute('data-id');
        this.idPedido=[];
        let pedidoLabel= "Identificador del pedido: "+event.target.getAttribute('data-idOrder');
        this.idPedido.push(pedidoLabel);
      }
      else {
        let response = await api.deleteLocking(this.targetLockingId);
        if (response["status-code"] === "200") {
          this.$emit('toggleNewLockingModal');
          window.alert("La reserva ha sido eliminada satisfactoriamente.");
        }
        else {
          window.alert("Error al eliminar la reserva.");
        }
      }
      this.toggleDeleteLockingModal();
    },
    //CANCEL
    async onCancelLocking(event) {
      if (!this.display.cancelLockingModal) {
        console.log('CANCEL WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetLockingId = event.target.getAttribute('data-id');
        this.idPedido=[];
        let pedidoLabel= "Identificador del pedido: "+event.target.getAttribute('data-idOrder');
        this.idLocker= event.target.getAttribute('data-idLocker'); 
        this.idPedido.push(pedidoLabel);
      }
      else {      
        
        let response = await api.cancelOrder(this.targetLockingId,this.idLocker);
        if (response["status-code"] === "200") {
          this.$emit('toggleNewLockingModal');
          window.alert("La reserva ha sido cancelada satisfactoriamente.");
        }
        else {
          window.alert("Error al cancelada la reserva.");
        }
      }
      this.toggleCancelLockingModal();
    }
  },  
  async created() {
    this.usersList=[];   
   
    let requestUser = {
        nifCif: this.session.user.nifCif        
      };
    let response = await api.getUserList(requestUser)
    if (response["status-code"] === "200") {
      this.usersList=response.UserList
    }
    if(!this.usersList.some(user => user.idUsuario == this.session.user.id )){
      this.usersList.push(this.session.user.id)
    }
    await this.getLockingList()
    this.setDefaultUser()
    this.$emit('getAvailableCompanies',(this.displayLockingsDataTable));
    this.displayOrdersFilters()
  },
  mounted() {
    $("#LockingsDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn background-adn ' },
          { "extend": 'copy', "className": 'btn background-adn' },
          { "extend": 'csv', "className": 'btn background-adn' },
          { "extend": 'print', "className": 'btn me-0 background-adn' },
        ],
        columns: this.lockingColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        //stateSave: this.dataTableOptions.stateSave,
        order: [[4,'desc']]
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>
<style>
.card-title {
  font-size: 1rem;
}
.card-text {
  font-size: 1.5rem;
  font-weight: bold;
}
.btnCopyId:hover, .btnDetailLocking:hover,  .btnModifyLocking:hover  , .btnDeleteLocking:hover, .btnCancelLocking:hover {
  color: #FED54F  !important;
}
.btn:hover{
  color:#7D8235 !important;;
}
.tx-primary-adn{
  color:#7D8235;
}
.background-adn{
  background-color: #FED54F  !important;
  color: black;
}
.container {
  width: 1200px;
  margin: 0 auto;
}

.progress-meter {
  padding: 0;
}

ol.progress-meter {
  padding-bottom: 9.5px;
  list-style-type: none;
}
ol.progress-meter li {
  display: inline-block;
  text-align: center;
  text-indent: -19px;
  height: 36px;
  width: 24.99%;
  font-size: 12px;
  border-bottom-width: 4px;
  border-bottom-style: solid;
}
ol.progress-meter li:before {
  position: relative;
  float: left;
  text-indent: 0;
  left: -webkit-calc(50% - 9.5px);
  left: -moz-calc(50% - 9.5px);
  left: -ms-calc(50% - 9.5px);
  left: -o-calc(50% - 9.5px);
  left: calc(50% - 9.5px);
}
ol.progress-meter li.done {
  font-size: 12px;
}
ol.progress-meter li.done:before {
  content: "\2713";
  height: 19px;
  width: 19px;
  line-height: 21.85px;
  bottom: -28.175px;
  border: none;
  border-radius: 19px;
}
ol.progress-meter li.todo {
  font-size: 12px;
}
ol.progress-meter li.todo:before {
  content: "\2B24";
  font-size: 17.1px;
  bottom: -26.95px;
  line-height: 18.05px;
}
ol.progress-meter li.done {
  color: black;
  border-bottom-color: yellowgreen;
}
ol.progress-meter li.done:before {
  color: white;
  background-color: yellowgreen;
}
ol.progress-meter li.todo {
  color: silver;
  border-bottom-color: silver;
}
ol.progress-meter li.todo:before {
  color: silver;
}

</style>