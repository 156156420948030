<template>
<!-- SIDE MENU Left -->
<div :class="(visibility.sideMenu)?'nav-side-menu-left':'nav-side-menu-left-collapsed'"
  class=" overflow-hidden" style="background-color: white">  
  <div style="position: relative; height: 4.5rem; margin:0;"></div>

    <!-- Nombre de usuario -->
    <div class="menu-user p-4">
      <div class="top-menu-profile-button"
      style="vertical-align: middle;"
      @click="onLeftSideMenuItemClicked('profile')">
        <ion-icon name="person" class="top-menu-icon  mt-2 align-middle" style="font-size: 25px; color:#FED54F"></ion-icon>
      </div>
      <div class="col ps-3" style="align-content: center;">  
        <p class="tx-16 m-0 p-0"><a class="tx-black m-0 p-0" style="align-self: center; " 
        @click="onLeftSideMenuItemClicked('profile')">
          {{session.user.name+" "+session.user.lastName}}
        </a></p>
      </div>
    </div>
    <div class="col-12">
        <a class="tx-black text-nowrap border-1 border-danger rounded-5 m-0 p-2"
        @click="logOutRequest">
          <ion-icon name="exit-outline" :alt="dictionary['Cerrar sesión'][session.user.language]"
          class="menu-item-icon tx-22 align-bottom ms-2 mt-1"></ion-icon>
          <span class="ms-1 me-2"> 
            {{dictionary["Cerrar sesión"][session.user.language]}} 
          </span>
        </a> 
    </div>
    
    <!-- LOG OUT -->
    <div class="w-100 p-3 pb-0">
      <hr class="w-100 text-black">
    </div>

    <div class="row p-0 m-0 justify-content-center"
    v-for="page, pageName in generalPages" :key="pageName">
      <a class="mb-3 text-nowrap"
      :class="(this.$route.params.currentDisplay===page.value)?'tx-primary-adn':'text-black'"
      @click="onLeftSideMenuItemClicked(pageName)">
        <ion-icon :name="page.icon" :alt="dictionary[page.text][session.user.language]"
        class="menu-item-icon tx-30 align-bottom me-2 hydrated"></ion-icon>
        <span class="ms-1 lh-md">
          {{dictionary[page.text][session.user.language]}}
        </span>
       
      </a> 
      
    </div> 
  </div>

  <!-- SIDE MENU Right -->
  <div :class="(visibility.sideMenu)?'nav-side-menu-right':'nav-side-menu-right-collapsed'"
  class=" ms-3 pb-5 background_Alava" style="overflow: scroll; overflow-x: hidden; scrollbar-width: none;">
  <div class="d-flex d-row">
    <!-- <div class="m-0 p-0 justify-content-around col-lg-4 col-md-4 col-12">
      <div v-for="module, moduleName in this.availableModules" :key="moduleName"
        class="col-lg-11 col-md-11 col-12 mt-5 border   text-center rounded-5 sombra">
        <h5>
          <a @click="onModuleClicked(moduleName)"
          class="col-12 aside-anchor text-center">
            <ion-icon :name="this.modules[moduleName].icon" class="tx-20 me-3 pt-3"></ion-icon>
            {{ dictionary[this.modules[moduleName].text][session.user.language].toUpperCase() }}
          </a>
        </h5>
        <ul class="col-12 column m-0 p-0">
          <li v-for="page, pageName in module" :key="pageName"
          @click="onRightSideMenuItemClicked(pageName, moduleName)"
          class="col-12 tx-16 mb-3" style="list-style: none; cursor: pointer;">
            <button class="aside-list-button"> {{ dictionary[this.pages[pageName].text][session.user.language] }} </button>
          </li>
          
         <li class="col-12 tx-16 mb-3"
    v-for="page, pageName in generalPages" :key="pageName"
      
      @click="onLeftSideMenuItemClicked(pageName)" style="list-style: none; cursor: pointer;">       
        
        <button class="aside-list-button">
          {{dictionary[page.text][session.user.language]}}
        </button>   
          
    </li> 
        </ul>
      </div>      -->

    </div>
          <div class="box justify-content-center d-flex align-items-end mt-3 m-0 p-0">
            <div class="row justify-content-center align-items-end mt-3 m-0 p-0">

              <img title="Locker" src="@/assets/img/LOCKER-FINAL-OK.png" class="img-fluid m-auto"
                style="max-height: 700px; max-width: 700px;">




            </div>
          <!-- </div> -->
          </div>
  </div>
</template>

<script>
import api from '@/services/api';
export default {
  name: "SideMenuComponent",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    availableModules: {
      type: Object,
      requiered: true,
    }
  },
  data() {
    return {
      generalPages: {
        companies: {text:"Empresas", icon: "briefcase-outline", value:"companies"},
        users: {text:"Usuarios", icon: "people-outline", value:"users"}
      },
      pages: {
        dashboard: {text:"Dashboard", icon: "home-outline", value: "dashboard"},
        companies: {text:"Empresas", icon: "briefcase-outline", value:"companies"},
        users: {text:"Usuarios", icon: "people-outline", value:"users"},
        emplacements: {text:"Emplazamientos", icon: "location-outline", value: "emplacements"},
        machines: {text:"Máquinas", icon: "cog-outline", value: "machines"},
        lockers: {text:"Lockers", icon: "cog-outline", value: "lockers"},
        bundles: {text:"Conjuntos", icon: "grid-outline", value: "bundles"},
        devices: {text:"Dispositivos", icon: "hardware-chip-outline", value: "devices"},
        products: {text:"Productos", icon: "cube-outline", value: "products"},
        stocks: {text:"Stock", icon: "layers-outline", value: "stocks"},
        receptions: {text:"Recepción", icon: "arrow-undo-outline", value: "receptions"},
        expeditions: {text:"Expedición", icon: "arrow-redo-outline", value: "expeditions"},
        orders: {text:"Pedidos", icon: "basket-outline", value: "orders"},
        refunds: {text:"Devoluciones", icon: "trash-bin-outline", value: "refunds"},
        lockings: {text:"Pedidos", icon: "lock-open-outline", value: "lockings"},
        charts: {text:"Gráficas", icon: "stats-chart-outline", value: "charts"},
        warnings: {text:"Avisos", icon: "mail-outline", value: "warnings"},
      },
      modules: {
        devices: {text:"Dispositivos", icon: "hardware-chip-outline", value: "devices"},
        fabric: {text:"Fabricación", icon: "business-outline", value: "fabric"},
        storage: {text:"Almacén", icon: "grid-outline", value: "storage"},
        smartLockers: {text:"Smart Lockers", icon: "lock-open-outline", value: "smartLockers"},
      },
    }
  },
  emits: ["toggleSideMenu", "logOutRequest" ],
  methods: {
    // NAVIGATE 
    onLeftSideMenuItemClicked(pageName) {
      this.$emit('toggleSideMenu');
      this.$router.replace({params: {currentModule: "smartLockers", currentDisplay: pageName, targetEntity: ""} })
    },
    onRightSideMenuItemClicked(pageName, moduleName) {
      api.pointAtModule(moduleName);
      this.$emit('toggleSideMenu');
      this.$router.replace({params: {currentModule: moduleName, currentDisplay: pageName, targetEntity: ""} })
    },
    onModuleClicked(moduleName) {
      api.pointAtModule(moduleName);
      this.$router.replace({params: {currentModule: moduleName, currentDisplay: ""} })
    },
    // LOG OUT
    logOutRequest() {
      this.$emit('logOutRequest');
    }
  },async created() {

    console.log(this.visibility)
  },
  
}
</script>

<style>
.nav-side-menu-left {
  height: 100vh;
  width: 25%;
  color: #FFF;
  font-weight:400;
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
}

.nav-side-menu-right {
  height: 100vh;
  width: 75%;
  color: #FFF;
  font-weight:400;
  z-index: 99;
  position: fixed;
  top: 0;
  right: 0;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  padding-top: 4.5rem;
}

@media (max-width: 767px) {
  .nav-side-menu-left {
    height: 40vh;
    width: 100%;
    transition: all 0.3s;
    top: 0;
  }
  .nav-side-menu-right {
    height: 60vh;
    width: 100%;
    transition: all 0.3s;
    top: 40vh;
    padding-top: 0;
  }
}

.nav-side-menu-left-collapsed {
  height: 100%;
  width: 0;
  color: #FFF;
  font-weight:400;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  position: fixed;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
}

.nav-side-menu-right-collapsed {
  height: 100%;
  width: 0;
  color: #FFF;
  font-weight:400;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  position: fixed;
  transition: all 0.3s;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  padding-top: 4.5rem;
}

@media (max-width: 767px) {
  .nav-side-menu-right-collapsed {
    top: 16.7rem;
  }
}

.menu-item-icon {
  cursor: pointer;
}

.menu-item-icon:hover {
  opacity: 0.75;
  transition: all 0.3s;
}

.menu-user{
  display: flex;
  justify-content: center;
  float: left;
  align-self: center;
  margin: 0 auto;
  margin-top: 2rem;
  transition: all 0.3s;
}

@media (max-width: 767px) {
  .menu-user {
    margin-top: 0;
  }
}

.menu-item-text{
  float: left;
  margin-left: 10%;
  transition: all 0.3s;
}
.background_Alava {
  background-image: url("@/assets/img/inea-separacion-amarilla-gr.png");
  background-position-x: 50%;
  background-position-y: 50%;
  background-repeat: repeat-x;
  background-color: white;
}
.tx-primary-adn{
  color:#7D8235;
}
</style>
