<template>
<!-- MODAL Text Alert -->
  <div id="textAlertModal"
  class="col-12 justify-content-center pe-6"
  style="padding: 15vh 20%">
    <div class="col-12 m-0 p-0 bg-caja rounded-5">

      <div class="form-group col-12 p-3">
        <div class="col-12 m-0 p-0">
          
          <div class="col-12 border border rounded overflow-hidden m-0 p-1 pb-0">
            <div class=" bg-clomos-black border border-black text-white p-1 m-0 mb-3 rounded"> 
             {{  alertHeader }}
            </div>

            <span v-show="alertTarget!= ''" class="col-12">
              {{ alertTarget }}
            </span>

            <span v-for="alertText in alertTexts" :key="alertText" class="col-12">
              {{ alertText }}
            </span>

            <div v-if="alertInput != undefined && (alertInput === 'date' || alertInput === 'datetime-local')" class="col-12 d-flex flex-column m-0 p-0">
              <span class="col-12 tx-bold ">
                {{dictionary['Fecha'][session.user.language]+": "}}
              </span>
              <input id="alertInput" :type="alertInput" :value="alertValue" @change="onChange(event)"
              class="form-control form-control-line ps-2 pe-2" style="color:#000!important">
            </div>

            <div class="row p-2">

              <div class="col-lg-6 col-md-6 col-12 p-2 ps-1 pe-1">
<!-- SUBMIT -->
                <button type="button" id="btnSubmitCreateNewEmplacement" class="button-ok bg-clomos-black w-100"
                @click="onSubmit">
                {{dictionary['Aceptar'][session.user.language]}}
                </button>
              </div>

              <div class="col-lg-6 col-md-6 col-12 p-2 ps-1 pe-1">
<!-- DISMISS -->
                <button type="button" id="btnSubmitCreateNewEmplacement" class="button-ok bg-clomos-black w-100"
                @click="onDismiss">
                {{dictionary['Cancelar'][session.user.language]}}
                </button>
              </div>

            </div>

          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
export default {
  name: "TextAlertModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    alertHeader: {
      type: String,
      requiered: true,
    },
    alertTarget: {
      type: String,
      requiered: true,
    },
    alertTexts: {
      type: Array,
      requiered: true,
    },
    alertInput: {
      type: String,
      requiered: true,
    },
    alertValue: {
      type: String,
      requiered: true,
    }
  },
  emits: ["acceptAlert", "dismissAlert", "updateAlertValue"],
  methods: {
    onSubmit() {
      if (this.alertInput != undefined) {
        if (this.alertValue === "") {
          if ($("#alertInput").get()[0] != undefined) $("#alertInput").get()[0].classList.add("missing");
          setTimeout(() => {
            if ($("#alertInput").get()[0] != undefined) $("#alertInput").get()[0].classList.remove("missing")
          }, 3000);
          return;
        }
      }
      this.$emit('acceptAlert');
    },
    onDismiss() {
      this.$emit('dismissAlert');
    },
    onChange(event) {
      this.$emit('updateAlertValue', event.target.value);
    },
  }
}
</script>

<style>
#textAlertModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>