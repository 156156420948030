export default {
    // DATETIME
    parseDateTimeToString(dateTime) {
        if (dateTime.getUTCFullYear === undefined) dateTime = new Date(dateTime);
        let year = dateTime.getUTCFullYear();
        let month = String(dateTime.getUTCMonth() + 1).padStart(2, '0'); // Month is 0-based
        let day = String(dateTime.getUTCDate()).padStart(2, '0');

        let hour = String(dateTime.getUTCHours()).padStart(2, '0');
        let minute = String(dateTime.getUTCMinutes()).padStart(2, '0');
        let second = String(dateTime.getUTCSeconds()).padStart(2, '0');

        let dateTimeStr = year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
        return dateTimeStr;
    },

    parseDateUserTimezone(date, user, dayjs) {
        let formatedDate = "-";
        let parsedDate = (date != "")
            ? dayjs(date).utc("z").local().tz(user.dateTimeZone).$d
            : "";
        if (parsedDate != "") {
            let d = new Date(parsedDate);
            switch (user.dateTimeFormat) {
                case "DD/MM/YYYY":
                    formatedDate = String(d.getDate()).padStart(2, "0") + "/" + String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getFullYear()) + " " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
                    break;
                case "MM/DD/YYYY":
                    formatedDate = String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
                    break;
                default:
                    formatedDate = String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
                    break;
            }
        }
        return formatedDate;
    },

    // GEOJSON
    parseStringToGeoJSON(str) {
        let geoJSON = {
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: [0, 0]
            }
        }
        try {
            let parsedGeoJSON = JSON.parse(str);
            if (parsedGeoJSON.geometry != undefined)
                geoJSON = parsedGeoJSON;
        }
        catch {
            console.warn("Invalid strign as GeoJSON: " + str);
        }
        return geoJSON;
    },

    // SESSION
    parseSessionDataFromResponse(response, callback = undefined) {
        if (response != undefined && response["status-code"] === "200" && response.usuario != undefined) {
            let session = {
                user: {
                    id: response.usuario.idUsuario,
                    name: response.usuario.nombre,
                    lastName: response.usuario.apellido,
                    nifCif: response.usuario.identificacionFiscal,
                    email: response.usuario.email,
                    telephone: response.usuario.telefono,
                    province: response.usuario.provincia,
                    state: response.usuario.estado,
                    country: response.usuario.pais,
                    adress1: response.usuario.direccion1,
                    adress2: response.usuario.direccion2,
                    rol: response.usuario.rol,
                    language: response.usuario.idioma,
                    unitTemperature: response.usuario.unidadTemperatura,
                    unitDistance: response.usuario.unidadDistancia,
                    dateTimeZone: response.usuario.zonaHoraria,
                    dateTimeFormat: response.usuario.formatoFecha,
                    habitual: response.usuario.habitual,
                    fieldSeparator: response.usuario.separadorCampos,
                    creationDateTime: response.usuario.fechaCreacion,
                    lastConnexion: response.usuario.ultimaConexion,
                },
                authorisation: {
                    availableModules: {}
                }
            }
            if (response.autorizacion.modulosPermitidos != undefined && response.autorizacion.modulosPermitidos != "")
                session.authorisation.availableModules = JSON.parse(response.autorizacion.modulosPermitidos);
           
            if (callback && typeof callback === "function")
                callback(this.session);
            return session
        }
        else {
            console.log("WARNING: Failed to parse 'session'.");
            console.log(response);
            return {};
        }
    },

    // USERS
    parseUserFromResponse(response, callback = undefined) {
        if (response != undefined && response["status-code"] === "200" && response.usuario.idUsuario != undefined) {
            let user = {
                id: response.usuario.idUsuario,
                name: response.usuario.nombre,
                lastName: response.usuario.apellido,
                nifCif: response.usuario.identificacionFiscal,
                email: response.usuario.email,
                province: response.usuario.provincia,
                state: response.usuario.estado,
                country: response.usuario.pais,
                adress1: response.usuario.direccion1,
                adress2: response.usuario.direccion2,
                telephone: response.usuario.telefono,
                rol: response.usuario.rol,
                language: response.usuario.idioma,
                unitTemperature: response.usuario.unidadTemperatura,
                unitDistance: response.usuario.unidadDistancia,
                dateTimeZone: response.usuario.zonaHoraria,
                dateTimeFormat: response.usuario.formatoFecha,
                habitual: response.usuario.habitual,
                fieldSeparator: response.usuario.separadorCampos,
                creationDateTime: response.usuario.fechaCreacion,
                lastConnexion: response.usuario.ultimaConexion,
                authorisation: {
                    availableModules: {}
                }
            }
            if (response.autorizacion.modulosPermitidos != undefined && response.autorizacion.modulosPermitidos != "")
                user.authorisation.availableModules = JSON.parse(response.autorizacion.modulosPermitidos);
            if (callback && typeof callback === "function")
                callback(user);
            return user;
        }
        else {
            console.log("WARNING: Failed to parse 'user'.")
            console.log(response);
            return {};
        }
    },

    parseUsersFromResponse(response, callback = undefined) {
        let userList = [];
        if (response != undefined && response["status-code"] === "200" && response.UserList != undefined) {
            for (let i in response.UserList) {
                userList[i] = {
                    id: response.UserList[i].idUsuario,
                    name: response.UserList[i].nombre,
                    lastName: response.UserList[i].apellido,
                    email: response.UserList[i].email,
                    position: response.UserList[i].cargo,
                    province: response.UserList[i].provincia,
                    state: response.UserList[i].estado,
                    country: response.UserList[i].pais,
                    adress1: response.UserList[i].direccion1,
                    adress2: response.UserList[i].direccion2,
                    telephone: response.UserList[i].telefono,
                    nifCif: response.UserList[i].identificacionFiscal,
                    rol: response.UserList[i].rol,
                    language: response.UserList[i].idioma,
                    unitTemperature: response.UserList[i].unidadTemperatura,
                    unitDistance: response.UserList[i].unidadDistancia,
                    dateTimeZone: response.UserList[i].zonaHoraria,
                    dateTimeFormat: response.UserList[i].formatoFecha,
                    habitual: response.UserList[i].habitual,
                    fieldSeparator: response.UserList[i].separadorCampos,
                    creationDateTime: response.UserList[i].fechaCreacion,
                    lastConnexion: response.UserList[i].ultimaConexion,
                }
            }
            if (callback && typeof callback === "function") callback(userList);
            return userList;
        }
        else {
            console.warn("WARNING: Failed to parse 'users'.");
            console.warn(response);
            return [];
        }
    },

    // COMPANIES
    parseCompanyFromResponse(response, callback = undefined) {
        if (response != undefined && response["status-code"] === "200" && response.empresa != undefined) {
            let company = {
                nifCif: response.empresa.identificacionFiscal,
                name: response.empresa.nombre,
                email: response.empresa.emailContacto,
                telephone: response.empresa.telContacto,
                adress: response.empresa.direccion,
                country: response.empresa.pais,
                sector: response.empresa.sector,
                equipmentType: response.empresa.tipoEquipamiento,
                params: (response.empresa.parametros != "") ? JSON.parse(response.empresa.parametros) : "",
                type: response.empresa.tipo,
                logoSrc: response.empresa.logo
            };
            if (callback && typeof callback === "function") callback(company);
            return company;
        }
        else {
            console.warn("WARNING: Failed to parse 'company'.");
            console.warn(response);
            return {};
        }
    },

    parseAvailableCompaniesFromResponse(response, callback = undefined) {
        let availableCompanies = [];
        if (response != undefined && response["status-code"] === "200" && response.empresas != undefined) {
            for (let i in response.empresas) {
                availableCompanies[i] = {
                    nifCif: response.empresas[i].identificacionFiscal,
                    name: response.empresas[i].nombre,
                    email: response.empresas[i].emailContacto,
                    telephone: response.empresas[i].telContacto,
                    adress: response.empresas[i].direccion,
                    country: response.empresas[i].pais,
                    sector: response.empresas[i].sector,
                    equipmentType: response.empresas[i].tipoEquipamiento,
                    params: (response.empresas[i].parametros != "") ? JSON.parse(response.empresas[i].parametros) : "",
                    type: response.empresas[i].tipo,
                    logoSrc: response.empresas[i].logo,
                    relation: {
                        referenceCompany: response.empresas[i].relacion.empresaDeReferencia,
                        destinationCompany: response.empresas[i].relacion.empresaDestino,
                        profile: response.empresas[i].relacion.perfil,
                        publicInformation: response.empresas[i].relacion.informacionPublica,
                        businessUnit: response.empresas[i].relacion.unidadDeNegocio,
                        relation: response.empresas[i].relacion.relacion,
                        relationParams: response.empresas[i].relacion.parametrosRelacion,
                        relationContactName: response.empresas[i].relacion.nombreContactoRelacion,
                        relationContactEmail: response.empresas[i].relacion.emailContactoRelacion,
                        relationContactTelephone: response.empresas[i].relacion.telefonoContactoRelacion,
                    }
                }
            }
            if (callback && typeof callback === "function") callback(availableCompanies);
            return availableCompanies;
        }
        else {
            console.warn("WARNING: Failed to parse 'available companies'.");
            console.warn(response);
            return [];
        }
    },

    parseCompaniesFromResponse(response, callback = undefined) {
        let companyList = [];
        if (response != undefined && response["status-code"] === "200" && response.empresas != undefined) {
            for (let i in response.empresas) {
                companyList[i] = {
                    nifCif: response.empresas[i].identificacionFiscal,
                    name: response.empresas[i].nombre,
                    email: response.empresas[i].emailContacto,
                    telephone: response.empresas[i].telContacto,
                    adress: response.empresas[i].direccion,
                    country: response.empresas[i].pais,
                    sector: response.empresas[i].sector,
                    equipmentType: response.empresas[i].tipoEquipamiento,
                    params: (response.empresas[i].parametros != "") ? JSON.parse(response.empresas[i].parametros) : "",
                    type: response.empresas[i].tipo,
                    logoSrc: response.empresas[i].logo,
                    relation: {
                        referenceCompany: response.empresas[i].relacion.empresaDeReferencia,
                        destinationCompany: response.empresas[i].relacion.empresaDestino,
                        profile: response.empresas[i].relacion.perfil,
                        publicInformation: response.empresas[i].relacion.informacionPublica,
                        businessUnit: response.empresas[i].relacion.unidadDeNegocio,
                        relation: response.empresas[i].relacion.relacion,
                        relationParams: response.empresas[i].relacion.parametrosRelacion,
                        relationContactName: response.empresas[i].relacion.nombreContactoRelacion,
                        relationContactEmail: response.empresas[i].relacion.emailContactoRelacion,
                        relationContactTelephone: response.empresas[i].relacion.telefonoContactoRelacion,
                    }
                }
            }
            if (callback && typeof callback === "function") callback(companyList);
            return companyList;
        }
        else {
            console.warn("WARNING: Failed to parse 'companies'.");
            console.warn(response);
            return [];
        }
    },

    // EMPLACEMENTS
    parseEmplacementFromResponse(response, callback = undefined) {
        if (response != undefined && response["status-code"] === "200" && response.planta != undefined) {
            let emplacementData = {
                id: response.planta.idDcl,
                dclId: response.planta.identificadorDcl,
                dclTypeId: response.planta.idTipoDcl,
                nifCif: response.planta.identificadorFiscal,
                label: response.planta.etiqueta,
                type: response.planta.nombreTipo,
                location: response.planta.emplazamiento,
                imgSrc: response.planta.urlImagen,
                communicationDateTime: response.planta.fechaUltimaComunicacion,
                geoJSON: this.parseStringToGeoJSON(response.planta.posicion),
                variables: {
                    analog: {},
                    digital: {},
                    dynamic: {},
                }
            }
            if (response.planta.VariablesAnalogicas != "") for (let i in response.planta.VariablesAnalogicas) {
                emplacementData.variables.analog[response.planta.VariablesAnalogicas[i].etiqueta] = {
                    variableId: response.planta.VariablesAnalogicas[i].idVariableAnalogica,
                    elementId: response.planta.VariablesAnalogicas[i].idElemento,
                    label: response.planta.VariablesAnalogicas[i].etiqueta,
                    getRol: response.planta.VariablesAnalogicas[i].accesoLectura,
                    setRol: response.planta.VariablesAnalogicas[i].accesoEscritura,
                    saveHistory: response.planta.VariablesAnalogicas[i].guardarHistorico,
                    value: response.planta.VariablesAnalogicas[i].valor,
                    valueDateTime: response.planta.VariablesAnalogicas[i].fechaValor,
                    max: response.planta.VariablesAnalogicas[i].limiteSuperior,
                    min: response.planta.VariablesAnalogicas[i].limiteInferior,
                    distributionGroup: response.planta.VariablesAnalogicas[i].grupoDistribucion,
                    adress: response.planta.VariablesAnalogicas[i].direccion,
                    unit: response.planta.VariablesAnalogicas[i].unidad,
                    externalAccess: response.planta.VariablesAnalogicas[i].accesoExterno,
                    type: response.planta.VariablesAnalogicas[i].tipo,
                    isStatus: response.planta.VariablesAnalogicas[i].esEstado,
                    scaleId: response.planta.VariablesAnalogicas[i].idEscala,
                    filterId: response.planta.VariablesAnalogicas[i].idFiltro,
                    isConfiguration: response.planta.VariablesAnalogicas[i].esConfiguracion,
                    events: response.planta.VariablesAnalogicas[i].eventos,
                    targetVariable: response.planta.VariablesAnalogicas[i].variablesDestino,
                    position: response.planta.VariablesAnalogicas[i].posicion,
                    history: response.planta.VariablesAnalogicas[i].historico
                }
            }
            if (response.planta.VariablesDigitales != "") for (let i in response.planta.VariablesDigitales) {
                emplacementData.variables.digital[response.planta.VariablesDigitales[i].etiqueta] = {
                    variableId: response.planta.VariablesDigitales[i].idVariableDigital,
                    elementId: response.planta.VariablesDigitales[i].idElemento,
                    label: response.planta.VariablesDigitales[i].etiqueta,
                    getRol: response.planta.VariablesDigitales[i].accesoLectura,
                    setRol: response.planta.VariablesDigitales[i].accesoEscritura,
                    saveHistory: response.planta.VariablesDigitales[i].guardarHistorico,
                    value: response.planta.VariablesDigitales[i].valor,
                    valueDateTime: response.planta.VariablesDigitales[i].fechaValor,
                    negativeLogic: response.planta.VariablesDigitales[i].logicaNegativa,
                    distributionGroup: response.planta.VariablesDigitales[i].grupoDistribucion,
                    adress: response.planta.VariablesDigitales[i].direccion,
                    externalAccess: response.planta.VariablesDigitales[i].accesoExterno,
                    type: response.planta.VariablesDigitales[i].tipo,
                    isConfiguration: response.planta.VariablesDigitales[i].esConfiguracion,
                    targetVariable: response.planta.VariablesDigitales[i].variablesDestino,
                    position: response.planta.VariablesDigitales[i].posicion
                }
            }
            if (response.planta.VariablesDinamicas != "") for (let i in response.planta.VariablesDinamicas) {
                emplacementData.variables.dynamic[response.planta.VariablesDinamicas[i].etiqueta] = {
                    variableId: response.planta.VariablesDinamicas[i].idVariableDinamica,
                    elementId: response.planta.VariablesDinamicas[i].idElemento,
                    label: response.planta.VariablesDinamicas[i].etiqueta,
                    getRol: response.planta.VariablesDinamicas[i].accesoLectura,
                    setRol: response.planta.VariablesDinamicas[i].accesoEscritura,
                    saveHistory: response.planta.VariablesDinamicas[i].guardarHistorico,
                    value: response.planta.VariablesDinamicas[i].valor,
                    valueDateTime: response.planta.VariablesDinamicas[i].fechaValor,
                    distributionGroup: response.planta.VariablesDinamicas[i].grupoDistribucion,
                    adress: response.planta.VariablesDinamicas[i].direccion,
                    externalAccess: response.planta.VariablesDinamicas[i].accesoExterno,
                    type: response.planta.VariablesDinamicas[i].tipo,
                    isConfiguration: response.planta.VariablesDinamicas[i].esConfiguracion,
                    targetVariable: response.planta.VariablesDinamicas[i].variablesDestino,
                    position: response.planta.VariablesDinamicas[i].posicion
                }
            }
            if (callback && typeof callback === "function") callback(emplacementData);
            return emplacementData;
        }
        else {
            console.warn("WARNING: Failed to parse 'emplacement'.");
            console.warn(response);
            return {};
        }
    },

    parseEmplacementsFromResponse(response, callback = undefined) {
        let emplacementList = [];
        if (response != undefined && response["status-code"] === "200" && response.plantas != undefined) {
            for (let i in response.plantas) {
                emplacementList[i] = {
                    id: response.plantas[i].idDcl,
                    dclId: response.plantas[i].identificadorDcl,
                    dclTypeId: response.plantas[i].idTipoDcl,
                    nifCif: response.plantas[i].identificadorFiscal,
                    label: response.plantas[i].etiqueta,
                    type: response.plantas[i].nombreTipo,
                    location: response.plantas[i].emplazamiento,
                    imgSrc: response.plantas[i].urlImagen,
                    communicationDateTime: response.plantas[i].fechaUltimaComunicacion,
                    geoJSON: this.parseStringToGeoJSON(response.plantas[i].posicion),
                    variables: {
                        analog: {},
                        digital: {},
                        dynamic: {},
                    }
                }
                if (response.plantas[i].VariablesAnalogicas != "") for (let ii in response.plantas[i].VariablesAnalogicas) {
                    emplacementList[i].variables.analog[response.plantas[i].VariablesAnalogicas[ii].etiqueta] = {
                        variableId: response.plantas[i].VariablesAnalogicas[ii].idVariableAnalogica,
                        elementId: response.plantas[i].VariablesAnalogicas[ii].idElemento,
                        label: response.plantas[i].VariablesAnalogicas[ii].etiqueta,
                        getRol: response.plantas[i].VariablesAnalogicas[ii].accesoLectura,
                        setRol: response.plantas[i].VariablesAnalogicas[ii].accesoEscritura,
                        saveHistory: response.plantas[i].VariablesAnalogicas[ii].guardarHistorico,
                        value: response.plantas[i].VariablesAnalogicas[ii].valor,
                        valueDateTime: response.plantas[i].VariablesAnalogicas[ii].fechaValor,
                        max: response.plantas[i].VariablesAnalogicas[ii].limiteSuperior,
                        min: response.plantas[i].VariablesAnalogicas[ii].limiteInferior,
                        distributionGroup: response.plantas[i].VariablesAnalogicas[ii].grupoDistribucion,
                        adress: response.plantas[i].VariablesAnalogicas[ii].direccion,
                        unit: response.plantas[i].VariablesAnalogicas[ii].unidad,
                        externalAccess: response.plantas[i].VariablesAnalogicas[ii].accesoExterno,
                        type: response.plantas[i].VariablesAnalogicas[ii].tipo,
                        isStatus: response.plantas[i].VariablesAnalogicas[ii].esEstado,
                        scaleId: response.plantas[i].VariablesAnalogicas[ii].idEscala,
                        filterId: response.plantas[i].VariablesAnalogicas[ii].idFiltro,
                        isConfiguration: response.plantas[i].VariablesAnalogicas[ii].esConfiguracion,
                        events: response.plantas[i].VariablesAnalogicas[ii].eventos,
                        targetVariable: response.plantas[i].VariablesAnalogicas[ii].variablesDestino,
                        position: response.plantas[i].VariablesAnalogicas[ii].posicion,
                        history: response.plantas[i].VariablesAnalogicas[ii].historico
                    }
                }
                if (response.plantas[i].VariablesDigitales != "") for (let ii in response.plantas[i].VariablesDigitales) {
                    emplacementList[i].variables.digital[response.plantas[i].VariablesDigitales[ii].etiqueta] = {
                        variableId: response.plantas[i].VariablesDigitales[ii].idVariableDigital,
                        elementId: response.plantas[i].VariablesDigitales[ii].idElemento,
                        label: response.plantas[i].VariablesDigitales[ii].etiqueta,
                        getRol: response.plantas[i].VariablesDigitales[ii].accesoLectura,
                        setRol: response.plantas[i].VariablesDigitales[ii].accesoEscritura,
                        saveHistory: response.plantas[i].VariablesDigitales[ii].guardarHistorico,
                        value: response.plantas[i].VariablesDigitales[ii].valor,
                        valueDateTime: response.plantas[i].VariablesDigitales[ii].fechaValor,
                        negativeLogic: response.plantas[i].VariablesDigitales[ii].logicaNegativa,
                        distributionGroup: response.plantas[i].VariablesDigitales[ii].grupoDistribucion,
                        adress: response.plantas[i].VariablesDigitales[ii].direccion,
                        externalAccess: response.plantas[i].VariablesDigitales[ii].accesoExterno,
                        type: response.plantas[i].VariablesDigitales[ii].tipo,
                        isConfiguration: response.plantas[i].VariablesDigitales[ii].esConfiguracion,
                        targetVariable: response.plantas[i].VariablesDigitales[ii].variablesDestino,
                        position: response.plantas[i].VariablesDigitales[ii].posicion
                    }
                }
                if (response.plantas[i].VariablesDinamicas != "") for (let ii in response.plantas[i].VariablesDinamicas) {
                    emplacementList[i].variables.dynamic[response.plantas[i].VariablesDinamicas[ii].etiqueta] = {
                        variableId: response.plantas[i].VariablesDinamicas[ii].idVariableDinamica,
                        elementId: response.plantas[i].VariablesDinamicas[ii].idElemento,
                        label: response.plantas[i].VariablesDinamicas[ii].etiqueta,
                        getRol: response.plantas[i].VariablesDinamicas[ii].accesoLectura,
                        setRol: response.plantas[i].VariablesDinamicas[ii].accesoEscritura,
                        saveHistory: response.plantas[i].VariablesDinamicas[ii].guardarHistorico,
                        value: response.plantas[i].VariablesDinamicas[ii].valor,
                        valueDateTime: response.plantas[i].VariablesDinamicas[ii].fechaValor,
                        distributionGroup: response.plantas[i].VariablesDinamicas[ii].grupoDistribucion,
                        adress: response.plantas[i].VariablesDinamicas[ii].direccion,
                        externalAccess: response.plantas[i].VariablesDinamicas[ii].accesoExterno,
                        type: response.plantas[i].VariablesDinamicas[ii].tipo,
                        isConfiguration: response.plantas[i].VariablesDinamicas[ii].esConfiguracion,
                        targetVariable: response.plantas[i].VariablesDinamicas[ii].variablesDestino,
                        position: response.plantas[i].VariablesDinamicas[ii].posicion
                    }
                }
            }
            if (callback && typeof callback === "function") callback(emplacementList);
            return emplacementList;
        }
        else {
            console.warn("WARNING: Failed to parse 'emplacements'.");
            console.warn(response);
            return [];
        }
    },

    // BUNDLES
    parseBundleFromResponse(response) {
        if (response != undefined && response["status-code"] === "200" && response.conjunto != undefined) {
            let bundleData = {
                id: response.conjunto.idDcl,
                nifCif: response.conjunto.identificadorFiscal,
                dataConsumed: response.conjunto.datosConsumidos,
                label: response.conjunto.etiqueta,
                dclId: response.conjunto.identificadorDcl,
                dclTypeId: response.conjunto.idTipoDcl,
                dclClass: response.conjunto.claseDcl,
                typeName: response.conjunto.nombreTipo,
                distributionGroup: response.conjunto.grupoDistribucion,
                parent: response.conjunto.padre,
                isBase: response.conjunto.esBase,
                isPublic: response.conjunto.isPublic,
                token: response.conjunto.token,
                imgSrc: response.conjunto.urlImagen,
                emplacement: response.conjunto.emplazamiento,
                communicationDateTime: response.conjunto.fechaUltimaComunicacion,
                tieneAlarmaActivada: response.conjunto.tieneAlarmaActivada,
                version: response.conjunto.version,
                geoJSON: this.parseStringToGeoJSON(response.conjunto.posicion),
                variables: {
                    analog: {},
                    digital: {},
                    dynamic: {},
                }
            }
            if (response.conjunto.VariablesAnalogicas != "") for (let i in response.conjunto.VariablesAnalogicas) {
                bundleData.variables.analog[response.conjunto.VariablesAnalogicas[i].etiqueta] = {
                    variableId: response.conjunto.VariablesAnalogicas[i].idVariableAnalogica,
                    elementId: response.conjunto.VariablesAnalogicas[i].idElemento,
                    label: response.conjunto.VariablesAnalogicas[i].etiqueta,
                    getRol: response.conjunto.VariablesAnalogicas[i].accesoLectura,
                    setRol: response.conjunto.VariablesAnalogicas[i].accesoEscritura,
                    saveHistory: response.conjunto.VariablesAnalogicas[i].guardarHistorico,
                    value: response.conjunto.VariablesAnalogicas[i].valor,
                    valueDateTime: response.conjunto.VariablesAnalogicas[i].fechaValor,
                    max: response.conjunto.VariablesAnalogicas[i].limiteSuperior,
                    min: response.conjunto.VariablesAnalogicas[i].limiteInferior,
                    distributionGroup: response.conjunto.VariablesAnalogicas[i].grupoDistribucion,
                    adress: response.conjunto.VariablesAnalogicas[i].direccion,
                    unit: response.conjunto.VariablesAnalogicas[i].unidad,
                    externalAccess: response.conjunto.VariablesAnalogicas[i].accesoExterno,
                    type: response.conjunto.VariablesAnalogicas[i].tipo,
                    isStatus: response.conjunto.VariablesAnalogicas[i].esEstado,
                    scaleId: response.conjunto.VariablesAnalogicas[i].idEscala,
                    filterId: response.conjunto.VariablesAnalogicas[i].idFiltro,
                    isConfiguration: response.conjunto.VariablesAnalogicas[i].esConfiguracion,
                    events: response.conjunto.VariablesAnalogicas[i].eventos,
                    targetVariable: response.conjunto.VariablesAnalogicas[i].variablesDestino,
                    position: response.conjunto.VariablesAnalogicas[i].posicion,
                    history: response.conjunto.VariablesAnalogicas[i].historico
                }
            }
            if (response.conjunto.VariablesDigitales != "") for (let i in response.conjunto.VariablesDigitales) {
                bundleData.variables.digital[response.conjunto.VariablesDigitales[i].etiqueta] = {
                    variableId: response.conjunto.VariablesDigitales[i].idVariableDigital,
                    elementId: response.conjunto.VariablesDigitales[i].idElemento,
                    label: response.conjunto.VariablesDigitales[i].etiqueta,
                    getRol: response.conjunto.VariablesDigitales[i].accesoLectura,
                    setRol: response.conjunto.VariablesDigitales[i].accesoEscritura,
                    saveHistory: response.conjunto.VariablesDigitales[i].guardarHistorico,
                    value: response.conjunto.VariablesDigitales[i].valor,
                    valueDateTime: response.conjunto.VariablesDigitales[i].fechaValor,
                    negativeLogic: response.conjunto.VariablesDigitales[i].logicaNegativa,
                    distributionGroup: response.conjunto.VariablesDigitales[i].grupoDistribucion,
                    adress: response.conjunto.VariablesDigitales[i].direccion,
                    externalAccess: response.conjunto.VariablesDigitales[i].accesoExterno,
                    type: response.conjunto.VariablesDigitales[i].tipo,
                    isConfiguration: response.conjunto.VariablesDigitales[i].esConfiguracion,
                    targetVariable: response.conjunto.VariablesDigitales[i].variablesDestino,
                    position: response.conjunto.VariablesDigitales[i].posicion
                }
            }
            if (response.conjunto.VariablesDinamicas != "") for (let i in response.conjunto.VariablesDinamicas) {
                bundleData.variables.dynamic[response.conjunto.VariablesDinamicas[i].etiqueta] = {
                    variableId: response.conjunto.VariablesDinamicas[i].idVariableDinamica,
                    elementId: response.conjunto.VariablesDinamicas[i].idElemento,
                    label: response.conjunto.VariablesDinamicas[i].etiqueta,
                    getRol: response.conjunto.VariablesDinamicas[i].accesoLectura,
                    setRol: response.conjunto.VariablesDinamicas[i].accesoEscritura,
                    saveHistory: response.conjunto.VariablesDinamicas[i].guardarHistorico,
                    value: response.conjunto.VariablesDinamicas[i].valor,
                    valueDateTime: response.conjunto.VariablesDinamicas[i].fechaValor,
                    distributionGroup: response.conjunto.VariablesDinamicas[i].grupoDistribucion,
                    adress: response.conjunto.VariablesDinamicas[i].direccion,
                    externalAccess: response.conjunto.VariablesDinamicas[i].accesoExterno,
                    type: response.conjunto.VariablesDinamicas[i].tipo,
                    isConfiguration: response.conjunto.VariablesDinamicas[i].esConfiguracion,
                    targetVariable: response.conjunto.VariablesDinamicas[i].variablesDestino,
                    position: response.conjunto.VariablesDinamicas[i].posicion
                }
            }
            return bundleData;
        }
        else {
            console.warn("WARNING: Failed to parse 'bundle'.");
            console.warn(response);
            return {};
        }
    },

    parseBundlesFromResponse(response, callback = undefined) {
        let bundleList = [];
        if (response != undefined && response["status-code"] === "200" && response.conjuntos != undefined) {
            for (let i in response.conjuntos) {
                bundleList[i] = {
                    id: response.conjuntos[i].idDcl,
                    nifCif: response.conjuntos[i].identificadorFiscal,
                    dataConsumed: response.conjuntos[i].datosConsumidos,
                    label: response.conjuntos[i].etiqueta,
                    dclId: response.conjuntos[i].identificadorDcl,
                    dclTypeId: response.conjuntos[i].idTipoDcl,
                    dclClass: response.conjuntos[i].claseDcl,
                    type: response.conjuntos[i].nombreTipo,
                    status: response.conjuntos[i].estado,
                    distributionGroup: response.conjuntos[i].grupoDistribucion,
                    parent: response.conjuntos[i].padre,
                    isBase: response.conjuntos[i].esBase,
                    isPublic: response.conjuntos[i].isPublic,
                    token: response.conjuntos[i].token,
                    imgSrc: response.conjuntos[i].urlImagen,
                    emplacement: response.conjuntos[i].emplazamiento,
                    communicationDateTime: response.conjuntos[i].fechaUltimaComunicacion,
                    alarmed: response.conjuntos[i].tieneAlarmaActivada,
                    version: response.conjuntos[i].version,
                    geoJSON: this.parseStringToGeoJSON(response.conjuntos[i].posicion),
                    variables: {
                        analog: {},
                        digital: {},
                        dynamic: {},
                    }
                }
                if (response.conjuntos[i].VariablesAnalogicas != "") for (let ii in response.conjuntos[i].VariablesAnalogicas) {
                    bundleList[i].variables.analog[response.conjuntos[i].VariablesAnalogicas[ii].etiqueta] = {
                        variableId: response.conjuntos[i].VariablesAnalogicas[ii].idVariableAnalogica,
                        elementId: response.conjuntos[i].VariablesAnalogicas[ii].idElemento,
                        label: response.conjuntos[i].VariablesAnalogicas[ii].etiqueta,
                        getRol: response.conjuntos[i].VariablesAnalogicas[ii].accesoLectura,
                        setRol: response.conjuntos[i].VariablesAnalogicas[ii].accesoEscritura,
                        saveHistory: response.conjuntos[i].VariablesAnalogicas[ii].guardarHistorico,
                        value: response.conjuntos[i].VariablesAnalogicas[ii].valor,
                        valueDateTime: response.conjuntos[i].VariablesAnalogicas[ii].fechaValor,
                        max: response.conjuntos[i].VariablesAnalogicas[ii].limiteSuperior,
                        min: response.conjuntos[i].VariablesAnalogicas[ii].limiteInferior,
                        distributionGroup: response.conjuntos[i].VariablesAnalogicas[ii].grupoDistribucion,
                        adress: response.conjuntos[i].VariablesAnalogicas[ii].direccion,
                        unit: response.conjuntos[i].VariablesAnalogicas[ii].unidad,
                        externalAccess: response.conjuntos[i].VariablesAnalogicas[ii].accesoExterno,
                        type: response.conjuntos[i].VariablesAnalogicas[ii].tipo,
                        isStatus: response.conjuntos[i].VariablesAnalogicas[ii].esEstado,
                        scaleId: response.conjuntos[i].VariablesAnalogicas[ii].idEscala,
                        filterId: response.conjuntos[i].VariablesAnalogicas[ii].idFiltro,
                        isConfiguration: response.conjuntos[i].VariablesAnalogicas[ii].esConfiguracion,
                        events: response.conjuntos[i].VariablesAnalogicas[ii].eventos,
                        targetVariable: response.conjuntos[i].VariablesAnalogicas[ii].variablesDestino,
                        position: response.conjuntos[i].VariablesAnalogicas[ii].posicion,
                        history: response.conjuntos[i].VariablesAnalogicas[ii].historico
                    }
                }
                if (response.conjuntos[i].VariablesDigitales != "") for (let ii in response.conjuntos[i].VariablesDigitales) {
                    bundleList[i].variables.digital[response.conjuntos[i].VariablesDigitales[ii].etiqueta] = {
                        variableId: response.conjuntos[i].VariablesDigitales[ii].idVariableDigital,
                        elementId: response.conjuntos[i].VariablesDigitales[ii].idElemento,
                        label: response.conjuntos[i].VariablesDigitales[ii].etiqueta,
                        getRol: response.conjuntos[i].VariablesDigitales[ii].accesoLectura,
                        setRol: response.conjuntos[i].VariablesDigitales[ii].accesoEscritura,
                        saveHistory: response.conjuntos[i].VariablesDigitales[ii].guardarHistorico,
                        value: response.conjuntos[i].VariablesDigitales[ii].valor,
                        valueDateTime: response.conjuntos[i].VariablesDigitales[ii].fechaValor,
                        negativeLogic: response.conjuntos[i].VariablesDigitales[ii].logicaNegativa,
                        distributionGroup: response.conjuntos[i].VariablesDigitales[ii].grupoDistribucion,
                        adress: response.conjuntos[i].VariablesDigitales[ii].direccion,
                        externalAccess: response.conjuntos[i].VariablesDigitales[ii].accesoExterno,
                        type: response.conjuntos[i].VariablesDigitales[ii].tipo,
                        isConfiguration: response.conjuntos[i].VariablesDigitales[ii].esConfiguracion,
                        targetVariable: response.conjuntos[i].VariablesDigitales[ii].variablesDestino,
                        position: response.conjuntos[i].VariablesDigitales[ii].posicion
                    }
                }
                if (response.conjuntos[i].VariablesDinamicas != "") for (let ii in response.conjuntos[i].VariablesDinamicas) {
                    bundleList[i].variables.dynamic[response.conjuntos[i].VariablesDinamicas[ii].etiqueta] = {
                        variableId: response.conjuntos[i].VariablesDinamicas[ii].idVariableDinamica,
                        elementId: response.conjuntos[i].VariablesDinamicas[ii].idElemento,
                        label: response.conjuntos[i].VariablesDinamicas[ii].etiqueta,
                        getRol: response.conjuntos[i].VariablesDinamicas[ii].accesoLectura,
                        setRol: response.conjuntos[i].VariablesDinamicas[ii].accesoEscritura,
                        saveHistory: response.conjuntos[i].VariablesDinamicas[ii].guardarHistorico,
                        value: response.conjuntos[i].VariablesDinamicas[ii].valor,
                        valueDateTime: response.conjuntos[i].VariablesDinamicas[ii].fechaValor,
                        distributionGroup: response.conjuntos[i].VariablesDinamicas[ii].grupoDistribucion,
                        adress: response.conjuntos[i].VariablesDinamicas[ii].direccion,
                        externalAccess: response.conjuntos[i].VariablesDinamicas[ii].accesoExterno,
                        type: response.conjuntos[i].VariablesDinamicas[ii].tipo,
                        isConfiguration: response.conjuntos[i].VariablesDinamicas[ii].esConfiguracion,
                        targetVariable: response.conjuntos[i].VariablesDinamicas[ii].variablesDestino,
                        position: response.conjuntos[i].VariablesDinamicas[ii].posicion
                    }
                }
            }
            if (callback && typeof callback === "function") callback(bundleList);
            return bundleList;
        }
        else {
            console.warn("WARNING: Failed to parse 'bundles'.");
            console.warn(response);
            return [];
        }
    },

    // MACHINES
    parseMachineFromResponse(response) {
        if (response != undefined && response["status-code"] === "200" && response.maquina != undefined) {
            let deviceData = {
                id: response.maquina.idDcl,
                nifCif: response.maquina.identificadorFiscal,
                dataConsumed: response.maquina.datosConsumidos,
                label: response.maquina.etiqueta,
                dclId: response.maquina.identificadorDcl,
                dclTypeId: response.maquina.idTipoDcl,
                dclClass: response.maquina.claseDcl,
                typeName: response.maquina.nombreTipo,
                distributionGroup: response.maquina.grupoDistribucion,
                parent: response.maquina.padre,
                isBase: response.maquina.esBase,
                isPublic: response.maquina.isPublic,
                token: response.maquina.token,
                imgSrc: response.maquina.urlImagen,
                emplacement: response.maquina.emplazamiento,
                communicationDateTime: response.maquina.fechaUltimaComunicacion,
                tieneAlarmaActivada: response.maquina.tieneAlarmaActivada,
                version: response.maquina.version,
                geoJSON: this.parseStringToGeoJSON(response.maquina.posicion),
                variables: {
                    analog: {},
                    digital: {},
                    dynamic: {},
                }
            }
            if (response.maquina.VariablesAnalogicas != "") for (let i in response.maquina.VariablesAnalogicas) {
                deviceData.variables.analog[response.maquina.VariablesAnalogicas[i].etiqueta] = {
                    variableId: response.maquina.VariablesAnalogicas[i].idVariableAnalogica,
                    elementId: response.maquina.VariablesAnalogicas[i].idElemento,
                    label: response.maquina.VariablesAnalogicas[i].etiqueta,
                    getRol: response.maquina.VariablesAnalogicas[i].accesoLectura,
                    setRol: response.maquina.VariablesAnalogicas[i].accesoEscritura,
                    saveHistory: response.maquina.VariablesAnalogicas[i].guardarHistorico,
                    value: response.maquina.VariablesAnalogicas[i].valor,
                    valueDateTime: response.maquina.VariablesAnalogicas[i].fechaValor,
                    max: response.maquina.VariablesAnalogicas[i].limiteSuperior,
                    min: response.maquina.VariablesAnalogicas[i].limiteInferior,
                    distributionGroup: response.maquina.VariablesAnalogicas[i].grupoDistribucion,
                    adress: response.maquina.VariablesAnalogicas[i].direccion,
                    unit: response.maquina.VariablesAnalogicas[i].unidad,
                    externalAccess: response.maquina.VariablesAnalogicas[i].accesoExterno,
                    type: response.maquina.VariablesAnalogicas[i].tipo,
                    isStatus: response.maquina.VariablesAnalogicas[i].esEstado,
                    scaleId: response.maquina.VariablesAnalogicas[i].idEscala,
                    filterId: response.maquina.VariablesAnalogicas[i].idFiltro,
                    isConfiguration: response.maquina.VariablesAnalogicas[i].esConfiguracion,
                    events: response.maquina.VariablesAnalogicas[i].eventos,
                    targetVariable: response.maquina.VariablesAnalogicas[i].variablesDestino,
                    position: response.maquina.VariablesAnalogicas[i].posicion,
                    history: response.maquina.VariablesAnalogicas[i].historico
                }
            }
            if (response.maquina.VariablesDigitales != "") for (let i in response.maquina.VariablesDigitales) {
                deviceData.variables.digital[response.maquina.VariablesDigitales[i].etiqueta] = {
                    variableId: response.maquina.VariablesDigitales[i].idVariableDigital,
                    elementId: response.maquina.VariablesDigitales[i].idElemento,
                    label: response.maquina.VariablesDigitales[i].etiqueta,
                    getRol: response.maquina.VariablesDigitales[i].accesoLectura,
                    setRol: response.maquina.VariablesDigitales[i].accesoEscritura,
                    saveHistory: response.maquina.VariablesDigitales[i].guardarHistorico,
                    value: response.maquina.VariablesDigitales[i].valor,
                    valueDateTime: response.maquina.VariablesDigitales[i].fechaValor,
                    negativeLogic: response.maquina.VariablesDigitales[i].logicaNegativa,
                    distributionGroup: response.maquina.VariablesDigitales[i].grupoDistribucion,
                    adress: response.maquina.VariablesDigitales[i].direccion,
                    externalAccess: response.maquina.VariablesDigitales[i].accesoExterno,
                    type: response.maquina.VariablesDigitales[i].tipo,
                    isConfiguration: response.maquina.VariablesDigitales[i].esConfiguracion,
                    targetVariable: response.maquina.VariablesDigitales[i].variablesDestino,
                    position: response.maquina.VariablesDigitales[i].posicion
                }
            }
            if (response.maquina.VariablesDinamicas != "") for (let i in response.maquina.VariablesDinamicas) {
                deviceData.variables.dynamic[response.maquina.VariablesDinamicas[i].etiqueta] = {
                    variableId: response.maquina.VariablesDinamicas[i].idVariableDinamica,
                    elementId: response.maquina.VariablesDinamicas[i].idElemento,
                    label: response.maquina.VariablesDinamicas[i].etiqueta,
                    getRol: response.maquina.VariablesDinamicas[i].accesoLectura,
                    setRol: response.maquina.VariablesDinamicas[i].accesoEscritura,
                    saveHistory: response.maquina.VariablesDinamicas[i].guardarHistorico,
                    value: response.maquina.VariablesDinamicas[i].valor,
                    valueDateTime: response.maquina.VariablesDinamicas[i].fechaValor,
                    distributionGroup: response.maquina.VariablesDinamicas[i].grupoDistribucion,
                    adress: response.maquina.VariablesDinamicas[i].direccion,
                    externalAccess: response.maquina.VariablesDinamicas[i].accesoExterno,
                    type: response.maquina.VariablesDinamicas[i].tipo,
                    isConfiguration: response.maquina.VariablesDinamicas[i].esConfiguracion,
                    targetVariable: response.maquina.VariablesDinamicas[i].variablesDestino,
                    position: response.maquina.VariablesDinamicas[i].posicion
                }
            }
            return deviceData;
        }
        else {
            console.warn("WARNING: Failed to parse 'machine'.");
            console.warn(response);
            return {};
        }
    },

    parseMachinesFromResponse(response, callback = undefined) {
        let machineList = [];
        if (response != undefined && response["status-code"] === "200" && response.maquinas != undefined) {
            for (let i in response.maquinas) {
                machineList[i] = {
                    id: response.maquinas[i].idDcl,
                    nifCif: response.maquinas[i].identificadorFiscal,
                    dataConsumed: response.maquinas[i].datosConsumidos,
                    label: response.maquinas[i].etiqueta,
                    dclId: response.maquinas[i].identificadorDcl,
                    dclTypeId: response.maquinas[i].idTipoDcl,
                    dclClass: response.maquinas[i].claseDcl,
                    type: response.maquinas[i].nombreTipo,
                    distributionGroup: response.maquinas[i].grupoDistribucion,
                    parent: response.maquinas[i].padre,
                    isBase: response.maquinas[i].esBase,
                    isPublic: response.maquinas[i].isPublic,
                    token: response.maquinas[i].token,
                    imgSrc: response.maquinas[i].urlImagen,
                    emplacement: response.maquinas[i].emplazamiento,
                    communicationDateTime: response.maquinas[i].fechaUltimaComunicacion,
                    alarmed: response.maquinas[i].tieneAlarmaActivada,
                    version: response.maquinas[i].version,
                    geoJSON: this.parseStringToGeoJSON(response.maquinas[i].posicio)
                }
            }
            if (callback && typeof callback === "function") callback(machineList);
            return machineList;
        }
        else {
            console.warn("WARNING: Failed to parse 'machines'.");
            console.warn(response);
            return [];
        }
    },

    parseLockersFromResponse(response, callback = undefined) {
        let machineList = [];
        if (response != undefined && response["status-code"] === "200" && response.lockers != undefined) {
            for (let i in response.lockers) {
                machineList[i] = {
                    id: response.lockers[i].idDcl,
                    nifCif: response.lockers[i].identificadorFiscal,
                    dataConsumed: response.lockers[i].datosConsumidos,
                    label: response.lockers[i].etiqueta,
                    dclId: response.lockers[i].identificadorDcl,
                    dclTypeId: response.lockers[i].idTipoDcl,
                    dclClass: response.lockers[i].claseDcl,
                    type: response.lockers[i].nombreTipo,
                    distributionGroup: response.lockers[i].grupoDistribucion,
                    parent: response.lockers[i].padre,
                    isBase: response.lockers[i].esBase,
                    isPublic: response.lockers[i].isPublic,
                    token: response.lockers[i].token,
                    imgSrc: response.lockers[i].urlImagen,
                    emplacement: response.lockers[i].emplazamiento,
                    communicationDateTime: response.lockers[i].fechaUltimaComunicacion,
                    alarmed: response.lockers[i].tieneAlarmaActivada,
                    version: response.lockers[i].version,
                    geoJSON: this.parseStringToGeoJSON(response.lockers[i].posicio)
                }
            }
            if (callback && typeof callback === "function") callback(machineList);
            return machineList;
        }
        else {
            console.warn("WARNING: Failed to parse 'machines'.");
            console.warn(response);
            return [];
        }
    },

    // DEVICES
    parseDeviceFromResponse(response) {
        if (response != undefined && response["status-code"] === "200" && response.dispositivo != undefined) {
            let deviceData = {
                id: response.dispositivo.idDcl,
                nifCif: response.dispositivo.identificadorFiscal,
                dataConsumed: response.dispositivo.datosConsumidos,
                label: response.dispositivo.etiqueta,
                dclId: response.dispositivo.identificadorDcl,
                dclTypeId: response.dispositivo.idTipoDcl,
                dclClass: response.dispositivo.claseDcl,
                typeName: response.dispositivo.nombreTipo,
                distributionGroup: response.dispositivo.grupoDistribucion,
                parent: response.dispositivo.padre,
                isBase: response.dispositivo.esBase,
                isPublic: response.dispositivo.isPublic,
                token: response.dispositivo.token,
                imgSrc: response.dispositivo.urlImagen,
                emplacement: response.dispositivo.emplazamiento,
                communicationDateTime: response.dispositivo.fechaUltimaComunicacion,
                tieneAlarmaActivada: response.dispositivo.tieneAlarmaActivada,
                version: response.dispositivo.version,
                geoJSON: this.parseStringToGeoJSON(response.dispositivo.posicion),
                variables: {
                    analog: {},
                    digital: {},
                    dynamic: {},
                }
            }
            if (response.dispositivo.VariablesAnalogicas != "") for (let i in response.dispositivo.VariablesAnalogicas) {
                deviceData.variables.analog[response.dispositivo.VariablesAnalogicas[i].etiqueta] = {
                    variableId: response.dispositivo.VariablesAnalogicas[i].idVariableAnalogica,
                    elementId: response.dispositivo.VariablesAnalogicas[i].idElemento,
                    label: response.dispositivo.VariablesAnalogicas[i].etiqueta,
                    getRol: response.dispositivo.VariablesAnalogicas[i].accesoLectura,
                    setRol: response.dispositivo.VariablesAnalogicas[i].accesoEscritura,
                    saveHistory: response.dispositivo.VariablesAnalogicas[i].guardarHistorico,
                    value: response.dispositivo.VariablesAnalogicas[i].valor,
                    valueDateTime: response.dispositivo.VariablesAnalogicas[i].fechaValor,
                    max: response.dispositivo.VariablesAnalogicas[i].limiteSuperior,
                    min: response.dispositivo.VariablesAnalogicas[i].limiteInferior,
                    distributionGroup: response.dispositivo.VariablesAnalogicas[i].grupoDistribucion,
                    adress: response.dispositivo.VariablesAnalogicas[i].direccion,
                    unit: response.dispositivo.VariablesAnalogicas[i].unidad,
                    externalAccess: response.dispositivo.VariablesAnalogicas[i].accesoExterno,
                    type: response.dispositivo.VariablesAnalogicas[i].tipo,
                    isStatus: response.dispositivo.VariablesAnalogicas[i].esEstado,
                    scaleId: response.dispositivo.VariablesAnalogicas[i].idEscala,
                    filterId: response.dispositivo.VariablesAnalogicas[i].idFiltro,
                    isConfiguration: response.dispositivo.VariablesAnalogicas[i].esConfiguracion,
                    events: response.dispositivo.VariablesAnalogicas[i].eventos,
                    targetVariable: response.dispositivo.VariablesAnalogicas[i].variablesDestino,
                    position: response.dispositivo.VariablesAnalogicas[i].posicion,
                    history: response.dispositivo.VariablesAnalogicas[i].historico
                }
            }
            if (response.dispositivo.VariablesDigitales != "") for (let i in response.dispositivo.VariablesDigitales) {
                deviceData.variables.digital[response.dispositivo.VariablesDigitales[i].etiqueta] = {
                    variableId: response.dispositivo.VariablesDigitales[i].idVariableDigital,
                    elementId: response.dispositivo.VariablesDigitales[i].idElemento,
                    label: response.dispositivo.VariablesDigitales[i].etiqueta,
                    getRol: response.dispositivo.VariablesDigitales[i].accesoLectura,
                    setRol: response.dispositivo.VariablesDigitales[i].accesoEscritura,
                    saveHistory: response.dispositivo.VariablesDigitales[i].guardarHistorico,
                    value: response.dispositivo.VariablesDigitales[i].valor,
                    valueDateTime: response.dispositivo.VariablesDigitales[i].fechaValor,
                    negativeLogic: response.dispositivo.VariablesDigitales[i].logicaNegativa,
                    distributionGroup: response.dispositivo.VariablesDigitales[i].grupoDistribucion,
                    adress: response.dispositivo.VariablesDigitales[i].direccion,
                    externalAccess: response.dispositivo.VariablesDigitales[i].accesoExterno,
                    type: response.dispositivo.VariablesDigitales[i].tipo,
                    isConfiguration: response.dispositivo.VariablesDigitales[i].esConfiguracion,
                    targetVariable: response.dispositivo.VariablesDigitales[i].variablesDestino,
                    position: response.dispositivo.VariablesDigitales[i].posicion
                }
            }
            if (response.dispositivo.VariablesDinamicas != "") for (let i in response.dispositivo.VariablesDinamicas) {
                deviceData.variables.dynamic[response.dispositivo.VariablesDinamicas[i].etiqueta] = {
                    variableId: response.dispositivo.VariablesDinamicas[i].idVariableDinamica,
                    elementId: response.dispositivo.VariablesDinamicas[i].idElemento,
                    label: response.dispositivo.VariablesDinamicas[i].etiqueta,
                    getRol: response.dispositivo.VariablesDinamicas[i].accesoLectura,
                    setRol: response.dispositivo.VariablesDinamicas[i].accesoEscritura,
                    saveHistory: response.dispositivo.VariablesDinamicas[i].guardarHistorico,
                    value: response.dispositivo.VariablesDinamicas[i].valor,
                    valueDateTime: response.dispositivo.VariablesDinamicas[i].fechaValor,
                    distributionGroup: response.dispositivo.VariablesDinamicas[i].grupoDistribucion,
                    adress: response.dispositivo.VariablesDinamicas[i].direccion,
                    externalAccess: response.dispositivo.VariablesDinamicas[i].accesoExterno,
                    type: response.dispositivo.VariablesDinamicas[i].tipo,
                    isConfiguration: response.dispositivo.VariablesDinamicas[i].esConfiguracion,
                    targetVariable: response.dispositivo.VariablesDinamicas[i].variablesDestino,
                    position: response.dispositivo.VariablesDinamicas[i].posicion
                }
            }
            return deviceData;
        }
        else {
            console.warn("WARNING: Failed to parse 'device'.");
            console.warn(response);
            return {};
        }
    },

    parseDevicesFromResponse(response, callback = undefined) {
        let deviceList = [];
        if (response != undefined && response["status-code"] === "200" && response.dispositivos != undefined) {
            for (let i in response.dispositivos) {
                deviceList[i] = {
                    id: response.dispositivos[i].idDcl,
                    nifCif: response.dispositivos[i].identificadorFiscal,
                    dataConsumed: response.dispositivos[i].datosConsumidos,
                    label: response.dispositivos[i].etiqueta,
                    dclId: response.dispositivos[i].identificadorDcl,
                    dclTypeId: response.dispositivos[i].idTipoDcl,
                    dclClass: response.dispositivos[i].claseDcl,
                    type: response.dispositivos[i].nombreTipo,
                    distributionGroup: response.dispositivos[i].grupoDistribucion,
                    parent: response.dispositivos[i].padre,
                    isBase: response.dispositivos[i].esBase,
                    isPublic: response.dispositivos[i].isPublic,
                    token: response.dispositivos[i].token,
                    imgSrc: response.dispositivos[i].urlImagen,
                    emplacement: response.dispositivos[i].emplazamiento,
                    communicationDateTime: response.dispositivos[i].fechaUltimaComunicacion,
                    alarmed: response.dispositivos[i].tieneAlarmaActivada,
                    version: response.dispositivos[i].version,
                    geoJSON: this.parseStringToGeoJSON(response.dispositivos[i].posicion)
                }
            }
            if (callback && typeof callback === "function") callback(deviceList);
            return deviceList;
        }
        else {
            console.warn("WARNING: Failed to parse 'devices'.");
            console.warn(response);
            return [];
        }
    },

    parseVariableHistoryFromResponse(response, callback = undefined) {
        let history = [];
        let i = 0;
        if (response != undefined && response["status-code"] === "200" && response.variables != undefined) {
            for (let variable in response.variables)
                for (let entry in response.variables[variable].historico) {
                    history[i] = [
                        // DATE
                        response.variables[variable].historico[entry][0],
                        // VALUE
                        response.variables[variable].historico[entry][1]];
                    i += 1;
                }
            if (callback && typeof callback === "function") callback(history);
            return history;
        }
        else {
            console.warn("WARNING: Failed to parse 'history'.");
            console.warn(response);
            return [];
        }
    },

    // PRODUCTS
    parseStockTypeFromResponse(response, callback = undefined) {
        if (response != undefined && response["status-code"] === "200" && response.tipoDcl != undefined) {
            let productData = {
                id: response.tipoDcl.idTipoDcl,
                nifCif: response.tipoDcl.identificadorFiscal,
                label: response.tipoDcl.etiqueta,
                dclClass: response.tipoDcl.claseDcl,
                category: response.tipoDcl.categoria,
                variables: {
                    analog: {},
                    digital: {},
                    dynamic: {},
                }
            }
            if (response.tipoDcl.VariablesAnalogicas != "") for (let i in response.tipoDcl.VariablesAnalogicas) {
                productData.variables.analog[response.tipoDcl.VariablesAnalogicas[i].etiqueta] = {
                    variableId: response.tipoDcl.VariablesAnalogicas[i].idVariableAnalogica,
                    elementId: response.tipoDcl.VariablesAnalogicas[i].idElemento,
                    label: response.tipoDcl.VariablesAnalogicas[i].etiqueta,
                    getRol: response.tipoDcl.VariablesAnalogicas[i].accesoLectura,
                    setRol: response.tipoDcl.VariablesAnalogicas[i].accesoEscritura,
                    saveHistory: response.tipoDcl.VariablesAnalogicas[i].guardarHistorico,
                    value: response.tipoDcl.VariablesAnalogicas[i].valor,
                    valueDateTime: response.tipoDcl.VariablesAnalogicas[i].fechaValor,
                    max: response.tipoDcl.VariablesAnalogicas[i].limiteSuperior,
                    min: response.tipoDcl.VariablesAnalogicas[i].limiteInferior,
                    distributionGroup: response.tipoDcl.VariablesAnalogicas[i].grupoDistribucion,
                    adress: response.tipoDcl.VariablesAnalogicas[i].direccion,
                    unit: response.tipoDcl.VariablesAnalogicas[i].unidad,
                    externalAccess: response.tipoDcl.VariablesAnalogicas[i].accesoExterno,
                    type: response.tipoDcl.VariablesAnalogicas[i].tipo,
                    isStatus: response.tipoDcl.VariablesAnalogicas[i].esEstado,
                    scaleId: response.tipoDcl.VariablesAnalogicas[i].idEscala,
                    filterId: response.tipoDcl.VariablesAnalogicas[i].idFiltro,
                    isConfiguration: response.tipoDcl.VariablesAnalogicas[i].esConfiguracion,
                    events: response.tipoDcl.VariablesAnalogicas[i].eventos,
                    targetVariable: response.tipoDcl.VariablesAnalogicas[i].variablesDestino,
                    position: response.tipoDcl.VariablesAnalogicas[i].posicion,
                    history: response.tipoDcl.VariablesAnalogicas[i].historico
                }
            }
            if (response.tipoDcl.VariablesDigitales != "") for (let i in response.tipoDcl.VariablesDigitales) {
                productData.variables.digital[response.tipoDcl.VariablesDigitales[i].etiqueta] = {
                    variableId: response.tipoDcl.VariablesDigitales[i].idVariableDigital,
                    elementId: response.tipoDcl.VariablesDigitales[i].idElemento,
                    label: response.tipoDcl.VariablesDigitales[i].etiqueta,
                    getRol: response.tipoDcl.VariablesDigitales[i].accesoLectura,
                    setRol: response.tipoDcl.VariablesDigitales[i].accesoEscritura,
                    saveHistory: response.tipoDcl.VariablesDigitales[i].guardarHistorico,
                    value: response.tipoDcl.VariablesDigitales[i].valor,
                    valueDateTime: response.tipoDcl.VariablesDigitales[i].fechaValor,
                    negativeLogic: response.tipoDcl.VariablesDigitales[i].logicaNegativa,
                    distributionGroup: response.tipoDcl.VariablesDigitales[i].grupoDistribucion,
                    adress: response.tipoDcl.VariablesDigitales[i].direccion,
                    externalAccess: response.tipoDcl.VariablesDigitales[i].accesoExterno,
                    type: response.tipoDcl.VariablesDigitales[i].tipo,
                    isConfiguration: response.tipoDcl.VariablesDigitales[i].esConfiguracion,
                    targetVariable: response.tipoDcl.VariablesDigitales[i].variablesDestino,
                    position: response.tipoDcl.VariablesDigitales[i].posicion
                }
            }
            if (response.tipoDcl.VariablesDinamicas != "") for (let i in response.tipoDcl.VariablesDinamicas) {
                productData.variables.dynamic[response.tipoDcl.VariablesDinamicas[i].etiqueta] = {
                    variableId: response.tipoDcl.VariablesDinamicas[i].idVariableDinamica,
                    elementId: response.tipoDcl.VariablesDinamicas[i].idElemento,
                    label: response.tipoDcl.VariablesDinamicas[i].etiqueta,
                    getRol: response.tipoDcl.VariablesDinamicas[i].accesoLectura,
                    setRol: response.tipoDcl.VariablesDinamicas[i].accesoEscritura,
                    saveHistory: response.tipoDcl.VariablesDinamicas[i].guardarHistorico,
                    value: response.tipoDcl.VariablesDinamicas[i].valor,
                    valueDateTime: response.tipoDcl.VariablesDinamicas[i].fechaValor,
                    distributionGroup: response.tipoDcl.VariablesDinamicas[i].grupoDistribucion,
                    adress: response.tipoDcl.VariablesDinamicas[i].direccion,
                    externalAccess: response.tipoDcl.VariablesDinamicas[i].accesoExterno,
                    type: response.tipoDcl.VariablesDinamicas[i].tipo,
                    isConfiguration: response.tipoDcl.VariablesDinamicas[i].esConfiguracion,
                    targetVariable: response.tipoDcl.VariablesDinamicas[i].variablesDestino,
                    position: response.tipoDcl.VariablesDinamicas[i].posicion
                }
            }
            if (callback && typeof callback === "function") callback(productData);
            return productData;
        }
        else {
            console.warn("WARNING: Failed to parse 'product'.");
            console.warn(response);
            return {};
        }
    },

    parseStockTypesFromResponse(response) {
        let productList = [];
        if (response != undefined && response["status-code"] === "200" && response.tiposDcl != undefined) {
            for (let t in response.tiposDcl) {
                productList[t] = {
                    id: response.tiposDcl[t].idTipoDcl,
                    nifCif: response.tiposDcl[t].identificadorFiscal,
                    label: response.tiposDcl[t].etiqueta,
                    dclClass: response.tiposDcl[t].claseDcl,
                    category: response.tiposDcl[t].categoria,
                    variables: {
                        analog: {},
                        digital: {},
                        dynamic: {},
                    }
                }
                if (response.tiposDcl[t].VariablesAnalogicas != "") for (let i in response.tiposDcl[t].VariablesAnalogicas) {
                    productList[t].variables.analog[response.tiposDcl[t].VariablesAnalogicas[i].etiqueta] = {
                        variableId: response.tiposDcl[t].VariablesAnalogicas[i].idVariableAnalogica,
                        elementId: response.tiposDcl[t].VariablesAnalogicas[i].idElemento,
                        label: response.tiposDcl[t].VariablesAnalogicas[i].etiqueta,
                        getRol: response.tiposDcl[t].VariablesAnalogicas[i].accesoLectura,
                        setRol: response.tiposDcl[t].VariablesAnalogicas[i].accesoEscritura,
                        saveHistory: response.tiposDcl[t].VariablesAnalogicas[i].guardarHistorico,
                        value: response.tiposDcl[t].VariablesAnalogicas[i].valor,
                        valueDateTime: response.tiposDcl[t].VariablesAnalogicas[i].fechaValor,
                        max: response.tiposDcl[t].VariablesAnalogicas[i].limiteSuperior,
                        min: response.tiposDcl[t].VariablesAnalogicas[i].limiteInferior,
                        distributionGroup: response.tiposDcl[t].VariablesAnalogicas[i].grupoDistribucion,
                        adress: response.tiposDcl[t].VariablesAnalogicas[i].direccion,
                        unit: response.tiposDcl[t].VariablesAnalogicas[i].unidad,
                        externalAccess: response.tiposDcl[t].VariablesAnalogicas[i].accesoExterno,
                        type: response.tiposDcl[t].VariablesAnalogicas[i].tipo,
                        isStatus: response.tiposDcl[t].VariablesAnalogicas[i].esEstado,
                        scaleId: response.tiposDcl[t].VariablesAnalogicas[i].idEscala,
                        filterId: response.tiposDcl[t].VariablesAnalogicas[i].idFiltro,
                        isConfiguration: response.tiposDcl[t].VariablesAnalogicas[i].esConfiguracion,
                        events: response.tiposDcl[t].VariablesAnalogicas[i].eventos,
                        targetVariable: response.tiposDcl[t].VariablesAnalogicas[i].variablesDestino,
                        position: response.tiposDcl[t].VariablesAnalogicas[i].posicion,
                        history: response.tiposDcl[t].VariablesAnalogicas[i].historico
                    }
                }
                if (response.tiposDcl[t].VariablesDigitales != "") for (let i in response.tiposDcl[t].VariablesDigitales) {
                    productList[t].variables.digital[response.tiposDcl[t].VariablesDigitales[i].etiqueta] = {
                        variableId: response.tiposDcl[t].VariablesDigitales[i].idVariableDigital,
                        elementId: response.tiposDcl[t].VariablesDigitales[i].idElemento,
                        label: response.tiposDcl[t].VariablesDigitales[i].etiqueta,
                        getRol: response.tiposDcl[t].VariablesDigitales[i].accesoLectura,
                        setRol: response.tiposDcl[t].VariablesDigitales[i].accesoEscritura,
                        saveHistory: response.tiposDcl[t].VariablesDigitales[i].guardarHistorico,
                        value: response.tiposDcl[t].VariablesDigitales[i].valor,
                        valueDateTime: response.tiposDcl[t].VariablesDigitales[i].fechaValor,
                        negativeLogic: response.tiposDcl[t].VariablesDigitales[i].logicaNegativa,
                        distributionGroup: response.tiposDcl[t].VariablesDigitales[i].grupoDistribucion,
                        adress: response.tiposDcl[t].VariablesDigitales[i].direccion,
                        externalAccess: response.tiposDcl[t].VariablesDigitales[i].accesoExterno,
                        type: response.tiposDcl[t].VariablesDigitales[i].tipo,
                        isConfiguration: response.tiposDcl[t].VariablesDigitales[i].esConfiguracion,
                        targetVariable: response.tiposDcl[t].VariablesDigitales[i].variablesDestino,
                        position: response.tiposDcl[t].VariablesDigitales[i].posicion
                    }
                }
                if (response.tiposDcl[t].VariablesDinamicas != "") for (let i in response.tiposDcl[t].VariablesDinamicas) {
                    productList[t].variables.dynamic[response.tiposDcl[t].VariablesDinamicas[i].etiqueta] = {
                        variableId: response.tiposDcl[t].VariablesDinamicas[i].idVariableDinamica,
                        elementId: response.tiposDcl[t].VariablesDinamicas[i].idElemento,
                        label: response.tiposDcl[t].VariablesDinamicas[i].etiqueta,
                        getRol: response.tiposDcl[t].VariablesDinamicas[i].accesoLectura,
                        setRol: response.tiposDcl[t].VariablesDinamicas[i].accesoEscritura,
                        saveHistory: response.tiposDcl[t].VariablesDinamicas[i].guardarHistorico,
                        value: response.tiposDcl[t].VariablesDinamicas[i].valor,
                        valueDateTime: response.tiposDcl[t].VariablesDinamicas[i].fechaValor,
                        distributionGroup: response.tiposDcl[t].VariablesDinamicas[i].grupoDistribucion,
                        adress: response.tiposDcl[t].VariablesDinamicas[i].direccion,
                        externalAccess: response.tiposDcl[t].VariablesDinamicas[i].accesoExterno,
                        type: response.tiposDcl[t].VariablesDinamicas[i].tipo,
                        isConfiguration: response.tiposDcl[t].VariablesDinamicas[i].esConfiguracion,
                        targetVariable: response.tiposDcl[t].VariablesDinamicas[i].variablesDestino,
                        position: response.tiposDcl[t].VariablesDinamicas[i].posicion
                    }
                }
            }
            return productList;
        }
        else {
            console.warn("WARNING: Failed to parse 'products'.");
            console.warn(response);
            return {};
        }
    },

    // STOCKS
    parseStockFromResponse(response, callback = undefined) {
        if (response != undefined && response["status-code"] === "200" && response.stock != undefined) {
            let stockData = {
                id: response.stock.idDcl,
                nifCif: response.stock.identificadorFiscal,
                label: response.stock.etiqueta,
                dclClass: response.stock.claseDcl,
                dclTypeId: response.stock.idTipoDcl,
                emplacement: response.stock.emplazamiento,
                parent: response.stock.padre,
                variables: {
                    analog: {},
                    digital: {},
                    dynamic: {},
                }
            }
            if (response.stock.VariablesAnalogicas != "") for (let i in response.stock.VariablesAnalogicas) {
                stockData.variables.analog[response.stock.VariablesAnalogicas[i].etiqueta] = {
                    variableId: response.stock.VariablesAnalogicas[i].idVariableAnalogica,
                    elementId: response.stock.VariablesAnalogicas[i].idElemento,
                    label: response.stock.VariablesAnalogicas[i].etiqueta,
                    getRol: response.stock.VariablesAnalogicas[i].accesoLectura,
                    setRol: response.stock.VariablesAnalogicas[i].accesoEscritura,
                    saveHistory: response.stock.VariablesAnalogicas[i].guardarHistorico,
                    value: response.stock.VariablesAnalogicas[i].valor,
                    valueDateTime: response.stock.VariablesAnalogicas[i].fechaValor,
                    max: response.stock.VariablesAnalogicas[i].limiteSuperior,
                    min: response.stock.VariablesAnalogicas[i].limiteInferior,
                    distributionGroup: response.stock.VariablesAnalogicas[i].grupoDistribucion,
                    adress: response.stock.VariablesAnalogicas[i].direccion,
                    unit: response.stock.VariablesAnalogicas[i].unidad,
                    externalAccess: response.stock.VariablesAnalogicas[i].accesoExterno,
                    type: response.stock.VariablesAnalogicas[i].tipo,
                    isStatus: response.stock.VariablesAnalogicas[i].esEstado,
                    scaleId: response.stock.VariablesAnalogicas[i].idEscala,
                    filterId: response.stock.VariablesAnalogicas[i].idFiltro,
                    isConfiguration: response.stock.VariablesAnalogicas[i].esConfiguracion,
                    events: response.stock.VariablesAnalogicas[i].eventos,
                    targetVariable: response.stock.VariablesAnalogicas[i].variablesDestino,
                    position: response.stock.VariablesAnalogicas[i].posicion,
                    history: response.stock.VariablesAnalogicas[i].historico
                }
            }
            if (response.stock.VariablesDigitales != "") for (let i in response.stock.VariablesDigitales) {
                stockData.variables.digital[response.stock.VariablesDigitales[i].etiqueta] = {
                    variableId: response.stock.VariablesDigitales[i].idVariableDigital,
                    elementId: response.stock.VariablesDigitales[i].idElemento,
                    label: response.stock.VariablesDigitales[i].etiqueta,
                    getRol: response.stock.VariablesDigitales[i].accesoLectura,
                    setRol: response.stock.VariablesDigitales[i].accesoEscritura,
                    saveHistory: response.stock.VariablesDigitales[i].guardarHistorico,
                    value: response.stock.VariablesDigitales[i].valor,
                    valueDateTime: response.stock.VariablesDigitales[i].fechaValor,
                    negativeLogic: response.stock.VariablesDigitales[i].logicaNegativa,
                    distributionGroup: response.stock.VariablesDigitales[i].grupoDistribucion,
                    adress: response.stock.VariablesDigitales[i].direccion,
                    externalAccess: response.stock.VariablesDigitales[i].accesoExterno,
                    type: response.stock.VariablesDigitales[i].tipo,
                    isConfiguration: response.stock.VariablesDigitales[i].esConfiguracion,
                    targetVariable: response.stock.VariablesDigitales[i].variablesDestino,
                    position: response.stock.VariablesDigitales[i].posicion
                }
            }
            if (response.stock.VariablesDinamicas != "") for (let i in response.stock.VariablesDinamicas) {
                stockData.variables.dynamic[response.stock.VariablesDinamicas[i].etiqueta] = {
                    variableId: response.stock.VariablesDinamicas[i].idVariableDinamica,
                    elementId: response.stock.VariablesDinamicas[i].idElemento,
                    label: response.stock.VariablesDinamicas[i].etiqueta,
                    getRol: response.stock.VariablesDinamicas[i].accesoLectura,
                    setRol: response.stock.VariablesDinamicas[i].accesoEscritura,
                    saveHistory: response.stock.VariablesDinamicas[i].guardarHistorico,
                    value: response.stock.VariablesDinamicas[i].valor,
                    valueDateTime: response.stock.VariablesDinamicas[i].fechaValor,
                    distributionGroup: response.stock.VariablesDinamicas[i].grupoDistribucion,
                    adress: response.stock.VariablesDinamicas[i].direccion,
                    externalAccess: response.stock.VariablesDinamicas[i].accesoExterno,
                    type: response.stock.VariablesDinamicas[i].tipo,
                    isConfiguration: response.stock.VariablesDinamicas[i].esConfiguracion,
                    targetVariable: response.stock.VariablesDinamicas[i].variablesDestino,
                    position: response.stock.VariablesDinamicas[i].posicion
                }
            }
            if (callback && typeof callback === "function") callback(stockData);
            return stockData;
        }
        else {
            console.warn("WARNING: Failed to parse 'product'.");
            console.warn(response);
            return {};
        }
    },

    parseStocksFromResponse(response) {
        let stockList = [];
        if (response != undefined && response["status-code"] === "200" && response.stocks != undefined) {
            for (let i in response.stocks) {
                stockList[i] = {
                    id: response.stocks[i].idDcl,
                    nifCif: response.stocks[i].identificadorFiscal,
                    label: response.stocks[i].etiqueta,
                    dclClass: response.stocks[i].claseDcl,
                    dclTypeId: response.stocks[i].idTipoDcl,
                    emplacement: response.stocks[i].emplazamiento,
                    parent: response.stocks[i].padre,
                    variables: {
                        analog: {},
                        digital: {},
                        dynamic: {},
                    }
                }
                if (response.stocks[i].VariablesAnalogicas != "") for (let ii in response.stocks[i].VariablesAnalogicas) {
                    stockList[i].variables.analog[response.stocks[i].VariablesAnalogicas[ii].etiqueta] = {
                        variableId: response.stocks[i].VariablesAnalogicas[ii].idVariableAnalogica,
                        elementId: response.stocks[i].VariablesAnalogicas[ii].idElemento,
                        label: response.stocks[i].VariablesAnalogicas[ii].etiqueta,
                        getRol: response.stocks[i].VariablesAnalogicas[ii].accesoLectura,
                        setRol: response.stocks[i].VariablesAnalogicas[ii].accesoEscritura,
                        saveHistory: response.stocks[i].VariablesAnalogicas[ii].guardarHistorico,
                        value: response.stocks[i].VariablesAnalogicas[ii].valor,
                        valueDateTime: response.stocks[i].VariablesAnalogicas[ii].fechaValor,
                        max: response.stocks[i].VariablesAnalogicas[ii].limiteSuperior,
                        min: response.stocks[i].VariablesAnalogicas[ii].limiteInferior,
                        distributionGroup: response.stocks[i].VariablesAnalogicas[ii].grupoDistribucion,
                        adress: response.stocks[i].VariablesAnalogicas[ii].direccion,
                        unit: response.stocks[i].VariablesAnalogicas[ii].unidad,
                        externalAccess: response.stocks[i].VariablesAnalogicas[ii].accesoExterno,
                        type: response.stocks[i].VariablesAnalogicas[ii].tipo,
                        isStatus: response.stocks[i].VariablesAnalogicas[ii].esEstado,
                        scaleId: response.stocks[i].VariablesAnalogicas[ii].idEscala,
                        filterId: response.stocks[i].VariablesAnalogicas[ii].idFiltro,
                        isConfiguration: response.stocks[i].VariablesAnalogicas[ii].esConfiguracion,
                        events: response.stocks[i].VariablesAnalogicas[ii].eventos,
                        targetVariable: response.stocks[i].VariablesAnalogicas[ii].variablesDestino,
                        position: response.stocks[i].VariablesAnalogicas[ii].posicion,
                        history: response.stocks[i].VariablesAnalogicas[ii].historico
                    }
                }
                if (response.stocks[i].VariablesDigitales != "") for (let ii in response.stocks[i].VariablesDigitales) {
                    stockList[i].variables.digital[response.stocks[i].VariablesDigitales[ii].etiqueta] = {
                        variableId: response.stocks[i].VariablesDigitales[ii].idVariableDigital,
                        elementId: response.stocks[i].VariablesDigitales[ii].idElemento,
                        label: response.stocks[i].VariablesDigitales[ii].etiqueta,
                        getRol: response.stocks[i].VariablesDigitales[ii].accesoLectura,
                        setRol: response.stocks[i].VariablesDigitales[ii].accesoEscritura,
                        saveHistory: response.stocks[i].VariablesDigitales[ii].guardarHistorico,
                        value: response.stocks[i].VariablesDigitales[ii].valor,
                        valueDateTime: response.stocks[i].VariablesDigitales[ii].fechaValor,
                        negativeLogic: response.stocks[i].VariablesDigitales[ii].logicaNegativa,
                        distributionGroup: response.stocks[i].VariablesDigitales[ii].grupoDistribucion,
                        adress: response.stocks[i].VariablesDigitales[ii].direccion,
                        externalAccess: response.stocks[i].VariablesDigitales[ii].accesoExterno,
                        type: response.stocks[i].VariablesDigitales[ii].tipo,
                        isConfiguration: response.stocks[i].VariablesDigitales[ii].esConfiguracion,
                        targetVariable: response.stocks[i].VariablesDigitales[ii].variablesDestino,
                        position: response.stocks[i].VariablesDigitales[ii].posicion
                    }
                }
                if (response.stocks[i].VariablesDinamicas != "") for (let ii in response.stocks[i].VariablesDinamicas) {
                    stockList[i].variables.dynamic[response.stocks[i].VariablesDinamicas[ii].etiqueta] = {
                        variableId: response.stocks[i].VariablesDinamicas[ii].idVariableDinamica,
                        elementId: response.stocks[i].VariablesDinamicas[ii].idElemento,
                        label: response.stocks[i].VariablesDinamicas[ii].etiqueta,
                        getRol: response.stocks[i].VariablesDinamicas[ii].accesoLectura,
                        setRol: response.stocks[i].VariablesDinamicas[ii].accesoEscritura,
                        saveHistory: response.stocks[i].VariablesDinamicas[ii].guardarHistorico,
                        value: response.stocks[i].VariablesDinamicas[ii].valor,
                        valueDateTime: response.stocks[i].VariablesDinamicas[ii].fechaValor,
                        distributionGroup: response.stocks[i].VariablesDinamicas[ii].grupoDistribucion,
                        adress: response.stocks[i].VariablesDinamicas[ii].direccion,
                        externalAccess: response.stocks[i].VariablesDinamicas[ii].accesoExterno,
                        type: response.stocks[i].VariablesDinamicas[ii].tipo,
                        isConfiguration: response.stocks[i].VariablesDinamicas[ii].esConfiguracion,
                        targetVariable: response.stocks[i].VariablesDinamicas[ii].variablesDestino,
                        position: response.stocks[i].VariablesDinamicas[ii].posicion
                    }
                }
            }
            return stockList;
        }
        else {
            console.warn("WARNING: Failed to parse 'stocks'.");
            console.warn(response);
            return {};
        }
    },

    // ORDERS
    parseOrderFromResponse(response, callback = undefined) {
        if (response != undefined && response["status-code"] === "200" && response.orden != undefined) {
            let order = {
                id: response.orden.idOrden,
                type: response.orden.tipoOrden,
                status: response.orden.estado,
                creationDateTime: response.orden.fechaCreacion,
                plannedDateTime: response.orden.fechaPlanificada,
                executionDateTime: response.orden.fechaEjecucion,
                executionEndDateTime: response.orden.fechaFinEjecucion,
                cancelDateTime: response.orden.fechaCancelada,
                endDateTime: response.orden.fechaFinalizacion,
                entityTargets: response.orden.listaTiposDcl,
                dclTargets: response.orden.listaDcls,
                summary: response.orden.resumen,
                params: (response.orden.parametros != "") ? JSON.parse(response.orden.parametros) : "",
            }
            if (callback && typeof callback === "function") callback(order);
            return order;
        }
        else {
            console.warn("WARNING: Failed to parse 'order'.");
            console.warn(response);
            return {};
        }
    },

    parseOrdersFromResponse(response, callback = undefined) {
        let orders = [];
        let i = 0;
        if (response != undefined && response["status-code"] === "200" && response.ordenes != undefined) {
            for (let o in response.ordenes) {
                orders[i] = {
                    id: response.ordenes[o].idOrden,
                    type: response.ordenes[o].tipoOrden,
                    status: response.ordenes[o].estado,
                    creationDateTime: response.ordenes[o].fechaCreacion,
                    plannedDateTime: response.ordenes[o].fechaPlanificada,
                    executionDateTime: response.ordenes[o].fechaEjecucion,
                    executionEndDateTime: response.ordenes[o].fechaFinEjecucion,
                    endDateTime: response.ordenes[o].fechaFinalizacion,
                    cancelDateTime: response.ordenes[o].fechaCancelada,
                    entityTargets: response.ordenes[o].listaTiposDcl,
                    dclTargets: response.ordenes[o].listaDcls,
                    summary: response.ordenes[o].resumen,
                    params: (response.ordenes[o].parametros != "") ? JSON.parse(response.ordenes[o].parametros) : "",
                }
                i += 1;
            }
            if (callback && typeof callback === "function") callback(orders);
            return orders;
        }
        else {
            console.warn("WARNING: Failed to parse 'orders'.");
            console.warn(response);
            return [];
        }
    },

    parsePublicOrderFromResponse(response, callback = undefined) {
        if (response != undefined && response["status-code"] === "200" && response.reserva != undefined) {
            let order = {
                id: response.reserva.id,
                idLocker: response.reserva.idLocker,
                idOrder: response.reserva.idPedido,
                idProvider: response.reserva.idProveedor,
                status: response.reserva.estado,
                creationDateTime: response.reserva.fechaCreacion,
                plannedDateTime: response.reserva.fechaPlanificada,
                executionDateTime: response.reserva.fechaEjecucion,
                executionEndDateTime: response.reserva.fechaFinEjecucion,
                cancelDateTime: response.reserva.fechaCancelada,
                endDateTime: response.reserva.fechaFinalizacion,
                observations: response.reserva.observaciones,
                numBundle: response.reserva.numBultos,
                scheduledDate: response.reserva.fechaProgramada,
                maxHoldingDays: response.reserva.maxDiasEspera,
                providerName: response.proveedor.nombre,
                providerEmail: response.proveedor.email,
                providerPhone: response.proveedor.telefono,
                lockerLabel: response.locker.etiqueta,
                lockerEmplacement: response.locker.emplazamiento,
                lockerPosition: response.locker.posicion            
            }
            if (callback && typeof callback === "function") callback(order);
            return order;
        }
        else {
            console.warn("WARNING: Failed to parse 'order'.");
            console.warn(response);
            return {};
        }
    },


    // WARNINGS
    parseWarningFromResponse(response, callback = undefined) {
        if (response != undefined && response["status-code"] === "200" && response.aviso != undefined) {
            let warning = {
                id: response.aviso.idAviso,
                label: response.aviso.etiqueta,
                description: response.aviso.descripcion,
                status: response.aviso.estadoAviso,
                condition: response.aviso.condicion,
                isActive: response.aviso.estaActivado,
                isAlarm: response.aviso.esAlarma,
                isNotification: response.aviso.esNotificacion,
                readDateTime: response.aviso.fechaLeido,
                notificationDateTime: response.aviso.fechaNotificacion,
                activationDateTime: response.aviso.fechaActivacion,
                preactiveDateTime: response.aviso.fechaPreactivo,
                silencedDateTime: response.aviso.fechaSilenciado
            }
            if (callback && typeof callback === "function") callback(warning);
            return warning;
        }
        else {
            console.warn("WARNING: Failed to parse 'warning'.");
            console.warn(response);
            return {};
        }
    },

    parseWarningsFromResponse(response, callback = undefined) {
        let warnings = [];
        let i = 0;
        if (response != undefined && response["status-code"] === "200" && response.avisos != undefined) {
            for (let a in response.avisos) {
                warnings[i] = {
                    id: response.avisos[a].idAviso,
                    label: response.avisos[a].etiqueta,
                    description: response.avisos[a].descripcion,
                    status: response.avisos[a].estadoAviso,
                    condition: response.avisos[a].condicion,
                    isActive: response.avisos[a].estaActivado,
                    isAlarm: response.avisos[a].esAlarma,
                    isNotification: response.avisos[a].esNotificacion,
                    readDateTime: response.avisos[a].fechaLeido,
                    notificationDateTime: response.avisos[a].fechaNotificacion,
                    activationDateTime: response.avisos[a].fechaActivacion,
                    preactiveDateTime: response.avisos[a].fechaPreactivo,
                    silencedDateTime: response.avisos[a].fechaSilenciado
                }
                i += 1;
            }
            if (callback && typeof callback === "function") callback(warnings);
            return warnings;
        }
        else {
            console.warn("WARNING: Failed to parse 'warnings'.");
            console.warn(response);
            return [];
        }
    },
  
}