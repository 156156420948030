<template>
  <div class="home-container  fontAdn ">

    <div v-if="this.session.user.nifCif != ''">
      <!-- MENU Side -->
      <SideMenuComponent :session="session" :dictionary="dictionary" :visibility="visibility"
        :availableModules="session.authorisation.availableModules" v-on:toggleSideMenu="toggleSideMenu"
        v-on:logOutRequest="logOutRequest">
      </SideMenuComponent>

      <!-- MENU Top -->
      <TopMenuComponent :session="session" :dictionary="dictionary" :visibility="visibility"
        :availableCompanies="availableCompanies" :currentCompany="currentCompany"
        :availableModules="session.authorisation.availableModules" v-on:toggleSideMenu="toggleSideMenu">
      </TopMenuComponent>

      <!-- SECTION Profile -->
      <div v-if="this.$route.params.currentDisplay === 'profile'" class="module-contaier">
        <ProfileComponent :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones"
          :currentCompany="currentCompany" :visibility="visibility" v-on:getCurrentUserData="getCurrentUserData"
          v-on:getCurrentCompanyData="getCurrentCompanyData">
        </ProfileComponent>
      </div>

      <!-- SECTION Dashboard -->
      <div v-if="this.$route.params.currentDisplay === 'dashboard'" class="module-contaier">
        <DashboardComponent :session="session" :dictionary="dictionary" :visibility="visibility"
          :currentCompany="currentCompany" :dataTableOptions="dataTableOptions">
        </DashboardComponent>
      </div>


      <!-- SECTION Companies -->
      <div v-if="this.$route.params.currentDisplay === 'companies'" class="module-contaier">
        <CompaniesComponent :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones"
          :visibility="visibility" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
          :dataTableOptions="dataTableOptions" v-on:setCurrentCompany="setCurrentCompany"
          v-on:getAvailableCompanies="getAvailableCompanies">
        </CompaniesComponent>
      </div>

      <!-- SECTION Emplacements -->
      <div v-if="this.$route.params.currentDisplay === 'emplacements'" class="module-contaier">
        <EmplacementsComponent :session="session" :dictionary="dictionary" :visibility="visibility"
          :currentCompany="currentCompany" :availableCompanies="availableCompanies" :dataTableOptions="dataTableOptions"
          v-on:setCurrentCompany="setCurrentCompany" v-on:getAvailableCompanies="getAvailableCompanies">
        </EmplacementsComponent>
      </div>

      <!-- SECTION Machines -->
      <div v-if="this.$route.params.currentDisplay === 'machines'" class="module-contaier">
        <MachinesComponent :session="session" :dictionary="dictionary" :countries="countries" :visibility="visibility"
          :currentCompany="currentCompany" :availableCompanies="availableCompanies" :targetEmplacementId="''"
          :dataTableOptions="dataTableOptions" v-on:setCurrentCompany="setCurrentCompany"
          v-on:getAvailableCompanies="getAvailableCompanies">
        </MachinesComponent>
      </div>

      <!-- SECTION Lockers -->
      <div v-if="this.$route.params.currentDisplay === 'lockers'" class="module-contaier">
        <LockersComponent :session="session" :dictionary="dictionary" :countries="countries" :visibility="visibility"
          :currentCompany="currentCompany" :availableCompanies="availableCompanies" :targetEmplacementId="''"
          :dataTableOptions="dataTableOptions" v-on:setCurrentCompany="setCurrentCompany"
          v-on:getAvailableCompanies="getAvailableCompanies">
        </LockersComponent>
      </div>
      <!-- SECTION Devices -->
      <div v-if="this.$route.params.currentDisplay === 'bundles'" class="module-contaier">
        <BundlesComponent :session="session" :dictionary="dictionary" :countries="countries" :visibility="visibility"
          :currentCompany="currentCompany" :availableCompanies="availableCompanies" :targetMachineId="''"
          :dataTableOptions="dataTableOptions" v-on:setCurrentCompany="setCurrentCompany"
          v-on:getAvailableCompanies="getAvailableCompanies">
        </BundlesComponent>
      </div>
      <!-- SECTION Slots -->
      <div v-if="this.$route.params.currentDisplay === 'slots'" class="module-contaier">
        <SlotsComponent :session="session" :dictionary="dictionary" :countries="countries" :visibility="visibility"
          :currentCompany="currentCompany" :availableCompanies="availableCompanies" :targetMachineId="''"
          :dataTableOptions="dataTableOptions" v-on:setCurrentCompany="setCurrentCompany"
          v-on:getAvailableCompanies="getAvailableCompanies">
        </SlotsComponent>
      </div>

      <!-- SECTION Devices -->
      <div v-if="this.$route.params.currentDisplay === 'devices'" class="module-contaier">
        <DevicesComponent :session="session" :dictionary="dictionary" :countries="countries" :visibility="visibility"
          :currentCompany="currentCompany" :availableCompanies="availableCompanies" :targetEmplacementId="''"
          :dataTableOptions="dataTableOptions" v-on:setCurrentCompany="setCurrentCompany"
          v-on:getAvailableCompanies="getAvailableCompanies">
        </DevicesComponent>
      </div>

      <!-- SECTION Status -->
      <div v-if="this.$route.params.currentDisplay === 'status'" class="module-contaier">
        <StatusComponent :session="session" :dictionary="dictionary" :countries="countries" :visibility="visibility"
          :currentCompany="currentCompany" :availableCompanies="availableCompanies" :requestedDeviceId="''"
          v-on:setCurrentCompany="setCurrentCompany">
        </StatusComponent>
      </div>

      <!-- SECTION Products -->
      <div v-if="this.$route.params.currentDisplay === 'products'" class="module-contaier">
        <ProductsComponent :session="session" :dictionary="dictionary" :countries="countries" :visibility="visibility"
          :currentCompany="currentCompany" :availableCompanies="availableCompanies" :targetEmplacementId="''"
          :dataTableOptions="dataTableOptions" v-on:setCurrentCompany="setCurrentCompany"
          v-on:getAvailableCompanies="getAvailableCompanies">
        </ProductsComponent>
      </div>

      <!-- SECTION Stock -->
      <div v-if="this.$route.params.currentDisplay === 'stocks'" class="module-contaier">
        <StockComponent :session="session" :dictionary="dictionary" :countries="countries" :visibility="visibility"
          :currentCompany="currentCompany" :availableCompanies="availableCompanies" :dataTableOptions="dataTableOptions"
          v-on:setCurrentCompany="setCurrentCompany" v-on:getAvailableCompanies="getAvailableCompanies">
        </StockComponent>
      </div>

      <!-- SECTION Receptions -->
      <div v-if="this.$route.params.currentDisplay === 'receptions'" class="module-contaier">
        <ReceptionsComponent :session="session" :dictionary="dictionary" :countries="countries" :visibility="visibility"
          :currentCompany="currentCompany" :availableCompanies="availableCompanies" :dataTableOptions="dataTableOptions"
          v-on:setCurrentCompany="setCurrentCompany" v-on:getAvailableCompanies="getAvailableCompanies">
        </ReceptionsComponent>
      </div>

      <!-- SECTION Expeditions -->
      <div v-if="this.$route.params.currentDisplay === 'expeditions'" class="module-contaier">
        <ExpeditionsComponent :session="session" :dictionary="dictionary" :countries="countries"
          :visibility="visibility" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
          :dataTableOptions="dataTableOptions" v-on:setCurrentCompany="setCurrentCompany"
          v-on:getAvailableCompanies="getAvailableCompanies">
        </ExpeditionsComponent>
      </div>

      <!-- SECTION Orders -->
      <div v-if="this.$route.params.currentDisplay === 'orders'" class="module-contaier">
        <OrdersComponent :session="session" :dictionary="dictionary" :countries="countries" :visibility="visibility"
          :currentCompany="currentCompany" :availableCompanies="availableCompanies"
          :dataTableOptions="dataTableOptions">
        </OrdersComponent>
      </div>

      <!-- SECTION Locking -->
      <div v-if="this.$route.params.currentDisplay === 'lockings'" class="module-contaier">
        <LockingsComponent :session="session" :dictionary="dictionary" :countries="countries" :visibility="visibility"
          :currentCompany="currentCompany" :availableCompanies="availableCompanies" :dataTableOptions="dataTableOptions"
          v-on:setCurrentCompany="setCurrentCompany" v-on:getAvailableCompanies="getAvailableCompanies">
        </LockingsComponent>
      </div>

      <!-- SECTION Charts -->
      <div v-if="this.$route.params.currentDisplay === 'charts'" class="module-contaier">
        <ChartsComponent :session="session" :dictionary="dictionary" :countries="countries" :visibility="visibility"
          :currentCompany="currentCompany" :availableCompanies="availableCompanies" :timeZones="timeZones"
          v-on:setCurrentCompany="setCurrentCompany">
        </ChartsComponent>
      </div>

      <!-- SECTION Warnings -->
      <div v-if="this.$route.params.currentDisplay === 'warnings'" class="module-contaier">
        <WarningsComponent :session="session" :dictionary="dictionary" :countries="countries" :visibility="visibility"
          :currentCompany="currentCompany" :availableCompanies="availableCompanies" :dataTableOptions="dataTableOptions"
          v-on:setCurrentCompany="setCurrentCompany" v-on:getAvailableCompanies="getAvailableCompanies">
        </WarningsComponent>
      </div>

      <!-- SECTION Users -->
      <div v-if="this.$route.params.currentDisplay === 'users'" class="module-contaier">
        <UsersComponent :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones"
          :visibility="visibility" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
          :dataTableOptions="dataTableOptions" v-on:setCurrentCompany="setCurrentCompany"
          v-on:getAvailableCompanies="getAvailableCompanies">
        </UsersComponent>
      </div>

      <!--
      <div class="p-3">
        <small>Copyright &copy; 2022. D&A Innovative Systems.</small>
      </div>
      -->
      <div class="row mt-5 pt-4 border-top border-black justify-content-around">
        <div
          class="col-lg-3 col-12">
          <div class="mb-2" >
            <img 
              src="https://landa-merkataritza.araba.eus/wp-content/uploads/elementor/thumbs/logo-next-generation-qkddho5k5s75luq79e139c9pk83j6wxi3oxo58bfae.png"
              title="Logo Next Generation EU" alt="Logo Next Generation EU" loading="lazy">
          </div>
        </div>
        <div
          class="col-lg-3 col-12">
          <div class="mb-2">
            <img
              src="https://landa-merkataritza.araba.eus/wp-content/uploads/elementor/thumbs/logo-mece-footer-qn4gxitwqkhp5nxysgu3t83ua2eqr8cqmvpkg29qte.png"
              title="logo-mece-footer" alt="logo-mece-footer" loading="lazy">
          </div>
        </div>
        <div
          class="col-lg-3 col-12">
          <div class="mb-2">
            <img
              src="https://landa-merkataritza.araba.eus/wp-content/uploads/elementor/thumbs/logo-PRTR-qkddfh73q5oq21l41b0y0azneglq9zq8hihy6lzlhm.png"
              title="Logo Plan de Recuperación, Transformación y Resiliencia"
              alt="Logo Plan de Recuperación, Transformación y Resiliencia" loading="lazy">
          </div>
        </div>
        <div
          class="col-lg-3 col-12">
          <div class="mb-2" >
            <img
              src="https://landa-merkataritza.araba.eus/wp-content/uploads/elementor/thumbs/logo-DFA-qkddbz8qrwenauzuhxgf5rz8285ikwc0niptiwr908.png"
              title="logo Diputación Foral de Alava" alt="logo Diputación Foral de Alava" loading="lazy">
          </div>
        </div>
      </div>
      <p class="tx-14 pt-3">©2024 Todos los derechos reservados. <a style="text-decoration: none !important; color:black !important; font-weight: 500 !important" href="https://web.araba.eus/es/aviso-legal&quot;" target="_blank">Aviso Legal</a> - <a style="text-decoration: none !important; color:black !important; font-weight: 500 !important" href="https://web.araba.eus/es/politica-de-privacidad" target="_blank">Política de Privacidad</a> - <a style="text-decoration: none !important; color:black !important; font-weight: 500 !important" href="https://web.araba.eus/es/accesibilidad" target="_blank">Accesibilidad</a> - <a style="text-decoration: none !important; color:black !important; font-weight: 500 !important" href="https://centinela.lefebvre.es/public/concept/1834447?access=48fp5sSMiQN8FTC62cVteVTU46KcYkxONFKwGn0LYAU%3D" target="_blank">Canal de denuncias</a></p>

    </div>

  </div>
</template>

<script>
import SideMenuComponent from '@/components/navigation/SideMenuComponent';
import TopMenuComponent from '@/components/navigation/TopMenuComponent';
import ProfileComponent from '@/components/profile/ProfileComponent';
import DashboardComponent from '@/components/dashboard/DashboardComponent';
import EmplacementsComponent from '@/components/emplacements/EmplacementsComponent';
import MachinesComponent from '@/components/machines/MachinesComponent';
import BundlesComponent from '@/components/bundles/BundlesComponent';
import DevicesComponent from '@/components/devices/DevicesComponent';
import StatusComponent from '@/components/status/StatusComponent';
import CompaniesComponent from '@/components/companies/CompaniesComponent';
import ProductsComponent from '@/components/products/ProductsComponent';
import StockComponent from '@/components/stock/StockComponent';
import ReceptionsComponent from '@/components/receptions/ReceptionsComponent';
import ExpeditionsComponent from '@/components/expeditions/ExpeditionsComponent';
import OrdersComponent from '@/components/orders/OrdersComponent';
import LockingsComponent from '@/components/lockers/LockingsComponent';
import LockersComponent from '@/components/lockers/LockersComponent';
import SlotsComponent from '@/components/lockers/SlotsComponent';
import ChartsComponent from '@/components/charts/ChartsComponent';
import WarningsComponent from '@/components/warnings/WarningsComponent';
import UsersComponent from '@/components/users/UsersComponent';
// Clomos
import api from '@/services/api';

export default {
  name: "ClomosView",
  components: {
    TopMenuComponent,
    SideMenuComponent,
    ProfileComponent,
    DashboardComponent,
    EmplacementsComponent,
    MachinesComponent,
    BundlesComponent,
    DevicesComponent,
    StatusComponent,
    CompaniesComponent,
    ProductsComponent,
    StockComponent,
    ReceptionsComponent,
    ExpeditionsComponent,
    OrdersComponent,
    LockingsComponent,
    LockersComponent,
    ChartsComponent,
    WarningsComponent,
    UsersComponent,
    SlotsComponent
  },
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    }
  },
  watch: {
    session: async function () {
      if (this.session.user.nifCif == "") {
        this.$emit('getCurrentSessionData', (async () => {
          await this.getCurrentCompanyData();
          await this.getAvailableCompanies();
        }));
      } else {
        await this.getCurrentCompanyData();
        await this.getAvailableCompanies();
      }
    }
  },
  data() {
    return {
      visibility: {
        sideMenu: false,
        availableMenu: true,
      },
      currentCompanyNifCif: this.$route.params.currentCompanyNifCif,
      currentCompany: {},
      availableCompanies: [],
    }
  },
  emits: ["getCurrentUserData", "getCurrentSessionData", "getCurrentCompanyData", "logOutRequest"],
  methods: {
    // DISPLAY
    toggleSideMenu() {
      this.visibility.sideMenu = !this.visibility.sideMenu;
    },
    // SESION
    getCurrentUserData(callback) {
      this.$emit('getCurrentUserData', callback);
    },
    async getCurrentCompanyData(callback) {
      if (this.session.user.nifCif === "") return;
      let response = await api.getCompanyData(this.currentCompanyNifCif);
      this.currentCompany = api.parseCompanyFromResponse(response);
      if (callback && typeof callback === "function") callback(this.currentCompany);
    },
    async getAvailableCompanies(callback, forced) {
      if (this.availableCompanies.length > 0 && forced === undefined) {
        if (callback && typeof callback === "function") {
          callback(this.availableCompanies);
        }
        return;
      }
      let availableCompanies = [];
      let request = {
        nifCif: this.session.user.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let responseUserCompany = await api.getCompanyData(this.session.user.nifCif);
      let uc = api.parseCompanyFromResponse(responseUserCompany);
      availableCompanies.push(uc);
      let responseAvailableCompanies = await api.getCompanyList(request);
      let ac = api.parseAvailableCompaniesFromResponse(responseAvailableCompanies);
      for (let c in ac) availableCompanies.push(ac[c]);
      this.availableCompanies = availableCompanies;
      if (callback && typeof callback === "function") callback(this.availableCompanies);
    },
    // SET
    async setCurrentCompany(nifCif, callback) {
      if (nifCif === "showAll") {
        this.currentCompanyNifCif = "showAll";
        this.currentCompany = {
          nifCif: "showAll",
          name: "Show All"
        }
      }
      else {
        this.currentCompanyNifCif = nifCif;
        let response = await api.getCompanyData(nifCif);
        this.currentCompany = api.parseCompanyFromResponse(response);
      }
      this.$router.replace({ params: { currentCompanyNifCif: this.currentCompanyNifCif, targetEntity: "" } })
      if (callback && typeof callback === "function") callback(this.currentCompany);
    },
    logOutRequest() {
      this.$emit('logOutRequest');
    }
  },
  async created() {
    if (this.session.user.nifCif == "") {
      this.$emit('getCurrentSessionData', (async () => {
        await this.getCurrentCompanyData();
        await this.getAvailableCompanies();
      }));
    } else {
      await this.getCurrentCompanyData();
      await this.getAvailableCompanies();
    }
    api.pointAtModule(this.$route.params.currentModule);
  }
}
</script>
<style>
.fontAdn {
  font-family: 'Montserrat', 'Segoe UI', sans-serif !important;
}
</style>