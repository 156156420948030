<template>
  <!-- MODAL Edit User -->
  <div id="editUserModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
    <div class="col-12 m-0 p-0 section-container rounded-5 sombra-modal">
      <!-- HEAD -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 bg-clomos-black">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="person" class="tx-18 me-2 align-bottom"></ion-icon>
            {{ dictionary['Editar usuario'][session.user.language] + ": " + editUserData.name + " " + editUserData.lastName }}
          </h6>
        </div>

        <div class="col-2 m-0 p-0 d-flex justify-content-end">
          <ion-icon :name="(editMode) ? 'lock-closed' : 'lock-open'"
            class="tx-18 btn m-0 mt-1 p-1 border-2 rounded tx-white align-bottom"
            @click="editMode = !editMode"></ion-icon>
          <ion-icon name="close" class="tx-30 btn m-0 p-1 pb-0 tx-white align-bottom"
            @click="$emit('toggleEditUserModal')"></ion-icon>
        </div>
      </div>

      <!-- CONTENT Edit User -->
      <div class="bg-caja m-0 p-0 w-100 rounded-bottom-5 pe-3">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="col-12 p-3 pe-0 pb-0" style="display: flex; flex-flow: wrap;">

            <!-- FORM User -->
            <div class="col-lg-8 col-md-12 col-12 p-0 pe-3 pb-3 m-0">
              <form class="m-0 p-1 border rounded form-material">

                <div class="border border-black text-black p-1 m-0 rounded">
                  {{ dictionary['Datos personales'][session.user.language] }}
                </div>

                <div class="m-0 p-2">
                  <div class="row justify-content-center">

                    <!-- NOMBRE -->
                    <div class="form-group col-md-6 col-12 m-0 p-3 pt-1 pb-0 tx-left">
                      <label class="p-0 tx-13 text-dark tx-bold">
                        {{ dictionary['Nombre'][session.user.language] }}
                      </label>
                      <div class="col-12 p-0">
                        <span v-if="!editMode" class="tx-13">
                          {{ editUserData.name }}
                        </span>
                        <input v-if="editMode" v-model="editUserData.name" id="inputEditUserName" type="text"
                          class="form-control form-control-line p-1" style="color:black;">
                      </div>
                    </div>

                    <!-- APELLIDOS -->
                    <div class="form-group col-md-6 col-12 m-0 p-3 pt-1 pb-0 tx-left">
                      <label class="p-0 tx-13 text-dark tx-bold">
                        {{ dictionary['Apellidos'][session.user.language] }}
                      </label>
                      <div class="col-12 p-0">
                        <span v-if="!editMode" class="tx-13">
                          {{ editUserData.lastName }}
                        </span>
                        <input v-if="editMode" v-model="editUserData.lastName" id="inputEditUserLastName" type="text"
                          class="form-control form-control-line p-1" style="color:black;">
                      </div>
                    </div>

                    <!-- CORREO -->
                    <div class="form-group col-md-12 col-12 m-0 ps-3 pe-3 tx-left">
                      <label class="p-0 tx-13 text-dark tx-bold">
                        {{ dictionary['Correo electrónico'][session.user.language] }}
                      </label>
                      <div class="col-12 p-0">
                        <span v-if="!editMode" class="tx-13">
                          {{ editUserData.email }}
                        </span>
                        <input v-if="editMode" v-model="editUserData.email" id="inputEditUserEmail" disabled type="text"
                          class="form-control form-control-line p-1" style="color:black;">
                      </div>
                    </div>

                    <!-- CORREO RE -->
                    <!--
                    <div class="row">
                      <div class="form-group col-md-12 col-12 m-0">
                        <label class="col-12 tx-bold tx-clomos-midtone">
                          {{dictionary['Repetir correo'][session.user.language]}}
                        </label>
                        <div class="col-12 p-0">
                          <span v-if="!editMode" class="tx-13">
                            {{editUserData.emailRe}}

                          </ v-if="editMode"span>
                          <input v-model="editUserData.emailRe" id="inputEditUserEmailRe" type="text" 
                          class="form-control form-control-line p-1" style="color:black;">
                        </div>
                      </div>
                    
                    </div>
  -->

                    <!-- ROL -->
                    <div class="form-group col-md-6 col-12 m-0 p-3 pt-1 pb-0 tx-left">
                      <label class="p-0 tx-13 text-dark tx-bold">
                        {{ dictionary['Rol'][session.user.language] }}
                      </label>
                      <div class="col-12 p-0">
                        <span v-if="!editMode" class="tx-13">
                          {{ (editUserData.rol === "255")
              ? assignRolName("255")
              : (editUserData.rol === "128")
                ? assignRolName("128")
                : (editUserData.rol === "32")
                  ? assignRolName("32")
                  : (editUserData.rol === "2")
                    ? assignRolName("2")
                    : (editUserData.rol === "1")
                      ? assignRolName("1")
                      : '' }}
                        </span>
                        <select v-if="editMode" v-model="editUserData.rol" disabled id="selectEditUserRol"
                          class="form-control form-control-line p-1" style="color:black;">
                          <option value="255">
                            {{ assignRolName("255") }}
                          </option>
                          <option value="128">
                            {{ assignRolName("128") }}
                          </option>
                          <option value="32">
                            {{ assignRolName("32") }}
                          </option>
                          <option value="2">
                            {{ assignRolName("2") }}
                          </option>
                          <option value="1">
                            {{ assignRolName("1") }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <!-- TELÉFONO -->
                    <div class="form-group col-md-6 col-12 m-0 p-3 pt-1 pb-0 tx-left">
                      <label class="p-0 tx-13 text-dark tx-bold">
                        {{ dictionary['Teléfono'][session.user.language] }}
                      </label>
                      <div class="col-12 p-0">
                        <div class="w-100">
                          <span v-if="!editMode" class="tx-13">
                            {{ editUserData.telephone }}
                          </span>
                          <input v-if="editMode" v-model="editUserData.telephone" id="inputEditUserTelephone"
                            class="form-control form-control-line p-1" style="color:black;">
                        </div>
                      </div>
                    </div>

                    <!-- PROVINCIA -->
                    <div class="form-group col-md-6 col-12 m-0 p-3 pt-1 pb-0 tx-left">
                      <label class="p-0 tx-13 text-dark tx-bold">
                        {{ dictionary['Provincia'][session.user.language] }}
                      </label>
                      <div class="col-12 p-0">
                        <span v-if="!editMode" class="tx-13">
                          {{ editUserData.province }}
                        </span>
                        <input v-if="editMode" v-model="editUserData.province" id="inputEditUserState" type="text"
                          class="form-control form-control-line p-1" style="color:black;">
                      </div>
                    </div>

                    <!-- PAÍS -->
                    <div class="form-group col-md-6 col-12 m-0 p-3 pt-1 pb-0 tx-left">
                      <label class="p-0 tx-13 text-dark tx-bold">
                        {{ dictionary['País'][session.user.language] }}
                      </label>
                      <div class="col-12 p-0">
                        <span v-if="!editMode" class="tx-13">
                          {{ editUserData.country }}
                        </span>
                        <select v-if="editMode" v-model="editUserData.country" id="selectEditUserCountry"
                          class="form-control form-control-line p-1" style="color:black;">
                          <option v-for="country in countries" :key="country['code']" :value="country['code']">
                            {{ country['name'][session.user.language] }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <!-- DIRECCIÓN -->
                    <div class="form-group col-md-6 col-12 m-0 p-3 pt-1 pb-0 tx-left">
                      <label class="p-0 tx-13 text-dark tx-bold">
                        {{ dictionary['Dirección'][session.user.language] }} 1
                      </label>
                      <div class="col-12 p-0">
                        <span v-if="!editMode" class="tx-13">
                          {{ editUserData.adress1 }}
                        </span>
                        <textarea v-if="editMode" v-model="editUserData.adress1" id="inputEditUserAdress1" rows="1"
                          class="form-control" style="color: black; padding: 0.7rem;" />
                      </div>
                    </div>

                    <div class="form-group col-md-6 col-12 m-0 p-3 pt-1 pb-0 tx-left">
                      <label class="p-0 tx-13 text-dark tx-bold">
                        {{ dictionary['Dirección'][session.user.language] }} 2
                      </label>
                      <div class="col-12 p-0">
                        <span v-if="!editMode" class="tx-13">
                          {{ editUserData.adress2 }}
                        </span>
                        <textarea v-if="editMode" v-model="editUserData.adress2" id="inputEditUserAdress2" rows="1"
                          class="form-control" style="color: black; padding: 0.7rem;" />
                      </div>
                    </div>

                  </div>

                  <div class="col-12 p-0 ps-1 pe-1">
                    <hr class="tx-clomos-dark bg-clomos-black">
                  </div>

                  <div class="row">

                    <div class="col-md-6 col-12 pt-0">
                      <label class="col-12 ps-1 tx-16 tx-clomos-black tx-bold">
                        {{ dictionary['Preferencias'][session.user.language] }}:
                      </label>
                    </div>

                  </div>

                  <div class="row">

                    <!-- IDIOMA -->
                    <div class="form-group col-md-6 col-12 m-0 p-3 pt-1 pb-0 tx-left">
                      <label class="p-0 tx-13 text-dark tx-bold">
                        {{ dictionary['Idioma'][session.user.language] }}
                      </label>
                      <div class="col-12 p-0">
                        <span v-if="!editMode" class="tx-13">
                          {{ (editUserData.language === "es")
              ? dictionary['Español'][session.user.language]
              : (editUserData.language === "en")
                ? dictionary['Inglés'][session.user.language]
                : (editUserData.language === "eus")
                  ? dictionary['Euskera'][session.user.language]
                  : '' }}
                        </span>
                        <select v-if="editMode" v-model="editUserData.language" id="selectEditUserLanguage"
                          class="form-control form-control-line p-1" style="color:black;">
                          <option value="es">
                            {{ dictionary['Español'][session.user.language] }}
                          </option>
                          <option value="en">
                            {{ dictionary['Inglés'][session.user.language] }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <!-- SEPARADOR DE COMAS CSV -->
                    <div class="form-group col-md-6 col-12 m-0 p-3 pt-1 pb-0 tx-left">
                      <label class="p-0 tx-13 text-dark tx-bold">
                        {{ dictionary['Separador de comas CSV'][session.user.language] }}
                      </label>
                      <div class="col-12 p-0">
                        <span v-if="!editMode" class="tx-13">
                          {{ (editUserData.fieldSeparator === ",")
              ? dictionary['Coma'][session.user.language]
              : (editUserData.fieldSeparator === ";")
                ? dictionary['Punto y coma'][session.user.language]
                : '' }}
                        </span>
                        <select v-if="editMode" v-model="editUserData.fieldSeparator" id="slectEditUserFieldSeparator"
                          class="form-control form-control-line p-1" style="color:black;">
                          <option value=",">
                            {{ dictionary['Coma'][session.user.language] }} ","
                          </option>
                          <option value=";">
                            {{ dictionary['Punto y coma'][session.user.language] }} ";"
                          </option>
                        </select>
                      </div>
                    </div>

                    <!-- TEMPERATURA -->
                    <div class="form-group col-md-6 col-12 m-0 p-3 pt-1 pb-0 tx-left">
                      <label class="p-0 tx-13 text-dark tx-bold">
                        {{ dictionary['Temperatura'][session.user.language] }}
                      </label>
                      <div class="col-12 p-0">
                        <span v-if="!editMode" class="tx-13">
                          {{ (editUserData.unitTemperature === "C")
              ? "Cº (Celsius)"
              : (editUserData.unitTemperature === "F")
                ? "Fº (Fahrenheit)"
                : '' }}
                        </span>
                        <select v-if="editMode" v-model="editUserData.unitTemperature"
                          id="selectEditUserUnitTemperature" class="form-control form-control-line p-1"
                          style="color:black;">
                          <option value="C">Cº (Celsius)</option>
                          <option value="F">Fº (Fahrenheit)</option>
                        </select>
                      </div>
                    </div>

                    <!-- FORMATO -->
                    <div class="form-group col-md-6 col-12 m-0 p-3 pt-1 pb-0 tx-left">
                      <label class="p-0 tx-13 text-dark tx-bold">
                        {{ dictionary['Formato'][session.user.language] }}
                      </label>
                      <div class="col-12 p-0">
                        <span v-if="!editMode" class="tx-13">
                          {{ editUserData.dateTimeFormat }}
                        </span>
                        <select v-if="editMode" v-model="editUserData.dateTimeFormat" id="selectEditUserDateTimeFormat"
                          class="form-control form-control-line p-1" style="color:black;">
                          <option value="DD/MM/YYYY">DD/MM/YYYY</option>
                          <option value="MM/DD/YYYY">MM/DD/YYYY</option>
                        </select>
                      </div>
                    </div>

                    <!-- ZONA HORARIA -->
                    <div class="form-group col-md-6 col-12 m-0 mb-3 p-3 pt-1 pb-0 tx-left">
                      <label class="p-0 tx-13 text-dark tx-bold">
                        {{ dictionary['Zona horaria'][session.user.language] }}
                      </label>
                      <div class="col-12 p-0">
                        <span v-if="!editMode" class="tx-13">
                          {{ editUserData.dateTimeZone.replace("_", " ") }}
                        </span>
                        <select v-if="editMode" v-model="editUserData.dateTimeZone" id="selectEditUserDateTimeZone"
                          class="form-control form-control-line p-1" style="color:black;">
                          <option v-for="timeZone in timeZones" :key="timeZone['value']" :value="timeZone['value']">
                            {{ timeZone['value'].replace("_", " ") }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <!-- SUBMIT -->
                    <div class="form-group col-md-6 col-12 mt-auto">
                      <div v-if="editMode" class="col-12 m-0 ps-2 pe-2">
                        <button type="button" @click="setEditUserData" id="btnSubmitCreateEditUser"
                          class="button-ok bg-clomos-black rounded-3">
                          <span class="button-text text-white">
                            <ion-icon name="create-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                            <span class="text-white mt-0">{{ dictionary['Guardar cambios'][session.user.language]}}</span>
                          </span>
                        </button>
                      </div>
                    </div>

                  </div>
                </div>

              </form>
            </div>

            <div class="col-lg-4 col-md-12 col-12 p-0 pe-3 m-0 d-flex flex-column">
              <!-- FORM Password -->
              <form v-if="editMode" id="cardUserChangePassword" class="border border-black rounded-5 mb-3">
                <div class="container-fluid">
                  <div class="row p-3">

                    <div class="col-12 m-0 p-0">
                      <label class="row tx-13 tx-black tx-left m-0">
                        {{ dictionary['Nueva contraseña'][session.user.language] }}
                      </label>
                      <div class="col-12 m-0 p-0">
                        <input v-model="changePassData.newPassword" id="inputEditUserNewPassword" type="password"
                          autocomplete="off" class="form-control form-control-line p-1"
                          style="color:black; background-color: #EEEEEE">
                      </div>
                    </div>

                    <div class="col-12 m-0 p-0">
                      <label class="row tx-13 tx-black tx-left m-0 mt-2">
                        {{ dictionary['Repite la contraseña'][session.user.language] }}
                      </label>
                      <div class="col-12 m-0 p-0">
                        <input v-model="changePassData.newPasswordCheck" id="inputEditUserNewPasswordChe"
                          type="password" autocomplete="off" class="form-control form-control-line p-1"
                          style="color:black; background-color: #EEEEEE">
                      </div>
                    </div>

                    <div class="col-12 p-0">
                      <div class="col-12 p-3 pb-0">
                        <button id="btnGuardarPass" type="button" class="button-ok bg-clomos-black"
                          @click="onChangePassword">
                          <span>
                            <span class="button-text text-white">
                              <ion-icon name="key-outline" class="text-white pt-0 me-2 tx-18 hydrated"></ion-icon>
                              <span class="text-white text-nowrap mt-0">{{ dictionary["Restablecer contraseña"][session.user.language]}}</span>
                            </span>
                          </span>
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </form>


              <div class="border border-black rounded-5 col-12 mb-3 p-3 pt-2 pb-1">
                <!-- MÓDULOS -->
                <div :style="(!editMode) ? 'pointer-events:none' : ''" class="form-group col-12 m-0 p-0 ps-2 pe-2">
                  <label class="col-12 m-0 p-0 tx-black tx-14 text-nowrap">
                    {{ dictionary['Módulos permitidos'][session.user.language] }}
                  </label>

                  <div class="col-12 p-1">
                    <div v-for="module, moduleKey in session.authorisation.availableModules" :key="moduleKey"
                      class="row">

                      <div class="col-12 p-0 pt-1">
                        <hr class="tx-white mt-0 bg-clomos-black">
                      </div>

                      <div class="btn modules-button w-100 m-0 mb-2"
                        :class="(editUserData.authorisation.availableModules[moduleKey] != undefined) ? 'active' : ''"
                        @click="(editUserData.authorisation.availableModules[moduleKey] != undefined) ? delete editUserData.authorisation.availableModules[moduleKey] : editUserData.authorisation.availableModules[moduleKey] = {}">
                        {{ moduleKey.toUpperCase() }}
                      </div>
                      <div class="row mb-2 d-flex flex-wrap justify-content-start gap-1">
                        <div v-for="page, pageKey in module" :key="pageKey" class="btn modules-button w-auto m-0"
                          :class="(editUserData.authorisation.availableModules[moduleKey] != undefined) ? (editUserData.authorisation.availableModules[moduleKey][pageKey] != undefined) ? 'active' : '' : 'disabled'"
                          @click="(editUserData.authorisation.availableModules[moduleKey] != undefined && editUserData.authorisation.availableModules[moduleKey][pageKey] != undefined) ? delete editUserData.authorisation.availableModules[moduleKey][pageKey] : editUserData.authorisation.availableModules[moduleKey][pageKey] = 1">
                          {{ pageKey.substring(0, 1).toUpperCase()+pageKey.substring(1,pageKey.length) }}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "EditUserModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    userId: {
      type: String,
      requiered: true,
    },
    editRequest: {
      type: Boolean,
      requiered: true,
    },
    modules: {
      type: Object,
      requiered: true,
    }
  },
  data() {
    return {
      userRoll: [],
      editMode: false,
      changePassData: {
        oldPassword: "",
        newPassword: "",
        newPasswordCheck: ""
      },
      editUserData: {
        id: "",
        name: "",
        lastName: "",
        email: "",
        emailRe: "",
        adress1: "",
        adress2: "",
        province: "",
        country: "",
        telephone: "",
        rol: "",
        language: "",
        unitTemperature: "",
        unitDistance: "",
        dateTimeZone: "",
        dateTimeFormat: "",
        fieldSeparator: "",
        authorisation: {
          availableModules: {}
        }
      },
    }
  },
  emits: ["toggleEditUserModal"],
  methods: {
    // GET 
    async getEditUserData(callback) {
      let response = await api.getUserData(this.userId);
      this.editUserData = api.parseUserFromResponse(response);
      if (callback && typeof callback === "function") callback(this.editUserData);
    },
    // SET 
    async setEditUserData() {
      //let valid = true;
      //if (this.editUserData.email != this.editUserData.emailRe
      //|| this.editUserData.email === ""
      //|| this.editUserData.emailRe === "") {
      //  $("#inputEditUserEmail").get()[0].classList.add("missing")
      //  $("#inputEditUserEmailRe").get()[0].classList.add("missing")
      //  valid = false;
      //}
      //if (!valid) {
      //  setTimeout(() => {
      //    $("#inputEditUserEmail").get()[0].classList.remove("missing")
      //    $("#inputEditUserEmailRe").get()[0].classList.remove("missing")
      //    }, 3000);
      //    return;
      //}
      let response = await api.setUserData(this.editUserData);
      if (response["status-code"] === "200") {
        window.alert("El usuario ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el usuario.");
      }
      this.getEditUserData();
    },
    // SET Password
    async onChangePassword() {
      let valid = true;
      if (this.changePassData.newPassword != this.changePassData.newPasswordCheck
        || this.changePassData.newPassword === ""
        || this.changePassData.newPasswordCheck === "") {
        $("#inputEditUserNewPassword").get()[0].classList.add("missing")
        $("#inputEditUserNewPasswordChe").get()[0].classList.add("missing")
        valid = false;
      }
      if (!valid) {
        setTimeout(() => {
          $("#inputEditUserNewPassword").get()[0].classList.remove("missing")
          $("#inputEditUserNewPasswordChe").get()[0].classList.remove("missing")
        }, 3000);
        return;
      }
      let request = {
        newPassword: this.changePassData.newPassword,
        userId: this.editUserData.id
      }
      let response = await api.setUserPasswordUnverified(request);
      if (response) {
        console.log("Password changed.");
        this.changePassData.oldPassword = "";
        this.changePassData.newPassword = "";
        this.changePassData.newPasswordCheck = "";
      }
    },
    //FILTER
    async displayRolbyModulues() {
      this.userRol = [];
      let userModules = this.session.authorisation.availableModules;
      for (let moduleName in userModules) {
        for (let moduleRol in this.modules[moduleName]) {
          let rolInfo = {
            numero: moduleRol,
            nombre: this.modules[moduleName][moduleRol]
          };
          let existingRolIndex = this.userRol.findIndex(addedRol => addedRol.numero === rolInfo.numero);
          if (existingRolIndex !== -1) {
            if (!this.userRol[existingRolIndex].nombre.includes(rolInfo.nombre)) {
              this.userRol[existingRolIndex].nombre += "/" + rolInfo.nombre;
            }
          } else {
            this.userRol.push(rolInfo);
          }
        }
      }
    },
    assignRolName(numberString) {
      if (!this.userRol || this.userRol.length === 0) return numberString;
      const userRole = this.userRol.find(element => numberString === element.numero);
      if (!userRole) return numberString;
      const roleName = this.dictionary[userRole.nombre][this.session.user.language];
      return roleName;
    }
  },


  created() {
    this.editMode = (this.editRequest) ? true : false;
    this.getEditUserData();
  },
  mounted() {
    this.displayRolbyModulues()
  }
}
</script>

<style>
#editUserModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>