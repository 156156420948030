<template>
<!-- MODAL New Locking -->
  <div id="newLockingModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
    <div class="col-12 m-0 p-0 section-container rounded-5 sombra">
<!-- HEAD -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 bg-clomos-black">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="lock-open-outline" class="tx-18 me-2 align-bottom"></ion-icon>
            {{dictionary["Nueva reserva"][session.user.language]}}
          </h6>
        </div>

        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleNewLockingModal')">	
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>

<!-- CONTENT Prepare Locking -->
      <div class="bg-caja m-0 p-0 w-100 rounded-bottom-5 pe-3">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;" class="p-3">
          <div class="col-12 p-3 border rounded form-material" style="overflow: auto; overflow-y: hidden; display: flex; flex-flow: wrap;">

<!-- CONTENT New Locking -->
            <form class="col-12 m-0 p-0">
              
              <div id="lockersMapContent"
              class="m-0 p-3" style="width: 100%; height: 200px;">
              </div>

              <div v-if="targetLocker.id!= undefined" class="row col-12 m-0 mb-2 p-2 pt-0 bg-clomos-black rounded-bottom-5">

                <div class="form-group col-lg-3 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-white text-nowrap">
                    {{ dictionary['Locker'][session.user.language] + ":" }}
                  </label>
                  <span class="col-md-12 p-0 tx-clomos-white">
                    {{targetLocker.label}}
                  </span>
                </div>

                <div class="form-group col-lg-3 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-white text-nowrap">
                    {{ dictionary['Emplazamiento'][session.user.language] + ":" }}
                  </label>
                  <span class="col-md-12 p-0 tx-clomos-white">
                    {{(targetLocker.emplacement!=undefined&&targetLocker.emplacement!="")?targetLocker.emplacement:this.dictionary["Sin localizar"][this.session.user.language]}}
                  
                  </span>
                </div>

                <div class="form-group col-lg-3 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-white text-nowrap">
                    {{ dictionary['Huecos libres'][session.user.language] + ":" }}
                  </label>
                  <span class="col-md-12 p-0 tx-clomos-white">
                    {{(targetLocker.variables!=undefined&&targetLocker.variables.analog["huecosLibres"]!=undefined)?targetLocker.variables.analog["huecosLibres"].value:dictionary['Sin configurar'][session.user.language]}}
                  </span>
                </div>

                <div class="form-group col-lg-3 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-white text-nowrap">
                    {{ dictionary['Huecos ocupados'][session.user.language] + ":" }}
                  </label>
                  <span class="col-md-12 p-0 tx-clomos-white">
                    {{(targetLocker.variables!=undefined&&targetLocker.variables.analog["huecosOcupados"]!=undefined)?targetLocker.variables.analog["huecosOcupados"].value:dictionary['Sin configurar'][session.user.language]}}
                  </span>
                </div>

              </div>

              <div v-else class="row col-12 m-0 mb-2 p-2  rounded-bottom-5" style="background-color: black;">
                <span id="displayNewLockingLockerId" class="col-md-12 p-0 tx-clomos-white">
                  {{ dictionary['Seleccione un Locker'][session.user.language] }}
                </span>
              </div>

<!-- LOCKING DATA -->
              <div class="col-12 m-0 p-0">
                <label class="col-12 m-0 p-0 tx-bold tx-black text-nowrap">
                  {{dictionary['Datos de la reserva'][session.user.language]}}
                </label>
              </div>

              <div class="row col-12 m-0 p-0">

                <div class="form-group col-lg-6 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['ID pedido'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <input v-model="newLockingData.id" id="inputNewLockingId" type="text"
                    class="form-control form-control-line ps-0 pe-0" style="color:black;">
                  </div>
                </div>

                <div class="form-group col-lg-6 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Observaciones'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <textarea v-model="newLockingData.observations" id="inputNewLockingObservations" rows="1"
                    class="form-control form-control-line ps-0 pe-0" style="color:black;"/>
                  </div>
                </div>

              </div>

              <div class="row col-12 m-0 p-0">

                <div class="form-group col-lg-6 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['E-mail'][session.user.language] + " (" + dictionary['Cliente'][session.user.language] + ")" }}
                  </label>
                  <div class="col-md-12 p-0">
                    <input v-model="newLockingData.userEmail" id="inputNewLockingUserEmail" type="text"
                    class="form-control form-control-line ps-0 pe-0" style="color:black;">
                  </div>
                </div>

                <div class="form-group col-lg-6 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['E-mail'][session.user.language] + " (" + dictionary['Transportista'][session.user.language] + ")" }}
                  </label>
                  <div class="col-md-12 p-0">
                    <input v-model="newLockingData.transportEmail" id="inputNewLockingTransportEmail" type="text"
                    class="form-control form-control-line ps-0 pe-0" style="color:black;">
                  </div>
                </div>
                
              </div>

<!-- DELIVERY DATA -->
              <div class="col-12 m-0 p-0">
                <label class="col-12 m-0 p-0 tx-bold tx-black text-nowrap">
                  {{dictionary['Datos de la entrega'][session.user.language]}}
                </label>
              </div>

              <div class="row col-12 m-0 p-0">

                <div class="form-group col-md-6 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Fecha prevista'][session.user.language]}}
                  </label>
                  <div class="col-12 p-0">
                    <input v-model="newLockingData.scheduledDateTime" id="inputNewLockingScheduledDateTime" type="date"
                    class="form-control form-control-line ps-0 pe-0" style="color:black;">
                  </div>
                </div>

                <div class="form-group col-md-3 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Días de espera'][session.user.language]}}
                  </label>
                  <div class="col-12 p-0">
                    <input v-model="newLockingData.maxHoldingDays" id="inputNewLockingMaxHoldingDays" type="number" min=1
                    class="form-control form-control-line ps-0 pe-0" style="color:black;">
                  </div>
                </div>

                <div class="form-group col-md-3 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Accesibilidad'][session.user.language]}}
                  </label>
                  <div class="col-12 p-0">
                    <div class="inputEditSlot form-control form-control-line border-0 tx-bold c-pointer"
                      :class="newLockingData.accessible? 'true' : 'false'"
                      @click="newLockingData.accessible ? newLockingData.accessible = false : newLockingData.accessible = true">
                        {{ newLockingData.accessible? "Sí" : "No" }}
                    </div>
                  </div>
                </div>

              </div>

              <div class="col-12 m-0 p-0">
                <label class="col-12 m-0 p-0 tx-bold tx-black text-nowrap">
                  {{dictionary['Bultos'][session.user.language]}}
                </label>
              </div>

              <div class="row m-0 p-0 border-2 rounded">
<!-- NEW BULGE -->

                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Ancho'][session.user.language] }}
                    <span class="ps-1 small">(mm)</span>

                  </label>
                  <div class="col-md-12 p-0">
                    <input v-model="inputNewBulgeWidth" id="inputNewBulgeWidth" type="number" min=0
                    class="form-control form-control-line ps-0 pe-0" style="color:black;">
                  </div>
                </div>

                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Alto'][session.user.language] }}
                    <span class="ps-1 small">(mm)</span>
                  </label>
                  <div class="col-md-12 p-0">
                    <input v-model="inputNewBulgeHeight" id="inputNewBulgeHeight" type="number" min=0
                    class="form-control form-control-line ps-0 pe-0" style="color:black;">
                  </div>
                </div>

                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Largo'][session.user.language] }}
                    <span class="ps-1 small">(mm)</span>
                  </label>
                  <div class="col-md-12 p-0">
                    <input v-model="inputNewBulgeLength" id="inputNewBulgeLength" type="number" min=0
                    class="form-control form-control-line ps-0 pe-0" style="color:black;">
                  </div>
                </div>

                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Peso'][session.user.language] }}
                    <span class="ps-1 small">(g)</span>
                  </label>
                  <div class="col-md-12 p-0">
                    <input v-model="inputNewBulgeWeight" id="inputNewBulgeWeight" type="number" min=0
                    class="form-control form-control-line ps-0 pe-0" style="color:black;">
                  </div>
                </div>

                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Tipo'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <select v-model="inputNewBulgeType" id="inputNewBulgeType"
                    class="form-control form-control-line ps-0 pe-0 pb-2" style="color:black;">
                      <option v-for="option in newBulgeTypeList" :key="option" :value="option">
                        {{ dictionary[option][session.user.language] }}
                      </option>  
                    </select>
                  </div>
                </div>

                <div class="form-group col-lg-2 col-12 p-0 pt-2 m-0">
                  <ion-icon name="add-circle-outline" class="tx-primary-adn c-pointer targets-icons m-0 p-0 pt-2 tx-36" style="vertical-align: middle;"
                  @click="onAppendBulge()">
                  </ion-icon>
                  <ion-icon name="close-circle-outline" class="tx-primary-adn c-pointer targets-icons m-0 p-0 pt-2 tx-36" style="vertical-align: middle;"
                  @click="onClearNewBulge()">
                  </ion-icon>
                </div>

              </div>
              <div  v-if="newLockingData.bulgeList.length>0" class="col-12 m-0 p-0 mt-2">
                <label class="col-12 m-0 p-0 tx-bold tx-black text-nowrap">
                  <span>{{ dictionary['Número de bultos añadidos'][session.user.language] }} {{ newLockingData.bulgeList.length }}</span>
                </label>
              </div>
              <div class="row m-0 mt-3 p-0 border-2 rounded" v-for="target in newLockingData.bulgeList" :key="target">
<!-- BULGES -->
                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Ancho'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <div class="border-bottom tx-13 pb-1 text-nowrap" style="color:black;">
                    {{ target["ancho"] }}
                    <span class="ps-1 small">mm</span>
                    </div>
                  </div>
                </div>
                
                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Alto'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <div class="border-bottom tx-13 pb-1 text-nowrap" style="color:black;">
                    {{ target["alto"] }}
                    <span class="ps-1 small">mm</span>
                    </div>
                  </div>
                </div>
                
                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Largo'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <div class="border-bottom tx-13 pb-1 text-nowrap" style="color:black;">
                    {{ target["fondo"] }}
                    <span class="ps-1 small">mm</span>
                    </div>
                  </div>
                </div>

                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Peso'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <div class="border-bottom tx-13 pb-1 text-nowrap" style="color:black;">
                    {{ target["peso"] }}
                    <span class="ps-1 small">g</span>
                    </div>
                  </div>
                </div>
                
                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Tipo'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <div class="border-bottom tx-13 pb-1 text-nowrap" style="color:black;">
                    {{ dictionary[target["tipo"]][session.user.language] }}
                    </div>
                  </div>
                </div>

                <div class="form-group col-lg-2 col-12 m-0 p-0">
                  <ion-icon name="remove-circle-outline" class="tx-primary-adn targets-icons m-0 p-0 pt-2 tx-36" style="vertical-align: middle;"
                  @click="this.newLockingData.bulgeList.splice(target,1)"></ion-icon>
                </div>

              </div>

              <div class="row">
<!-- SUBMIT -->

                <div class="form-group col-lg-6 col-12 p-0 pt-3 m-0 ms-auto me-auto">
                  <div class="col-md-12">
                    <button type="button" id="btnSubmitCreateNewLocking" class="button-ok bg-clomos-black text-white rounded-3" 
                    @click="setNewLockingData">
                      {{ dictionary['Crear nueva reserva'][session.user.language] }}
                    </button>
                  </div>
                </div>

              </div>

            </form>
              
          </div>
        </div>
      </div>
      
    </div>
  </div>

</template>

<script>
import $ from 'jquery';
import Leaflet from 'leaflet';
import { MarkerClusterGroup } from 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
// Clomos
import api from '@/services/api';

export default {
  name: "NewLockingModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    productId: {
      type: String,
      requiered: true,
    },
  },
  data() {
    return {
      newLockingData: {
        userEmail: "",
        transportEmail: this.session.user.id,
        id: "",
        lockerId: "",
        observations: "",
        scheduledDateTime: new Date(),
        maxHoldingDays: 1,
        accessible: false,
        bulgeList: [],
        releasedSlots: {}
      },
      emplacementList: [],
      lockerList: [],
      lockersMap: undefined,
      targetLocker: {},
      inputNewBulgeId: "",
      inputNewBulgeWidth: 0,
      inputNewBulgeHeight: 0,
      inputNewBulgeLength: 0,
      inputNewBulgeWeight: 0,
      inputNewBulgeType: "",
      newBulgeTypeList: ["frio", "ambiente"],
    }
  },
  emits: ["toggleNewLockingModal"],
  methods: {
    // DISPLAY
    async displayLockersMap() {
      if (this.lockersMap != undefined) {
        this.lockersMap.invalidateSize();
        this.lockersMap.remove();
      }
      var lockersMap = Leaflet.map("lockersMapContent");
      Leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png?lang='+this.session.user.language,{maxZoom: 19}).addTo(lockersMap);
      
      
      var siteMarkersGroup = new MarkerClusterGroup();
      lockersMap.addLayer(siteMarkersGroup);

      let lockersMapListForGroup = []; 
      siteMarkersGroup.addLayers(this.lockerList.map(l => {
        const options = { title: l.label, clickable: true, draggable: false };
        let coordinates = [0,0];
        if (l.parent === "") 
          coordinates = [l.geoJSON.geometry.coordinates[0],l.geoJSON.geometry.coordinates[1]]
        else for (let ii in this.emplacementList) if (l.parent === this.emplacementList[ii].id)
          coordinates = [this.emplacementList[ii].geoJSON.geometry.coordinates[0],this.emplacementList[ii].geoJSON.geometry.coordinates[1]]
        let emplacement = (l.emplacement!=undefined&&l.emplacement!="")?l.emplacement:this.dictionary["Sin localizar"][this.session.user.language];
        var marker = Leaflet.marker(coordinates, options);
        marker.on("click", () => {this.targetLocker = l});
        let freeSlots = l.variables.analog["huecosLibres"].value;
        let totalSlots = l.variables.analog["huecosTotales"].value;
        marker.bindPopup('<div class="d-flex flex-column" style="min-width: 9rem;">'
        +'<span data-id="' + l.id + '" class="btnTargetLocker c-pointer tx-bold tx-grey m-0 p-0">' + l.label + '</span>'
        +'<div class="m-0 p-0 d-flex flex-column justify-content-between">'
          +'<text class="w-100">' + emplacement + '</text>'
          +'<text class="w-100"> Huecos libres:' + freeSlots + '</text>'
          +'<text class="w-100"> Huecos totales:' + totalSlots + '</text>'
        +'</div></div>');
        lockersMapListForGroup.push(marker);
        return marker;
      }))
      if (lockersMapListForGroup[0] != undefined) {
        var lockersMapGroup = Leaflet.featureGroup(lockersMapListForGroup);
        lockersMap.fitBounds(lockersMapGroup.getBounds());
      } else {
        lockersMap.setView(new Leaflet.LatLng(40.426, -3.7034), 10);
      }

      if ($(".leaflet-control-attribution").get()[0] != undefined) $(".leaflet-control-attribution").get()[0].remove();

      this.lockersMap = lockersMap;
    },
    // GET
    async getLockerList() {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: ""
      }
      let response = await api.getLockerListByCompany(request);
      this.lockerList = api.parseLockersFromResponse(response);

      for (let i in this.lockerList) {
        let response = await api.getLockerData(this.lockerList[i].id);
        if (response["status-code"] === "200") {
          this.lockerList[i] = api.parseMachineFromResponse(response);
        }
      }
      await this.displayLockersMap();
    },
    async getEmplacementList(callback) {
      let request = {
        nifCif: "showAll",
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getLockerEmplacementList(request);
      this.emplacementList = api.parseEmplacementsFromResponse(response);
      if (callback && typeof callback === "function") callback(this.emplacementList);
    },
    // SET
    async setNewLockingData() {
      let valid = true;
      if (this.targetLocker.id != undefined) {
        this.newLockingData.lockerId = this.targetLocker.id;
      }
      else {
        $("#displayNewLockingLockerId").get()[0].style.color = "orange";
        valid = false;
      }    
      if (this.newLockingData.id == "") {
        $("#inputNewLockingId").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newLockingData.userEmail == "" || !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.newLockingData.userEmail))) {
        $("#inputNewLockingUserEmail").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newLockingData.transportEmail == "" || !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.newLockingData.transportEmail))) {
        $("#inputNewLockingTransportEmail").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newLockingData.maxHoldingDays == "") {
        $("#inputNewLockingMaxHoldingDays").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newLockingData.bulgeList.length == 0) {
        $("#inputNewBulgeWidth").get()[0].classList.add("missing");
        $("#inputNewBulgeHeight").get()[0].classList.add("missing");
        $("#inputNewBulgeLength").get()[0].classList.add("missing");
        $("#inputNewBulgeType").get()[0].classList.add("missing");
        valid = false;
      }
      if (!valid) {
        setTimeout(() => {
          $("#inputNewBulgeWidth").get()[0].classList.remove("missing")
          $("#inputNewBulgeHeight").get()[0].classList.remove("missing")
          $("#inputNewBulgeLength").get()[0].classList.remove("missing")
          $("#inputNewBulgeType").get()[0].classList.remove("missing")
          $("#inputNewLockingId").get()[0].classList.remove("missing")
          $("#inputNewLockingUserEmail").get()[0].classList.remove("missing")
          $("#inputNewLockingTransportEmail").get()[0].classList.remove("missing")
          $("#inputNewLockingScheduledDateTime").get()[0].classList.remove("missing")
          $("#inputNewLockingMaxHoldingDays").get()[0].classList.remove("missing")
        }, 3000);
        return;
      }
      for (let i in this.newLockingData.bulgeList) {
        this.newLockingData.bulgeList[i]["id"] = Number(i)+1;
        this.newLockingData.bulgeList[i]["accesible"] = this.newLockingData.accessible
      }
      let response = await api.postNewLocking(this.newLockingData);
      if (response["status-code"] === "200") {
        this.$emit('toggleNewLockingModal');
        window.alert("La reserva ha sido creada satisfactoriamente.");
      }
      else {
        window.alert("Error al crear la reserva.");
      }
    },
    // TARGET
    onTargetLocker(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      for (let i in this.lockerList) {
        if (this.lockerList[i].id === event.target.getAttribute('data-id')) this.targetLocker = this.lockerList[i];
      }
    },
    onClearNewBulge() {
      this.inputNewBulgeId = "";
      this.inputNewBulgeType = "";
      this.inputNewBulgeWidth = 0;
      this.inputNewBulgeHeight = 0;
      this.inputNewBulgeLength = 0;
      this.inputNewBulgeWeight = 0;

    },
    onAppendBulge() {
      let valid = true;
      if (this.inputNewBulgeWidth<=0||this.inputNewBulgeWidth==null) {
        $("#inputNewBulgeWidth").get()[0].classList.add("missing");
        valid = false;
      }
      if (this.inputNewBulgeHeight<=0||this.inputNewBulgeHeight==null) {
        $("#inputNewBulgeHeight").get()[0].classList.add("missing");
        valid = false;
      }
      if (this.inputNewBulgeLength<=0||this.inputNewBulgeLength==null) {
        $("#inputNewBulgeLength").get()[0].classList.add("missing");
        valid = false;
      }
      if (this.inputNewBulgeType=="") {
        $("#inputNewBulgeType").get()[0].classList.add("missing");
        valid = false;
      }
      if (!valid) {
        setTimeout(() => {
          $("#inputNewBulgeWidth").get()[0].classList.remove("missing")
          $("#inputNewBulgeHeight").get()[0].classList.remove("missing")
          $("#inputNewBulgeLength").get()[0].classList.remove("missing")
          $("#inputNewBulgeType").get()[0].classList.remove("missing")
        }, 3000);
        return;
      } 
      let bulge = {
      'ancho': this.inputNewBulgeWidth,
      'alto': this.inputNewBulgeHeight,
      'fondo': this.inputNewBulgeLength,
      'peso': this.inputNewBulgeWeight,
      'tipo': this.inputNewBulgeType
      }
      this.newLockingData.bulgeList.push(bulge)
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(()=>{
        $(".btnTargetLocker").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetLocker);
        });
      }, 300);
    },
  },
  async created() {
    let today = new Date(); 
    this.newLockingData.scheduledDateTime = new Date(today.setDate( today.getDate() + 1));
    await this.getEmplacementList();
    await this.getLockerList();
  }
}
</script>

<style>

#newLockingModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

.targets-icons:hover {
  color: var(--main-dark-50);
}

@media (max-width: 767px) {
  #stockImgUploadFormContainer {
    flex-direction: column;
  }
}

.loader {
  color: white !important;
  visibility: visible;
  transition: all 0.3s;
}

.requesting {
  animation: spin 3s infinite linear;
  transition: all 0.3s;
}
.tx-primary-adn{
  color:#7D8235;
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>