<template>
<!-- SECTION SiteMap -->
  <div id="siteMapSection"  class="row col-12 m-0 p-0">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">

<!-- HEADER Display Type -->
      <div class="col-lg-2 col-md-4 col-12 m-0 p-2 text-white rounded-5">
        <div class=" border-1 rounded-5 box sombra" style="background-color: white;">

          <div class="col-lg-12 overflow-hidden col-md-12 col-12 justify-content-center">
            <h6  class="tx-left tx-clomos-black text-center mt-2 mb-2 pt-1">
              <ion-icon name="location" class="tx-16 align-bottom"></ion-icon>
              {{dictionary["Emplazamientos"][session.user.language]}}
            </h6>
          </div>

          <div class="p-3 pt-0">
<!-- Selector de Sistema -->
            <div class="col-12 m-0 p-0">
              <hr v-if="this.$route.params.currentDisplay==='emplacements'" class="m-0 mb-3 p-0 bg-clomos-black" >
              <select v-if="this.$route.params.currentDisplay==='emplacements'" 
              :value="currentCompany.nifCif"
              class="form-control mt-3 mb-3 h-100 text-nowrap bg-white" 
              style="border: solid 2px black ; color: black;"
              @change="onCurrentCompanyChanged">
                <option v-if="availableCompanies[0]!=undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
                <option v-for="company in availableCompanies" :key="company.nifCif"
                :v-if="company.nifCif != session.user.nifCif
                && company.relation != undefined
                && company.relation.referenceCompany === this.session.user.nifCif"
                :value="company.nifCif">
                  {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
                </option>
              </select>
            </div>

            <hr class="m-0 p-0 mb-3">

            <div class="row justify-content-around m-0 p-0">
              <div class="col-3 m-0 mb-3 p-0">	
                <button @click="onChangeDisplayType('map')"
                :style="(displayType==='map')?'opacity=1;':'opacity: 0.7'"
                class="border-black col-12 header-tab-button border-2 btn  rounded-5 p-0 text-nowrap" style="background-color: white;">
                  <ion-icon name="map-outline" 
                  :style="(displayType==='map')?'opacity=1;':'opacity: 0.5'"
                  class="border-black text-black tx-23 pt-3 pb-3" style="vertical-align: middle;"></ion-icon>
                </button>
              </div>
              <div class="col-3 m-0 mb-3 p-0">	
                <button @click="onChangeDisplayType('table')"
                :style="(displayType==='table')?'opacity=1;':'opacity: 0.7'"
                class="border-black col-12 header-tab-button border-2 btn  rounded-5 p-0 text-nowrap" style="background-color: white;">
                  <ion-icon name="list-outline" 
                  :style="(displayType==='table')?'opacity=1;':'opacity: 0.5'"
                  class="border-white  text-black tx-23 pt-3 pb-3" style="vertical-align: middle;"></ion-icon>
                </button>
              </div>
              <div class="col-3 m-0 mb-3 p-0">	
                <button @click="onChangeDisplayType('cards')"
                :style="(displayType==='cards')?'opacity=1;':'opacity: 0.7'"
                class="border-black col-12 header-tab-button border-2 btn  rounded-5 p-0 text-nowrap" style="background-color: white;">
                  <ion-icon name="documents-outline" 
                  :style="(displayType==='cards')?'opacity=1;':'opacity: 0.5'"
                  class="border-white  text-black tx-23 pt-3 pb-3" style="vertical-align: middle;"></ion-icon>
                </button>
              </div>
            </div>

            <hr class="m-0 mb-3 bg-clomos-black p-0">
            
            <button id="btnDisplayNewDeviceModal"
            class="button-ok bg-clomos-black mt-0"  @click="toggleNewEmplacementModal">
              <span>
                <span class="button-text text-black">
                  <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                  <span class="text-white mt-0">{{dictionary["Añadir emplazamiento"][session.user.language]}}</span>
                </span>
              </span>
            </button>
          </div>
          
        </div>
      </div>

      <div class="col-lg-10 col-md-8 col-12 m-0 p-2 overflow-hidden justify-content-center">
        <div class="col-12 table-responsive bg-caja rounded-top-5 m-0 p-0 sombra">
          <!--<ion-icon v-if="session.user.bookmark==='machines'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
          <div class="position-sticky col-12 m-0 pt-2 pb-2 bg-clomos-black text-white border-1">
<!-- HEADER Map -->
            <span v-if="displayType==='map'" class="tx-left tx-clomos-white text-center mt-1 mb-2 ">
              <ion-icon name="location-outline" class="tx-16 mb-1 me-1 align-bottom"></ion-icon>
              {{dictionary["Emplazamientos"][session.user.language] +": "+ dictionary["Mapa"][session.user.language]}}
            </span>
<!-- HEADER List -->
            <span v-if="displayType==='table'" class="tx-left tx-clomos-white text-center mt-1 mb-2">
              <ion-icon name="location-outline" class="tx-16 mb-1 me-1 align-bottom"></ion-icon>
              {{dictionary["Emplazamientos"][session.user.language] +": "+ dictionary["Listado"][session.user.language]}}
            </span>
<!-- HEADER Cards -->
            <span v-if="displayType==='cards'" class="tx-left tx-clomos-white text-center mt-1 mb-2">
              <ion-icon name="location-outline" class="tx-16 mb-1 me-1 align-bottom"></ion-icon>
              {{dictionary["Emplazamientos"][session.user.language] +": "+ dictionary["Tarjetas"][session.user.language]}}
            </span>
          </div>

<!-- CONTENT Map -->
          <div v-show="displayType==='map'"
          id="emplacementsMapContent" class="m-0 p-3" style="width: 100%; height: 500px; z-index: 0;">
          </div>

<!-- CONTENT table -->
          <div v-show="displayType==='table'"
          id="tableContent" class="m-0 p-3 h-auto">
            <table id="emplacementsDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
              <thead class=" tx-14 text-white text-center overflow-hidden bg-clomos-black" >
                <tr>
                  <th v-for="column in emplacementsColumns" :key="column.data"
                  :width="column.width" class="fw-medium text-nowrap p-2">
                    {{dictionary[column.text][session.user.language]}}
                  </th>
                </tr>
              </thead>
            </table>
          </div>

<!-- CONTENT Cards -->
          <div v-show="displayType==='cards'&&this.targetEmplacementId!=''"
          id="siteCardsContent" class="col-12 m-0 p-2 h-auto">
            <div class="col-12 m-0 p-0 d-flex flex-row flex-wrap">
              <div v-for="emplacement in emplacementCardsByPages[emplacementCardsPage]" :key="emplacement"
              class="col-xl-2 col-md-4 col-12 m-0 p-2">
                <h6 class="tx-left tx-clomos-white text-center text-nowrap m-0 p-2 ps-1 pt-2 bg-clomos-black rounded-top-3" >
                  <span class="m-auto">{{(emplacement.label.length>18)?(emplacement.label.substring(0,18)+'...'):emplacement.label}}</span>
                </h6>

                <div class="col-12 border-1 rounded-bottom-3 m-0 p-3">
                  <img :src="displayMyEmplacementImage(emplacement.imgSrc)" :data-id="emplacement.id" alt="Emplacement Card Picture" class="btnTargetEmplacement col-12 m-0 p-0 mb-2">
                  <div class="col-12 m-0 p-0 d-flex space-between">
                    <ion-icon name="create-outline" :data-id="emplacement.id" class="btnEditEmplacement col-2 m-0 mt-auto mb-auto p-0 btn tx-24 tx-primary-adn"></ion-icon>
                    <ion-icon name="trash-outline" :data-id="emplacement.id" class="btnDeleteEmplacement col-2 m-0 mt-auto mb-auto p-0 btn tx-24 tx-danger"></ion-icon>
                  </div>
                  <hr class="col-12 mt-2 mb-2">
                  <div class="col-12 d-flex flex-column tx-left align-middle p-0" style="height: 5rem;">
                    <span class="tx-13 tx-bold">{{dictionary['Localización'][session.user.language]+": "}}</span>
                    <span class="tx-11 m-auto">{{(emplacement.location.length>100)?(emplacement.location.substring(0,100)+'...'):emplacement.location}}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex flex-row m-0 p-2">
                <div v-for="page,index in emplacementCardsByPages" :key="page" 
                class="border rounded c-pointer m-0 me-2 tx-13" :class="(emplacementCardsPage===index)?'border-dark':''" style="height:2.5rem; width:2.5rem; padding: inherit;"
                @click="onSetCardPage(index)">
                  <span class="tx-bold">{{ index+1  }}</span>
                </div>
              </div>
            </div>
          </div>

          <div v-show="displayType==='cards'&&this.targetEmplacementId===''" class="p-3">
            <span class="tx-13"> 
              {{dictionary['No hay emplazamientos disponibles'][session.user.language]}} 
            </span> 
          </div>
        
        </div>
      </div>   	
    </div>
  </div>

<!-- SECTION Devices -->
  <MachinesComponent v-if="this.$route.params.currentModule==='fabric' && session.authorisation.availableModules['fabric'] "
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :visibility="visibility"
  :currentCompany="currentCompany"
  :availableCompanies="availableCompanies"
  :targetEmplacementId="targetEmplacementId"
  :dataTableOptions="dataTableOptions">
  </MachinesComponent>

  <!-- SECTION Lockers -->
  <LockersComponent v-if="this.$route.params.currentModule==='smartLockers' && session.authorisation.availableModules['smartLockers'] "
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :visibility="visibility"
  :currentCompany="currentCompany"
  :availableCompanies="availableCompanies"
  :targetEmplacementId="targetEmplacementId"
  :dataTableOptions="dataTableOptions">
  </LockersComponent>

<!-- SECTION Devices -->
  <DevicesComponent v-if="this.$route.params.currentModule==='devices' && session.authorisation.availableModules['devices']"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :visibility="visibility"
  :currentCompany="currentCompany"
  :availableCompanies="availableCompanies"
  :targetEmplacementId="targetEmplacementId"
  :dataTableOptions="dataTableOptions">
  </DevicesComponent>

<!-- MODAL New Emplacement -->
  <NewEmplacementModal
  v-if="display.newEmplacementModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :currentCompany="currentCompany"
  v-on:toggleNewEmplacementModal="toggleNewEmplacementModal">
  </NewEmplacementModal>

<!-- MODAL Edit Emplacement -->
  <EditEmplacementModal
  v-if="display.editEmplacementModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :currentCompany="currentCompany"
  :emplacementId="targetEmplacementId"
  :editRequest="editRequest"
  v-on:toggleEditEmplacementModal="toggleEditEmplacementModal">
  </EditEmplacementModal>

<!-- MODAL Delete Text Alert -->
  <TextAlertModal
  v-if="display.deleteEmplacementModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertHeader="dictionary['Emplazamiento a eliminar'][session.user.language] + ': '"
  :alertTarget="targetEmplacementId"
  v-on:acceptAlert="onDeleteEmplacement"
  v-on:dismissAlert="toggleDeleteEmplacementModal">
  </TextAlertModal>

</template>

<script>
import $ from 'jquery';
import Leaflet from 'leaflet';
import { MarkerClusterGroup } from 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import MachinesComponent from '@/components/machines/MachinesComponent';
import LockersComponent from '@/components/lockers/LockersComponent';
import DevicesComponent from '@/components/devices/DevicesComponent';
import NewEmplacementModal from '@/components/emplacements/NewEmplacementModal';
import EditEmplacementModal from '@/components/emplacements/EditEmplacementModal';
import TextAlertModal from '@/components/other/TextAlertModal';
// Clomos
import api from '@/services/api';

export default {
  name: "EmplacementsComponent",
  components: { 
    MachinesComponent, DevicesComponent, NewEmplacementModal, EditEmplacementModal, TextAlertModal, LockersComponent
  },
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    }
  },
  watch: { 
    currentCompany: async function() {
      await this.getEmplacementList();
      this.onChangeDisplayType(this.displayType);
      if (this.emplacementList[0] != undefined) this.targetEmplacementId = this.emplacementList[0].id;
      else this.targetEmplacementId = "";
      this.setButtonFunctions();
    }
  },
  data() {
    return {
      // MAP
      emplacementsColumns: [
        {text:"Identificador", data: "emplacementId", width: "20%",className:"tx-left align-middle dt-nowrap",
        render: (data) => {
            return "<ion-icon name='copy-outline' data-id="+data+" class='btnCopyId btn tx-18 tx-clomos-midtone'></ion-icon>"
            +"<span data-id="+data+" class='btnTargetEmplacement c-pointer c-pointer tx-bold tx-clomos-midtone text-nowrap align-middle'>"+data+"</span>";
          }
        },
        {text:"Nombre", data: "emplacementLabel", width: "20%",className:"tx-left align-middle",
        render: (data) => {
            return "<span data-id="+data+" class='text-start tx-bold text-nowrap w-100'>"+data+"</span>"
          }
        },
        {text:"Localización", data: "emplacementLocation", width: "20%",className:"tx-left align-middle",
        render: (data) => {
            return "<span data-id="+data+" class='text-start text-nowrap w-100'>"+data+"</span>"
          }
        },
        {text:"Tipo", data: "emplacementType", width: "15%",className:"tx-left align-middle",
        render: (data) => {
            return "<span data-id="+data+" class='text-start text-nowrap w-100'>"+data+"</span>"
          }
        },
        {text:"Coordenadas", data: "emplacementGeoJSON", width: "15%",className:"tx-left align-middle",
        render: (data) => {
            if (data.geometry!=undefined && data.geometry.type==="Point") return "<div class='d-flex pt-1'><span class='me-2 ms-2 tx-black'>Lat:</span> <span>"+Number(data.geometry.coordinates[0]).toFixed(3)+"</span> <span class='me-2 ms-2 tx-black'>Lon:</span> <span>"+Number(data.geometry.coordinates[1]).toFixed(3) 
            else return "<span class='me-2 ms-2 tx-primary-adn'>Lat:</span> <span>"+0+"</span>  <span class='me-2 ms-2 tx-black'> Lon:</span></div> "+0
          }
        },
        {text:"Editar", data: "emplacementId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='create-outline' data-id="+data+" class='btnEditEmplacement btn tx-24 p-0 tx-16 tx-primary-adn'></ion-icon>"
          }
        },
        {text:"Eliminar", data: "emplacementId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='trash-outline' data-id="+data+" class='btnDeleteEmplacement btn tx-24 p-0 tx-16 text-danger'></ion-icon>"
          }
        }
      ],
      targetEmplacementId: this.$route.params.targetEntity,
      emplacementList: [],
      emplacementsMap: undefined,
      displayType : 'map',
      emplacementCardsPage: 0,
      display: {
        newEmplacementModal: false,
        editEmplacementModal: false,
        deleteEmplacementModal: false
      }
    }
  },
  computed: {
    viewpoint() {
      let viewpoint = {
        center: {lng: 0, lat:0},
        zoom: 5,
      }
      let l = undefined;
      let r = undefined;
      let t = undefined;
      let b = undefined;
      if (this.emplacementList[1] != undefined) { // Multiple emplacements
        for (let i in this.emplacementList) if (this.emplacementList[i].geoJSON.geometry!=undefined && this.emplacementList[i].geoJSON.geometry.type==="Point") {
          if (this.emplacementList[i].geoJSON.geometry.coordinates[0] >= t || t === undefined) {
            t = Number(this.emplacementList[i].geoJSON.geometry.coordinates[0]) 
          }
          if(this.emplacementList[i].geoJSON.geometry.coordinates[0] <= b || b === undefined) {
            b = Number(this.emplacementList[i].geoJSON.geometry.coordinates[0]);
          }
          if (this.emplacementList[i].geoJSON.geometry.coordinates[1] >= r || r === undefined) {
            r = Number(this.emplacementList[i].geoJSON.geometry.coordinates[1]) 
          }
          if(this.emplacementList[i].geoJSON.geometry.coordinates[1] <= l || l=== undefined) {
            l = Number(this.emplacementList[i].geoJSON.geometry.coordinates[1]);
          }
        }
        // CENTER
        viewpoint.center.lng = (r+l)/2;
        viewpoint.center.lat = (t+b)/2;
      }
      else if (this.emplacementList[0] != undefined) { // Single emplacement
        viewpoint.center = {lng: parseFloat(this.emplacementList[0].geoJSON.geometry.coordinates[0]), lat:parseFloat(this.emplacementList[0].geoJSON.geometry.coordinates[1])};
      }
      return viewpoint;
    },
    emplacementCardsByPages(){
      let emplacementCardsByPages = []
      let i = 0; 
      while (i*12 < this.emplacementList.length) {
        emplacementCardsByPages.push(this.emplacementList.slice(i*12,i*12+12));
        i++;
      }
      return emplacementCardsByPages;
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({params: {currentCompanyNifCif: value}})
      this.$emit('setCurrentCompany',value);
    },
    onChangeDisplayType(type) {
      this.displayType = type;
      setTimeout(()=>{
        if (type === "map") this.displayEmplacementsMap();
        if (type === "table") this.displayEmplacementsDataTable();
        this.setButtonFunctions();
      }, 100);
    },
    onSetCardPage(index) {
      this.emplacementCardsPage = index;
      this.setButtonFunctions();
    },
    displayEmplacementsDataTable() {
      $("#emplacementsDataTable").DataTable().clear()
      for (let i in this.emplacementList) {
        $("#emplacementsDataTable").DataTable().rows.add([{
          emplacementId: this.emplacementList[i].id, 
          emplacementLabel: this.emplacementList[i].label, 
          emplacementLocation: this.emplacementList[i].location, 
          emplacementType: this.emplacementList[i].type, 
          emplacementGeoJSON: this.emplacementList[i].geoJSON,
        }]);
      }
      $("#emplacementsDataTable").DataTable().draw();
      this.setButtonFunctions();
      if($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn)=> { btn.addEventListener('click', this.setButtonFunctions)});
    },
    displayEmplacementsMap() {
      if (this.emplacementsMap != undefined) {
        this.emplacementsMap.invalidateSize();
        this.emplacementsMap.remove();
      }

      var emplacementsMap = Leaflet.map("emplacementsMapContent");
      Leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png?lang='+this.session.user.language,{maxZoom: 19}).addTo(emplacementsMap);
      
      var siteMarkersGroup = new MarkerClusterGroup();
      emplacementsMap.addLayer(siteMarkersGroup);

      let emplacementsMapListForGroup = []; 
      siteMarkersGroup.addLayers(this.emplacementList.map(e => {
        let options = { title: e.label, clickable: true, draggable: false };
        var marker = Leaflet.marker([e.geoJSON.geometry.coordinates[0],e.geoJSON.geometry.coordinates[1]], options);
        marker.on("click", this.setButtonFunctions);
        marker.bindPopup('<div class="d-flex flex-column" style="min-width: 9rem;">'
        +'<span data-id="' + e.id + '" class="btnTargetEmplacement c-pointer tx-bold tx-grey m-0 p-0">' + e.label + '</span>'
        +'<div class="m-0 p-0 d-flex flex-row justify-content-between">'
          +'<text class="w-100">' + e.location + '</text>'
          +'<ion-icon name="create-outline" data-id="' + e.id + '" class="btnEditEmplacement col-2 m-0 mt-auto ps-1 p-0 btn tx-24 tx-primary-adn float-end"></ion-icon>'
          +'<ion-icon name="trash-outline" data-id="' + e.id + '" class="btnDeleteEmplacement col-2 m-0 mt-auto p-0 btn tx-24 tx-danger float-end"></ion-icon>'
        +'</div></div>');
        emplacementsMapListForGroup.push(marker);
        return marker;
      }))
      if (emplacementsMapListForGroup[0] != undefined) {
        var emplacementsMapGroup = Leaflet.featureGroup(emplacementsMapListForGroup);
        emplacementsMap.fitBounds(emplacementsMapGroup.getBounds());
      } else {
        emplacementsMap.setView(new Leaflet.LatLng(40.426, -3.7034), 10);
      }

      if ($(".leaflet-control-attribution").get()[0] != undefined) $(".leaflet-control-attribution").get()[0].remove();
      
      this.emplacementsMap = emplacementsMap;
    },
    async toggleNewEmplacementModal() {
      if (this.display.newEmplacementModal) {
        await this.getEmplacementList();
        this.onChangeDisplayType(this.displayType);
      }
      this.display.newEmplacementModal = !this.display.newEmplacementModal;
    },
    async toggleEditEmplacementModal() {
      if (this.display.editEmplacementModal) {
        await this.getEmplacementList();
        this.onChangeDisplayType(this.displayType);
        this.editRequest = false;
      }
      this.display.editEmplacementModal = !this.display.editEmplacementModal;
    },
    async toggleDeleteEmplacementModal() {
      if (this.display.deleteEmplacementModal) {
        await this.getEmplacementList();
        this.onChangeDisplayType(this.displayType);
        if (this.emplacementList[0] != undefined) this.targetEmplacementId = this.emplacementList[0].id;
        else this.targetEmplacementId = "";
      }
      this.display.deleteEmplacementModal = !this.display.deleteEmplacementModal;
    },
    displayMyEmplacementImage(src) {
      if (src!=undefined && src!='') { 
        try {
          return this.$clientConfig.api + '/ficheros/ficheros.php?fichero='+src+'&identificadorusuario='+this.session.user.id+'&Token='+localStorage.getItem("ClomosAuthToken");
        }
        catch(e) {
          console.warn(e);
        }
      }
      return require('/src/assets/img/placeholder_image.jpg');
    },
    // BUTTONS 
    setButtonFunctions() {
      setTimeout(()=>{
        $(".btnTargetEmplacement").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetEmplacement);
        });
        $(".btnShowEmplacement").get().forEach(btn => {
          btn.addEventListener('click', this.onShowEmplacement);
        });
        $(".btnEditEmplacement").get().forEach(btn => {
          btn.addEventListener('click', this.onEditEmplacement);
        });
        $(".btnDeleteEmplacement").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteEmplacement);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    // GET
    async getEmplacementList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getEmplacementList(request);
      this.emplacementList = api.parseEmplacementsFromResponse(response);
      if (callback && typeof callback === "function") callback(this.emplacementList);
    },
    // TARGET 
    onTargetEmplacement(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetEmplacementId = event.target.getAttribute('data-id')
      this.$router.replace({params: {targetEntity: this.targetEmplacementId}});
    },
    // SHOW
    onShowEmplacement(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetEmplacementId = event.target.getAttribute('data-id');
      this.$router.replace({params: {targetEntity: this.targetEmplacementId}});
      this.editRequest = false;
      this.toggleEditEmplacementModal();
    },
    // SET
    onEditEmplacement(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetEmplacementId = event.target.getAttribute('data-id');
      this.$router.replace({params: {targetEntity: this.targetEmplacementId}});
      this.editRequest = true;
      this.toggleEditEmplacementModal();
    },
    // DELETE
    async onDeleteEmplacement(event) {
      if (!this.display.deleteEmplacementModal) {
        console.log('DELETE WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetEmplacementId = event.target.getAttribute('data-id');
        this.$router.replace({params: {targetEntity: this.targetEmplacementId}});
      }
      else {
        let response = await api.deleteEmplacement(this.targetEmplacementId);
        console.log(response);
        }
      await this.toggleDeleteEmplacementModal();
    }
  },
  async created() {
    await this.getEmplacementList()
    this.$emit('getAvailableCompanies',this.onChangeDisplayType(this.displayType));
    if (this.emplacementList[0] != undefined && this.targetEmplacementId === "") this.targetEmplacementId = this.emplacementList[0].id;
    this.setButtonFunctions();
  },
  mounted() {
    $("#emplacementsDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.emplacementsColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    )
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>

<style>
.btonCopyId:hover, .btnTargetEmplacement:hover, .btnEditEmplacement:hover, .btnDeleteEmplacement:hover {
  color:#FED54F  !important;
}

.leaflet-popup-content {
  margin: 0 !important;
  padding: 0.5rem !important;
}
.tx-primary-adn{
  color:#7D8235;
}
</style>