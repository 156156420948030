<template>
    <div class="row col-12 p-2 m-0 section-container justify-content-center">

<!-- HEAD -->
      <div class="col-lg-2 col-md-4 col-12 p-2">
        <div class="col-12 m-0 p-0 text-black border border-black rounded-5 " >
          <div class="col-12 m-0 p-0 ps-3 pe-3 overflow-hidden justify-content-center">
<!--
            <ion-icon v-if="session.user.bookmark==='companies'" name="bookmark" class="bookmark-button"></ion-icon>
            <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>
-->
            <h6 class="tx-left tx-clomos-black text-center mt-2 mb-2 ps-1 pt-1">
              <ion-icon name="lock-open-outline" class="tx-18 me-2 align-bottom"></ion-icon>
              {{dictionary["Contraseña"][session.user.language]}}
            </h6>
            <hr class="tx-white m-0 p-0 bg-clomos-black" >
          </div>

          <form class="rounded">
            <div class="container-fluid m-0 p-0">
              <div class="row m-0 p-0 justufy-content-center">

<!-- CONTRASEÑA -->
                <div class="col-12 m-0 p-0 ps-3 pe-3">
                  <label class="col-12 p-0 pt-2 tx-14 tx-black text-nowrap">
                    {{dictionary['Contraseña anterior'][session.user.language]}}
                    </label>
                  <div class="col-12 m-0 p-0">
                    <input v-model="formChangePass.oldPassword" id="inputMyOldPass" type="password" autocomplete="off" 
                    class="form-control" style="color:black; background-color: #EEEEEE">
                  </div>
                </div>

<!-- NUEVA-CONTRASEÑA -->
                <div class="col-12 m-0 p-0 ps-3 pe-3">
                  <label class="col-12 p-0 pt-2 tx-14 tx-black text-nowrap">
                    {{dictionary['Nueva contraseña'][session.user.language]}}
                  </label>
                  <div class="col-12 m-0 p-0">
                    <input v-model="formChangePass.newPassword" id="inputMyNewPass" type="password" autocomplete="off" 
                    class="form-control" style="color:black; background-color: #EEEEEE">
                  </div>
                </div>

<!-- RE-NUEVA-CONTRASEÑA -->
                <div class="col-12 m-0 p-0 ps-3 pe-3">
                  <label class="col-12 p-0 pt-2 tx-14 tx-black text-nowrap">
                    {{dictionary['Repite la contraseña'][session.user.language]}}
                  </label>
                  <div class="col-12 m-0 p-0">
                    <input v-model="formChangePass.newPasswordRe" id="inputMyNewPassRe" type="password" autocomplete="off" 
                    class="form-control form-conwhitetrol-line" style="color:black; background-color: #EEEEEE">
                  </div>
                </div>

                <div class="col-12 p-0">
                  <div class="col-12 p-3">
                    <button id="btnGuardarPass" type="button" class="button-ok bg-clomos-black"  
                    @click="onChangePassword">
                      <span>
                        <span class="button-text text-white">
                          <ion-icon name="key-outline" class="text-white pt-0 me-2 tx-18 hydrated"></ion-icon>
                          <span class="text-white text-nowrap mt-0">{{dictionary["Restablecer contraseña"][session.user.language]}}</span>
                        </span>
                      </span>
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </form>
        </div>

        <div class="col-12 m-0 p-0 text-black rounded-5 border border-black mt-3">
<!-- FORM Company Logo-->
          <div class="row m-0 p-0 justify-content-center overflow-hidden">
            <div class="p-3">
              <label class="col-12 m-0 p-0 tx-black tx-14 text-nowrap text-capitalize">
                {{dictionary["Logotipo de la empresa"][session.user.language]}}
              </label>
              <hr class="text-white mt-2 mb-0">
              <label class="col-12 m-0 p-0 tx-black tx-light text-nowrap tx-12">
                {{dictionary["Tamaño recomendado"][session.user.language]+': 200x200px'}}
              </label>

              <img id="logo" class="w-100" style="max-width: 200px; width: auto; padding: 1rem;" :src="displayMyCompanyImage(myCompanyData.logoSrc)">

              <div id="logoUploadFormContainer" class="w-100 m-0 p-0 col-12" style="display: flex; flex-direction: column; justify-content: space-around; gap: 1rem;">
                <input type="file" id="logoUploadSrc" class="w-100 file-button btn btn-secondary rounded" style="height: 3rem;"
                @change="onChangeMyCompanyImage" accept=".png">

                <div v-if="myCompanyImageForm.file!=''" class="myCompanyImagePreview">
                  <div class="d-flex flex-column">
                    <button type="button" @click="onRemoveMyNewCompanyImage()" title="Remove file" style="line-height: 0; width: 25px; height: 25px" class="p-1 m-0 bg-clomos-white rounded ms-auto">
                      <ion-icon name="close" class="tx-18 tx-black align-bottom"></ion-icon>
                    </button>

                    <img class="myCompanyImagePreview pt-3" :src="displayMyCompanyThumbnail(myCompanyImageForm.file)" />
                  </div>
                </div>

                <button type="button" id="logoUploadSubmit" class="m-auto button-ok bg-clomos-black"
                @click="setMyCompanyImage">
                  <span class="button-text text-white">
                    <ion-icon name="image-outline" class="text-white pt-0 me-2 tx-18 hydrated"></ion-icon>
                    <span class="text-white text-nowrap mt-0">{{dictionary["Guardar archivo"][session.user.language]}}</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>


<!-- ROW 2 -->
      <div class="row col-lg-6 col-md-8 col-12 h-max m-0 p-2 overflow-auto rounded-end-5 justify-content-center">
        <div class="col-12 bg-caja rounded-5 bg-caja m-0 p-0 rounded-5">
          <div class="col-12 m-0 pt-3 pb-3 mb-2 bg-clomos-black text-white rounded-top-5 border-1 pb-1">
            <ion-icon name="person-outline" class="tx-16 mb-1 me-2 align-bottom"></ion-icon>
            {{dictionary["Mi perfil"][session.user.language]}}
          </div>
          <form class="rounded-bottom-3 p-0 tx-14">
              <div class="container-fluid m-0 p-0 p-3 pt-0">
                <div class="row">
<!-- MI NOMBRE -->
                  <div class="col-md-6 col-12">
                    <label class="col-12 p-0 pt-2 tx-14">
                      {{dictionary['Nombre'][session.user.language]}}
                    </label>
                    <div class="col-12 m-0 p-0">
                      <input v-model="myProfileData.name" id="inputMyUserName" type="text" 
                      class="form-control" style="color:black;">
                    </div>
                  </div>

<!-- MI APELLIDOS -->
                  <div class="col-md-6 col-12">
                    <label class="col-12 p-0 pt-2 tx-14">
                      {{dictionary['Apellidos'][session.user.language]}}
                    </label>
                    <div class="col-12 m-0 p-0">
                      <input v-model="myProfileData.lastName" id="inputMyUserLastName" type="text" 
                      class="form-control" style="color:black;">
                    </div>
                  </div>

                </div>
              
                <div class="row">
                
<!-- MI CORREO -->
                  <div class="col-12">
                    <label class="col-12 p-0 pt-2 tx-14">
                      {{dictionary['Correo electrónico'][session.user.language]}}
                    </label>
                    <div class="col-12 m-0 p-0">
                      <input v-model="myProfileData.email" id="inputMyUserEmail" type="text" 
                      class="form-control" style="color:black;" disabled>
                    </div>
                  </div>
                
                </div>

                
<!-- MI CORREO RE -->
<!--
                <div class="row">
                  <div class="col-md-6 col-12">
                    <label class="col-12 p-0 pt-2 tx-14">
                      {{dictionary['Repetir correo'][session.user.language]}}
                    </label>
                    <div class="col-12 m-0 p-0">
                      <input v-model="myProfileData.emailRe" id="inputMyUserEmailRe" type="text" 
                      class="form-control" style="color:black;">
                    </div>
                  </div>
                
                </div>
-->
                <div class="row">

<!-- MI ROL -->
                  <div class="col-md-6 col-12">
                    <label class="col-12 p-0 pt-2 tx-14">
                      {{dictionary['Rol'][session.user.language]}}
                    </label>
                    <div class="col-12 m-0 p-0">
                      <select v-model="myProfileData.rol"
                      id="selectMyUserRol" disabled
                      class="form-control" style="color:black;">
                      <option value="255"> 
                            {{assignRolName("255")}}
                          </option>
                          <option value="128">
                            {{assignRolName("128")}}
                          </option>
                          <option value="32">
                            {{assignRolName("32")}}
                          </option>
                          <option value="2">
                            {{assignRolName("2")}}
                          </option>
                          <option value="1">
                            {{assignRolName("1")}}
                          </option>
                      </select>
                    </div>
                  </div>

<!-- MI TELÉFONO -->
                  <div class="col-md-6 col-12">
                    <label class="col-12 p-0 pt-2 tx-14">
                      {{dictionary['Teléfono'][session.user.language]}}
                    </label>
                    <div class="col-12 m-0 p-0">
                      <input v-model="myProfileData.telephone" id="inputMyUserTelephone"
                      class="form-control" style="color:black;">
                    </div>
                      
                  </div>
                
                </div>

                <div class="row">

<!-- MI PROVINCIA -->
                  <div class="col-md-6 col-12">
                    <label class="col-12 p-0 pt-2 tx-14">
                      {{dictionary['Provincia'][session.user.language]}}
                    </label>
                    <div class="col-12 m-0 p-0">
                      <input v-model="myProfileData.province" id="inputMyUserProvince" type="text" 
                      class="form-control" style="color:black;">
                    </div>
                  </div>

<!-- MI PAÍS -->
                  <div class="col-md-6 col-12">
                    <label class="col-12 p-0 pt-2 tx-14">
                      {{dictionary['País'][session.user.language]}}
                    </label>
                    <div class="col-12 m-0 p-0">
                      <select v-model="myProfileData.country"
                      id="selectMyUserCountry"
                      class="form-control" style="color:black;">
                        <option v-for="country in countries" :key="country['code']" :value="country['code']">
                          {{country['name'][session.user.language]}}
                        </option>
                      </select>
                    </div>
                  </div>

                </div>

                <div class="row">

<!-- DIRECCIÓN -->
                  <div class="col-md-6 col-12">
                    <label class="col-12 p-0 pt-2 tx-14">
                      {{dictionary['Dirección'][session.user.language]}} 1
                    </label>
                    <div class="col-12 m-0 p-0">
                      <textarea v-model="myProfileData.adress1" id="inputMyUserAdress1" name="adress" rows="1"
                      class="form-control" style="color: black; padding: 0.7rem;"/> 
                    </div>
                  </div>

                  <div class="col-md-6 col-12">
                    <label class="col-12 p-0 pt-2 tx-14">
                      {{dictionary['Dirección'][session.user.language]}} 2
                    </label>
                    <div class="col-12 m-0 p-0">
                      <textarea v-model="myProfileData.adress2" id="inputMyUserAdress2" name="adress" rows="1"
                      class="form-control" style="color: black; padding: 0.7rem;"/> 
                    </div>
                  </div>
                
                </div>

                <div class="col-12 m-0 p-0">
                  <hr class="tx-clomos-dark">
                </div>

                <div class="row">

                  <div class="col-md-6 col-12 pt-0">
                    <label class="col-12 p-0 pt-2 tx-16 tx-white tx-bold">
                      {{dictionary['Preferencias'][session.user.language]}}:
                    </label>
                  </div>
                  
                </div>

                <div class="row">

<!-- MI IDIOMA -->
                  <div class="col-md-6 col-12">
                    <label class="col-12 p-0 pt-2 tx-14">
                      {{dictionary['Idioma'][session.user.language]}}
                    </label>
                    <div class="col-12 m-0 p-0">
                      <select v-model="myProfileData.language"
                      id="selectMyUserLanguage"
                      class="form-control" style="color:black;">
                        <option value="es">
                          {{dictionary['Español'][session.user.language]}}
                        </option>
                        <option value="en">
                          {{dictionary['Inglés'][session.user.language]}}
                        </option>
                        <option value="eus">
                          {{dictionary['Euskera'][session.user.language]}}
                        </option>
                      </select>
                    </div>
                  </div>

<!-- MI SEPARADOR DE COMAS CSV -->
                  <div class="col-md-6 col-12">
                    <label class="col-12 p-0 pt-2 tx-14">
                      {{dictionary['Separador de comas CSV'][session.user.language]}}
                    </label>
                    <div class="col-12 m-0 p-0">
                      <select v-model="myProfileData.fieldSeparator"
                      id="selectMyFieldSeparator"
                      class="form-control" style="color:black;">
                        <option value=",">
                          {{dictionary['Coma'][session.user.language]}} ","
                        </option>
                        <option value=";">
                          {{dictionary['Punto y coma'][session.user.language]}} ";"
                        </option>
                      </select>
                    </div>
                  </div>

                </div>

                <div class="row">

<!-- MI TEMPERATURA -->
                  <div class="col-md-6 col-12">
                    <label class="col-12 p-0 pt-2 tx-14">
                      {{dictionary['Temperatura'][session.user.language]}}
                    </label>
                    <div class="col-12 m-0 p-0">
                      <select v-model="myProfileData.unitTemperature"
                      id="selectMyUnitTemperature"
                      class="form-control" style="color:black;">
                        <option value="C">Cº (Celsius)</option>
                        <option value="F">Fº (Fahrenheit)</option>
                      </select>
                    </div>
                  </div>
                  
<!-- MI FORMATO -->
                  <div class="col-md-6 col-12">
                      <label class="col-12 p-0 pt-2 tx-14">
                        {{dictionary['Formato'][session.user.language]}}
                      </label>
                      <div class="col-12 m-0 p-0">
                        <select v-model='myProfileData.dateTimeFormat'
                        id="selectMyDateTimeFormat"
                        class="form-control" style="color:black;">
                          <option value="DD/MM/YYYY">DD/MM/YYYY</option>
                          <option value="MM/DD/YYYY">MM/DD/YYYY</option>
                        </select>
                      </div>
                    </div>

                </div>

                <div class="row">

<!-- MI ZONA HORARIA -->
                  <div class="col-md-6 col-12">
                    <label class="col-12 p-0 pt-2 tx-14">
                      {{dictionary['Zona horaria'][session.user.language]}}
                    </label>
                    <div class="col-12 m-0 p-0">
                      <select v-model="myProfileData.dateTimeZone"
                      id="selectMyDateTimeZone"
                      class="form-control" style="color:black;">
                        <option v-for="timeZone in timeZones" :key="timeZone['value']" :value="timeZone['value']">
                          {{timeZone['value'].replace("_"," ")}}
                        </option>
                      </select>
                    </div>
                  </div>

<!-- MI ZONA HORARIA -->
                  <div class="col-md-6 col-12">
                    <label class="col-12 p-0 pt-2 tx-14">
                      {{dictionary['Longitud decimal'][session.user.language]}}
                    </label>
                    <div class="col-12 m-0 p-0">
                      <select v-model="myFractionalLength"
                      @change="onSetMyFractionalLength(myFractionalLength)"
                      id="selectMyFractionalLength"
                      class="form-control" style="color:black;">
                        <option :value="0">0</option>
                        <option :value="1">1</option>
                        <option :value="2">2</option>
                        <option :value="3">3</option>
                        <option :value="4">4</option>
                        <option :value="5">5</option>
                      </select>
                    </div>
                  </div>

                </div>

                <div class="row">


                  <div class="col-12">
                    <div class="col-12 mt-3">
<!-- SUBMIT -->
                      <button type="button" @click="setMyUserData"
                      class="button-ok bg-clomos-black" id="btnGuardarPerfil">
                        <span class="button-text text-white">
                          <ion-icon name="create-outline" class="text-white pt-0 me-2 tx-18 hydrated"></ion-icon>
                          <span class="text-white text-nowrap mt-0">{{dictionary['Actualizar perfil'][session.user.language]}}</span>
                        </span>
                      </button>
                    </div>
                  </div>

                </div>
<!-- FORM My Profile (End) -->
                
              </div>
            </form>        
        </div>
      </div>

<!-- ROW 3 -->
      <div class="row col-lg-4 col-md-12 col-12 h-max m-0 p-2 overflow-auto rounded-end-5 justify-content-center">
        <div class="col-12 bg-caja rounded-5 bg-caja m-0 p-0 rounded-5 overflow-hidden">
          <div class="col-12 m-0 pt-3 pb-3 mb-2 bg-clomos-black text-white rounded-top-5 border-1 pb-1">
            <ion-icon name="briefcase-outline" class="tx-16 mb-1 me-2 align-bottom"></ion-icon>
            {{dictionary["Mi empresa"][session.user.language]}}
          </div>
          <form class="rounded-bottom-3 p-3 pt-0">
            <div class="row">
              <div class="col-md-6 col-12">

<!-- NIF/CIF MI COMPAÑÍA-->
                <div class="col-12 m-0 p-0">
                  <label class="col-12 p-0 pt-2 tx-14">
                    {{dictionary['NIF/CIF'][session.user.language]}}
                  </label>
                  <div class="col-12 m-0 p-0">
                    <input v-model="myCompanyData.nifCif" disabled
                    id="nifCif" type="text"
                    class="form-control" style="color:black;">
                  </div>
                </div>

              </div>

              <div class="col-md-6 col-12">

<!-- NOMBRE MI COMPAÑÍA-->
                <div class="col-12 m-0 p-0">
                  <label class="col-12 p-0 pt-2 tx-14">
                    {{dictionary['Nombre de la empresa'][session.user.language]}}
                  </label>
                  <div class="col-12 m-0 p-0">
                    <input v-model="myCompanyData.name" :disabled="Number(session.user.rol)<128"
                    id="inputMyCompanyName" type="text"
                    class="form-control" style="color:black;">
                  </div>
                </div>

              </div>
            </div>

            <div class="row">

              <div class="col-md-6 col-12">

<!-- DIRECCIÓN MI COMPAÑÍA-->
                <div class="col-12 m-0 p-0">
                  <label class="col-12 p-0 pt-2 tx-14">
                    {{dictionary['Dirección'][session.user.language]}}
                  </label>
                  <div class="col-12 m-0 p-0">
                    <input v-model="myCompanyData.adress" :disabled="Number(session.user.rol)<128"
                    id="inputMyCompanyAdress" type="text"
                    class="form-control" style="color:black;">
                  </div>
                </div>

              </div>
            
              <div class="col-md-6 col-12">

<!-- CORREO MI COMPAÑÍA-->
                <div class="col-12 m-0 p-0">
                  <label class="col-12 p-0 pt-2 tx-14">
                    {{dictionary['E-mail'][session.user.language]}}
                  </label>
                  <div class="col-12 m-0 p-0">
                    <input v-model="myCompanyData.email" :disabled="Number(session.user.rol)<128"
                    id="inputMyCompanyEmail" type="text"
                    class="form-control" style="color:black;">
                  </div>
                </div>

              </div>
            </div>

            <div class="row">

              <div class="col-md-6 col-12">

<!-- TELÉFONO MI COMPAÑÍA-->
                <div class="col-12 m-0 p-0">
                  <label class="col-12 p-0 pt-2 tx-14">
                    {{dictionary['Teléfono'][session.user.language]}}
                  </label>
                  <div class="col-12 m-0 p-0">
                    <input v-model="myCompanyData.telephone" :disabled="Number(session.user.rol)<128"
                    id="inputMyCompanyTelephone" type="text"
                    class="form-control" style="color:black;">
                  </div>
                </div>

              </div>
<!-- PAÍS -->
              <div class="col-md-6 col-12">

                <div class="col-12 m-0 p-0" style="text-align: left;">
                  <label class="col-12 p-0 pt-2 tx-14">
                    {{dictionary["País"][session.user.language]}}
                  </label>
                  <div class="col-12 m-0 p-0">
                    <select v-model="myCompanyData.country" id="selectMyCompanyCountry" :disabled="Number(session.user.rol)<128"
                    class="form-control"  style="color: black;">
                      <option v-for="country in countries" :key="country['code']" :value="country['code']">
                        {{country['name'][session.user.language]}}
                      </option>
                    </select>
                  </div>
                </div>

              </div>
            </div>

            <div class="row">

<!-- SECTOR -->
              <div class="col-12">

                <div class="col-12 m-0 p-0" style="text-align: left;">								
                  <label class="col-12 p-0 pt-2 tx-14">
                    {{dictionary["Sector"][session.user.language]}}
                  </label>
                  <div class="col-12 m-0 p-0">
                    <select v-model="myCompanyData.sector" id="selectMyCompanySector" :disabled="Number(session.user.rol)<128"
                    class="form-control" style="color: black;">
                      <option value="aeronautica">
                        {{dictionary["Aeronáutica"][session.user.language]}}
                      </option>
                      <option value="agroalimentario">
                        {{dictionary["Agroalimentario"][session.user.language]}}
                      </option>
                      <option value="automotriz">
                        {{dictionary["Automotriz"][session.user.language]}}
                      </option>
                      <option value="construccion">
                        {{dictionary["Obra civil"][session.user.language]}}
                      </option>
                      <option value="quimica">
                        {{dictionary["Química"][session.user.language]}}
                      </option>
                      <option value="militar">
                        {{dictionary["Defensa/Militar"][session.user.language]}}
                      </option>
                      <option value="electronica">
                        {{dictionary["Electrónica"][session.user.language]}}
                      </option>
                      <option value="energia">
                        {{dictionary["Energía"][session.user.language]}}
                      </option>
                      <option value="it">
                        {{dictionary["Tecnologías de la información"][session.user.language]}}
                      </option>
                      <option value="industria 4.0">
                        {{dictionary["Industria 4.0"][session.user.language]}}
                      </option>
                      <option value="farma-medico">
                        {{dictionary["Farma-médico"][session.user.language]}}
                      </option>
                      <option value="logistica">
                        {{dictionary["Transporte y logística"][session.user.language]}}
                      </option>
                      <option value="almacenamiento">
                        {{dictionary["Manejo y almacenamiento de materiales"][session.user.language]}}
                      </option>
                      <option value="canteras">
                        {{dictionary["Canteras, cemento, mármoles"][session.user.language]}}
                      </option>
                      <option value="papeleras">
                        {{dictionary["Fábricas de papel y celulosa"][session.user.language]}}
                      </option>
                      <option value="gas">
                        {{dictionary["Petróleo y gas"][session.user.language]}}
                      </option>
                      <option value="puertos">
                        {{dictionary["Industria Portuaria"][session.user.language]}}
                      </option>
                      <option value="puertos deportivos">
                        {{dictionary["Puertos deportivos"][session.user.language]}}
                      </option>
                      <option value="astilleros">
                        {{dictionary["Astilleros"][session.user.language]}}
                      </option>
                      <option value="telecomunicaciones">
                        {{dictionary["Telecomunicaciones"][session.user.language]}}
                      </option>
                      <option value="hierro y acero">
                        {{dictionary["Hierro y acero"][session.user.language]}}
                      </option>
                      <option value="reciclaje">
                        {{dictionary["Tratamiento de residuos/Reciclaje"][session.user.language]}}
                      </option>
                      <option value="otro">
                        {{dictionary["Otro"][session.user.language]}}
                      </option>
                    </select>
                  </div>
                </div>
                
              </div>

              <div v-if="Number(session.user.rol)>128" class="form-group col-12" style="padding:1rem 0.8rem 0;">
<!-- SUBMIT -->
                <button id="btnEditMyCompany" type="button" class="button-ok bg-clomos-black" @click="setMyCompanyData">
                  <span class="button-text text-white">
                    <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-18 hydrated"></ion-icon>
                    <span class="text-white text-nowrap mt-0">{{dictionary["Editar compañía"][session.user.language]}}</span>
                  </span>
                </button>
              </div>
              
            </div>
          </form>

          <div class="col-12 m-0 p-3 pt-0">
            <hr class="tx-clomos-dark m-0 p-0">
          </div>
          
<!--Mis huecos-->
          <div v-show="availableSlotTypes!=undefined" class="col-12 p-3 pt-0 m-0">                 
            <div class="col-12 row">
              <label class="col-12 p-0 pt-2 tx-clomos-dark text-nowrap tx-14 text-bold">
                {{dictionary['Huecos habilitados'][session.user.language]}}:
              </label>
            </div>

            <div class="col-12 m-0 p-0"> 
              <div v-for="availableSlots, slotType in availableSlotTypes" :key="slotType" class="row m-0 p-0"> 
                <div class="border-clomos rounded p-2 col-12 col-lg-3 m-auto">
                  <div class="tx-bold tx-14 overflow-hidden">
                    <span class="ps-1">{{slotType}}</span>
                  </div>
                </div>
                <div class="col-12 col-lg-3">
                  <label class="col-12 p-0 pt-2 tx-14 tx-bold text-nowrap p-0">
                    <span class="ps-1 small">{{dictionary['Dimensiones'][session.user.language]}} <span class="ps-1 small">(mm)</span></span>
                  </label>
                  <p class="ps-1 tx-10 ps-1">
                  {{boxTypes.filter(boxType => boxType.id === slotType)[0].volume.width + " x " + boxTypes.filter(boxType => boxType.id === slotType)[0].volume.height + " x " + boxTypes.filter(boxType => boxType.id === slotType)[0].volume.depth}}</p>
                </div>
                <div class="col-12 col-lg-3">
                  <label class="col-12 p-0 pt-2 tx-14 tx-bold text-nowrap pb-0">
                    <span class="ps-1 small">{{dictionary['Asignados'][session.user.language]}}</span>
                  </label>
                  <p class="ps-1 tx-10 ps-1">{{availableSlotTypes[slotType].huecosAsignados}}</p>
                </div>
                <div class="col-12 col-lg-3">
                  <label class="col-12 p-0 pt-2 tx-14 tx-bold text-nowrap pb-0">
                    <span class="ps-1 small">{{dictionary['Ocupados'][session.user.language]}}</span>
                  </label>
                  <p class="ps-1 tx-10 text-danger tx-bold ps-1">{{availableSlotTypes[slotType].huecosOcupados}}</p>
                </div>
              </div>
            </div>

          </div>

      </div>
    </div>
  </div>

</template>

<script>
import $ from 'jquery';
import boxTypesJson from "/src/assets/json/store_box_types.json";
import availableModulesJSON  from '@/assets/json/rol_name_types'
// Clomos
import api from '@/services/api';

export default {
  name: "ProfileComponent",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    }
  },
  watch: { 
    currentCompany: async function(oldCompany, newCompany) {
      if (oldCompany.nifCif != newCompany.nifCif) {
        this.getMyProfileData();
        await this.getMyCompanyData();
      }
    }
  },
  data() {
    return {
      availableModules:availableModulesJSON,
      userRol:[],
      formChangePass: {
        oldPassword: "",
        newPassword: "",
        newPasswordRe: ""
      },
      myProfileData: {
        id: "",
        name: "",
        lastName: "",
        email: "",
        emailRe: "",
        adress1: "",
        adress2: "",
        province: "",
        country: "",
        telephone: "",
        rol: "",
        language: "",
        unitTemperature: "",
        unitDistance: "",
        dateTimeZone: "",
        dateTimeFormat: "",
        fieldSeparator: ""
      },
      myCompanyData: {
        nifCif: "",
        name: "",
        email: "",
        telephone: "",
        country: "",
        adress: "",
        sector: "",
        logoSrc: ""
      },
      myCompanyImageForm: {
        nifCif: "",
        src: "",
        file: ""
      },
      availableSlotTypes: {},
      boxTypes: JSON.parse(JSON.stringify(boxTypesJson)),
      myFractionalLength: (localStorage.getItem("ClomosFractionalLength")!=undefined)?localStorage.getItem("ClomosFractionalLength"):2
    }
  },
  emits:[ 'getCurrentUserData', 'getCurrentCompanyData' ],
  methods: {
// DISPLAY
    displayMyCompanyImage(src) {
      if (src!=undefined && src!='') {
        try {
          return this.$clientConfig.api + '/ficheros/ficheros.php?fichero='+src+'&identificadorusuario='+this.session.user.id+'&Token='+localStorage.getItem("ClomosAuthToken");
        }
        catch(e) { 
          console.warn(e);
        }
      }
      return require('/src/assets/img/logo-sm.png');
    },
    displayMyCompanyThumbnail(file) {
      if (file === '' || file === undefined) return "";
      let fileSrc = URL.createObjectURL(file);
        setTimeout(() => {
            URL.revokeObjectURL(fileSrc);
        }, 1000);
      return fileSrc;
    },
    onChangeMyCompanyImage(event) {
      if (event.target.files[0] != undefined && event.target.files[0].size> 2097152) {
        alert("El tamaño del fichero debe ser inferior a 2Mb.");
        return;
      }
      this.myCompanyImageForm.nifCif = this.myCompanyData.nifCif;
      this.myCompanyImageForm.file = event.target.files[0];
      this.myCompanyImageForm.src = event.target.files[0].name;
      },
    onRemoveMyNewCompanyImage() {
      $("#logoUploadSrc").get()[0].value="";
      this.myCompanyImageForm.nifCif = "";
      this.myCompanyImageForm.file = "";
      this.myCompanyImageForm.src = "";
    },
// GET MyProfile
    getMyProfileData(callback) {
      this.$emit('getCurrentUserData', () => {
        this.myProfileData = {
          id: this.session.user.id,
          name: this.session.user.name,
          lastName: this.session.user.lastName,
          nifCif: this.session.user.nifCif,
          email: this.session.user.email,
          adress1: this.session.user.adress1,
          adress2: this.session.user.adress2,
          province: this.session.user.province,
          country: this.session.user.country,
          telephone: this.session.user.telephone,
          rol: this.session.user.rol,
          language: this.session.user.language,
          unitTemperature: this.session.user.unitTemperature,
          unitDistance: this.session.user.unitDistance,
          dateTimeZone: this.session.user.dateTimeZone,
          dateTimeFormat: this.session.user.dateTimeFormat,
          fieldSeparator: this.session.user.fieldSeparator,
          fractionalLength: this.session.user.fractionalLength,
        }
      });
      if (callback && typeof callback === "function") callback(this.myProfileData);
    },
// GET MyCompany
    async getMyCompanyData(callback) {
      let response = await api.getCompanyData(this.session.user.nifCif);
      this.myCompanyData = api.parseCompanyFromResponse(response);
      if (callback && typeof callback === "function") callback(this.myCompanyData);
    },
// SET MyPassword
    async onChangePassword() {
      let valid = true; 
      if (this.formChangePass.newPassword != this.formChangePass.newPasswordRe) {
        $("#inputMyNewPass").get()[0].classList.add("missing")
        $("#inputMyNewPassRe").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.formChangePass.oldPassword === "") {
        $("#inputMyOldPass").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.formChangePass.newPassword === "") {
        $("#inputMyNewPass").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.formChangePass.newPasswordRe === "") {
        $("#inputMyNewPassRe").get()[0].classList.add("missing")
        valid = false;
      }
      if (!valid) {
        setTimeout(() => {
          $("#inputMyNewPass").get()[0].classList.remove("missing")
          $("#inputMyNewPassRe").get()[0].classList.remove("missing")
          $("#inputMyOldPass").get()[0].classList.remove("missing")
        }, 3000);
        return;
      }
      let response = await api.setUserPassword(this.formChangePass);
      if (response) {
        this.formChangePass.oldPassword = "";
        this.formChangePass.newPassword = "";
        this.formChangePass.newPasswordRe = "";
        console.log("Password changed.");
      }
    },
// SET MyProfile
    async setMyUserData() {
      let valid = true;
      //if (this.myProfileData.email != this.myProfileData.emailRe
      //|| this.myProfileData.email === ""
      //|| this.myProfileData.emailRe === "") {
      //  $("#inputMyUserEmail").get()[0].classList.add("missing")
      //  $("#inputMyUserEmailRe").get()[0].classList.add("missing")
      //  valid = false;
      //}
      if (this.myProfileData.name === "") {
        $("#inputMyUserName").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.myProfileData.lastName === "") {
        $("#inputMyUserLastName").get()[0].classList.add("missing")
        valid = false;
      }
      if (!valid) {
        setTimeout(() => {
          $("#inputMyUserName").get()[0].classList.remove("missing")
          $("#inputMyUserLastName").get()[0].classList.remove("missing")
          $("#inputMyUserEmail").get()[0].classList.remove("missing")
          $("#inputMyUserEmailRe").get()[0].classList.remove("missing")
        }, 3000);
        return;
      }
      let response = await api.setUserData(this.myProfileData);
      console.log(response);
      if (response["status-code"] === "200") {
        window.alert("El usuario ha sido modificado satisfactoriamente.");
      }
      else if (response["status-code"] === "304") {
        window.alert("El usuario no ha sido modificado.");
      }
      else {
        window.alert("Error al modificar el usuario.");
      }
      this.getMyProfileData();
    },
    async setMyCompanyData() {
      let response = await api.setCompanyData(this.myCompanyData);
      this.$emit('getCurrentCompanyData', this.getMyCompanyData);
      console.log(response);
      if (response["status-code"] === "200") {
        window.alert("La empresa ha sido modificada satisfactoriamente.");
      }
      else if (response["status-code"] === "304") {
        window.alert("La empresa no ha sido modificada.");
      }
      else {
        window.alert("Error al modificar la empresa.");
      }
    },
// SET My profile picture
    async setMyCompanyImage() {
      let response = await api.setCompanyImage(this.myCompanyImageForm);
      console.log(response);
      this.onRemoveMyNewCompanyImage();
      await this.getMyCompanyData();
    },
// SET Fractional length
    onSetMyFractionalLength(value) {
      localStorage.setItem("ClomosFractionalLength",value)
    },
  //FILTER
    async displayRolbyModulues() {
      this.userRol = [];
      let userModules = this.session.authorisation.availableModules;
        for (let moduleName in userModules) {
          for (let moduleRol in this.availableModules[moduleName]) {
            let rolInfo = {
              numero: moduleRol,
              nombre: this.availableModules[moduleName][moduleRol]
            };      
            let existingRolIndex = this.userRol.findIndex(addedRol => addedRol.numero === rolInfo.numero);
            if (existingRolIndex !== -1) {        
              if (!this.userRol[existingRolIndex].nombre.includes(rolInfo.nombre)) {
                this.userRol[existingRolIndex].nombre += "/" + rolInfo.nombre;
              }
            } else {        
              this.userRol.push(rolInfo);
            }
          }
        }
    },
    assignRolName(numberString) {    
      if (!this.userRol || this.userRol.length === 0 ) return numberString;
      const userRole = this.userRol.find(element => numberString === element.numero);
      if (!userRole) return numberString;
      const roleName = this.dictionary[userRole.nombre][this.session.user.language];    
      return roleName;
    }
  },
  async created() {
    this.getMyProfileData();
    await this.getMyCompanyData();
    this.availableSlotTypes = (this.currentCompany != undefined && this.currentCompany.params != undefined) ?this.currentCompany.params.disponibilidadHuecos :undefined;
  },
  mounted(){
    this.displayRolbyModulues()
  }
}
</script>

<style>
.myCompanyImagePreview {
  max-width: 100%;
  margin: auto; 
}
</style>