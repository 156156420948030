<template>
  <!-- SECTION Slots-->
  <div id="slotsSection" class="row col-12 m-0 p-0">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">

      <!-- HEADER Slots -->
      <div class="col-lg-2 col-md-4 col-12 m-0 p-2 text-white rounded-5">
        <div class="border-1 rounded-5 box sombra">
          <!--<ion-icon v-if="session.user.bookmark==='slots'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
          <div class="col-12 overflow-hidden justify-content-center">
            <h6 class="tx-left tx-clomos-black text-center mt-2 mb-2 ps-1 pt-1">
              <ion-icon name="grid" class="tx-18 me-2 align-bottom"></ion-icon>
              {{ dictionary["Huecos"][session.user.language] }}
            </h6>
          </div>

          <div class="p-3 pt-0">
            <!-- Selector de Sistema -->
            <div class="col-12 m-0 p-0">
              <hr v-if="this.$route.params.currentDisplay === 'slots'" class="m-0 p-0 bg-clomos-black">

              <select v-if="this.$route.params.currentDisplay === 'slots'" :value="currentCompany.nifCif"
                class="form-control mt-3 mb-3 h-100 text-nowrap bg-white"
                style="border: solid 2px black ; color: black;" @change="onCurrentCompanyChanged">
                <option v-if="availableCompanies[0] != undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
                <option v-for="company in availableCompanies" :key="company.nifCif" :v-if="company.nifCif != session.user.nifCif
                && company.relation != undefined
                && company.relation.referenceCompany === this.session.user.nifCif" :value="company.nifCif">
                  {{ (company.nifCif === session.user.nifCif) ? "✪ " + company.name : company.name }}
                </option>
              </select>

              <hr class="m-0 p-0 mb-3 bg-clomos-black">

              <div class="row justify-content-around m-0 p-0">
                <div class="col-3 m-0 mb-3 p-0">
                  <button @click="onChangeDisplayType('locker')"
                    :style="(displayType === 'locker') ? 'opacity=1;' : 'opacity: 0.7'"
                    class="border-black col-12 header-tab-button border-2 btn  rounded-5 p-0  text-nowrap"
                    style="background-color:white">
                    <ion-icon name="grid-outline" :style="(displayType === 'locker') ? 'opacity=1;' : 'opacity: 0.5'"
                      class="border-black  text-black tx-23 pt-3 pb-3" style="vertical-align: middle;"></ion-icon>
                  </button>
                </div>
                <div class="col-3 m-0 mb-3 p-0">
                  <button @click="onChangeDisplayType('table')"
                    :style="(displayType === 'table') ? 'opacity=1;' : 'opacity: 0.7'"
                    class="border-black col-12 header-tab-button border-2 btn  rounded-5 p-0 text-nowrap"
                    style="background-color: white">
                    <ion-icon name="list-outline" :style="(displayType === 'table') ? 'opacity=1;' : 'opacity: 0.5'"
                      class="border-black  text-black tx-23 pt-3 pb-3" style="vertical-align: middle;"></ion-icon>
                  </button>
                </div>
                <div class="col-3 m-0 mb-3 p-0">
                  <button @click="onChangeDisplayType('cards')"
                    :style="(displayType === 'cards') ? 'opacity=1;' : 'opacity: 0.7'"
                    class="border-black col-12 header-tab-button border-2 btn  rounded-5 p-0 text-nowrap"
                    style="background-color:white">
                    <ion-icon name="documents-outline" :style="(displayType === 'cards') ? 'opacity=1;' : 'opacity: 0.5'"
                      class="border-black  text-black tx-23 pt-3 pb-3" style="vertical-align: middle;"></ion-icon>
                  </button>
                </div>
              </div>

              <hr class="m-0 p-0 bg-clomos-black">

              <div class="col-12 p-0 justify-content-center d-flex">
                <button id="btnDisplayNewSlotModal" type="button" class="button-ok bg-clomos-black mt-3 rounded-3"
                  @click="toggleNewSlotModal">
                  <span class="button-text text-white">
                    <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                    <span class="text-white mt-0 text-nowrap">{{ dictionary["Añadir hueco"][session.user.language]}}</span>
                  </span>
                </button>
              </div>
            </div>

          </div>

        </div>
      </div>

      <div class="col-lg-10 col-md-8 col-12 m-0 p-2 overflow-show justify-content-center">
        <div class="col-12 table-responsive bg-caja rounded-top-5 m-0 p-0 sombra">
          <!--<ion-icon v-if="session.user.bookmark==='slots'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
          <div class="position-sticky col-12 m-0 pt-2 pb-2 bg-clomos-black text-white border-1">
            <ion-icon name="grid-outline" class="tx-16 mb-1 me-2 align-bottom"></ion-icon>
            <span> {{ dictionary["Huecos"][session.user.language] }} </span>
            <span v-if="this.$route.params.currentDisplay === 'slots' || this.targetLockerId == ''">
              {{ ": " + currentCompany.name }}
            </span>
            <span v-else-if="this.targetLockerData.label != undefined">
              {{ ": " + targetLockerData.label }}
            </span>
          </div>

          <!-- LOCKERS Slots -->
          <div v-show="displayType === 'locker'"
            :class="(displayType === 'locker') ? 'd-flex d-row justify-content-around' : ''"
            class="form-group p-3 m-3 border rounded " style="overflow: scroll; overflow-x: auto; overflow-y: hidden;">
            <div v-if="lockerSlotsGrid.length > 0" class="border-3 rounded-3">
              <table class="tx-black fw-medium justify-content-center h-100"
                styles="border: solid white 3px; border-radius: 10px;">
                <tr v-for="row in lockerSlotsGrid" :key="row" style="height: 100%">
                  <td v-for="slot in row" :key="slot" @click="moreInfo(slot)"
                    class="tx-white fw-medium justify-content-center"
                    style="border: solid white 3px; border-radius: 8px; width: 135px; max-width: 135px; min-width: 135px; height: 100%; max-height: 100px; min-height: 100px; font-size: 1em;"
                    :style="{
                backgroundColor:
                  (slot.variables.analog.estado.value === '') ? 'silver' :
                    Number(slot.variables.analog.estado.value) === 0 ? '#7D8235' :
                      Number(slot.variables.analog.estado.value) === 1 ? '#ff9933' :
                        Number(slot.variables.analog.estado.value) === 2 ? 'red' :
                          Number(slot.variables.analog.estado.value) === 3 ? '#ff9933' :
                            'transparent'
              }">
                    <div v-if="slot.label != undefined">
                      {{ slot.label }} <img
                        v-if="slot.variables.digital['accesible'] != undefined && slot.variables.digital.accesible.value === 'true'"
                        src="@/assets/img/discapacidades.png" :data-id="slot.id"
                        class=" m-0 ms-1 p-0 icon-size tx-white"
                        style="width: 18px; height: 18px; filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(100%) contrast(100%);">
                    </div>
                    <div>
                      {{ (slot.variables.analog.estado.value === '') ?  dictionary["No hay ningún hueco."][session.user.language] :
                (Number(slot.variables.analog.estado.value) === 0) ?   dictionary["Vacío"][session.user.language]  :
                  (Number(slot.variables.analog.estado.value) === 1) ? dictionary["Reservado"][session.user.language] :
                    (Number(slot.variables.analog.estado.value) === 2) ?dictionary["Ocupado"][session.user.language] :
                      (Number(slot.variables.analog.estado.value) === 3) ? dictionary["Espera"][session.user.language] :
                        "" }}
                    </div>
                    <div v-if="slot.id != ''" class="col-12 d-flex justify-content-around m-0 p-0 pt-2 pb-1">
                      <ion-icon v-if="slot.variables.dynamic['tipo'] != undefined"
                        :name='slot.variables.dynamic.tipo.value === "frio" ? "snow-outline" : slot.variables.dynamic.tipo.value === "ambiente"?"sunny-outline":""'
                        :data-id="slot.id" class='m-0 p-0 tx-18 tx-white'></ion-icon>
                      <ion-icon v-if="slot.variables.digital['cerrado'] !== undefined"
                        :name='slot.variables.digital["cerrado"].value === "false" ? "lock-open-outline" : "lock-closed-outline"'
                        class='m-0 p-0 tx-18 tx-white'></ion-icon>
                      <ion-icon name='settings-outline' :data-id="slot.id"
                        class='btnEditSlot btn m-0 p-0 tx-18 tx-white'></ion-icon>
                    </div>
                  </td>
                </tr>
              </table>
            </div>

            <span v-else class="tx-13"> {{ dictionary["No hay huecos disponibles"][session.user.language] + "." }} </span>

            <div v-if="targetLockerData.variables" class="border-3 rounded-3 text-center  tx-24 ms-3 ps-3 w-100">
              <br>
              <ion-icon @click="onRefresh" name="reload-outline" class="border-3 rounded p-1 tx-20 tx-clomos-midtone"
                style="cursor:pointer;"></ion-icon>
              <br>

              <div v-if="!slotFilter" class="p-3">
                <label class="tx-clomos-black tx-20 tx-bold text-nowrap"> {{dictionary["Estado del locker"][session.user.language]}}: </label>
                <br>

                <label class="tx-clomos-black  tx-18 text-nowrap"> {{dictionary["Huecos totales"][session.user.language]}}: <span class="ps-1 tx-clomos-black">
                    {{ targetLockerData.variables.analog.huecosTotales.value }} </span> </label>
                <br>
                <label class="tx-clomos-black tx-18 text-nowrap"> {{dictionary["Huecos libres"][session.user.language]}}: <span class="ps-1 tx-clomos-black">
                    {{ targetLockerData.variables.analog.huecosLibres.value }} </span> </label>
                <br>
                <label class="tx-clomos-black tx-18 text-nowrap"> {{dictionary["Huecos ocupados"][session.user.language]}}: <span class="ps-1 tx-clomos-black">
                    {{ targetLockerData.variables.analog.huecosOcupados.value }} </span> </label>
                <br>

              </div>
              <div v-if="slotFilter" class="p-3">
                <div>
                <label class="tx-clomos-black tx-20 tx-bold text-nowrap"> {{dictionary["Nombre"][session.user.language]}}: 
                  <span class="ps-1 tx-clomos-black">
                    {{ slotFilter.label}} </span>
                </label>
                <br>
              </div>
              <div v-if="slotFilter.variables.dynamic.idPedido.value&&slotFilter.variables.dynamic.idPedido!=undefined ">
                <label  class="tx-clomos-black  tx-18 text-nowrap"> {{dictionary["ID pedido"][session.user.language]}}: <span v-if="slotFilter.variables.dynamic.idPedido.value.length<8" class="ps-1 tx-clomos-black">
                  
                    {{ slotFilter.variables.dynamic.idPedido.value}} </span> </label> 
                    <label  class="ps-1 tx-clomos-black tx-18" v-if="slotFilter.variables.dynamic.idPedido.value.length>=8">  {{ slotFilter.variables.dynamic.idPedido.value}}</label>
                <br>
              </div>
              <div>
                <label class="tx-clomos-black tx-18 text-nowrap">{{dictionary["Estado puerta"][session.user.language]}}: <span class="ps-1 tx-clomos-black">
                    {{ slotFilter.variables.digital.cerrado.value === "true" ? dictionary["Cerrada"][session.user.language] : dictionary["Abierta"][session.user.language] }} </span> </label>
                <br>
              </div>
              <div v-if= "slotFilter.token">
                <label  class="tx-clomos-black tx-18 text-nowrap"> Token: <span class="ps-1 tx-clomos-black">
                    {{ slotFilter.token }} </span> </label>
                <br>
              </div>
              <div>
                  <button v-if="!showSpinner" type="button" @click="forceSlotOpening(slotFilter.id)" id="btnForceSlotOpenning"
                    class="button-open rounded-3 bs-gray col-md-6 col-12 ">
                    <span class="button-text text-white">
                      <ion-icon :name="slotFilter.variables.digital.solicitaAperturaRemota&&slotFilter.variables.digital.solicitaAperturaRemota.value === 'true' ? 'lock-closed-outline' : 'lock-open-outline'" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                      <span  class="text-white mt-0">{{ (slotFilter.variables.digital.solicitaAperturaRemota&&slotFilter.variables.digital.solicitaAperturaRemota.value === "true") ? dictionary['Deshabilitar apertura remota'][session.user.language] : dictionary['Habilitar apertura remota'][session.user.language]}}</span>
                    </span>                   
                  </button>
                  <div v-if="showSpinner" class="spinner-border text-dark" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <!-- TABLE Slots -->
          <div v-show="displayType === 'table'" class="m-0 p-3 h-auto">
            <table id="slotsDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
              <thead class=" tx-14 text-white text-center bg-clomos-black overflow-hidden">
                <tr>
                  <th v-for="column in slotsColumns" :key="column.data" :width="column.width"
                    class="fw-medium text-nowrap p-2">
                    {{ dictionary[column.text][session.user.language] }}
                  </th>
                </tr>
              </thead>
            </table>
          </div>

          <!-- CARDS Slots -->
          <div v-show="displayType === 'cards' && this.slotList[0] != undefined" id="slotsCardsContent"
            class="col-12 m-0 p-2 h-auto" :style="(!display.showEverything) ? 'height: 15rem' : ''">
            <div class="col-12 m-0 p-0 d-flex flex-row flex-wrap">
              <div v-for="slot in slotCardsByPages[slotCardsPage]" :key="slot" class="col-xl-2 col-md-4 col-12 m-0 p-2">
                <h6
                  class="tx-left tx-clomos-white text-center text-nowrap m-0 p-2 ps-1 pt-2  rounded-top-3" :style="{
                  background: Number(slot.variables.analog.estado.value) === 0 ? '#7D8235' :
                         Number(slot.variables.analog.estado.value) === 1  ? '#ff9933' :
                        Number(slot.variables.analog.estado.value) === 2 ? 'red' :  Number(slot.variables.analog.estado.value) === 3?'#ff9933': 'black'
                }">
                  <span  class="m-auto">
                  {{ slot.label.length > 18 ? slot.label.substring(0, 18) + '...' : slot.label }}
                </span>
                </h6>

                <div class="col-12 border-1 rounded-bottom-3 m-0 p-3">
                  <div class="col-12 m-0 p-0 d-flex justify-content-around">
                     <ion-icon v-if="slot.showInfo && slot.showInfo !== undefined " name="remove-outline" :data-id="slot.id" @click="toggleInfo(slot)"
                      class="btnTargetSlot col-2 m-0 mt-auto mb-auto p-0 btn tx-24 text-black"></ion-icon>
                    <ion-icon v-if="!slot.showInfo|| slot.showInfo === undefined " name="add-outline" :data-id="slot.id" @click="toggleInfo(slot)"
                      class="btnTargetSlot col-2 m-0 mt-auto mb-auto p-0 btn tx-24 text-black"></ion-icon>
                    <ion-icon name="settings-outline" :data-id="slot.id"
                      class="btnEditSlot col-2 m-0 mt-auto mb-auto p-0 btn tx-24 text-black"></ion-icon>
                    <ion-icon name="trash-outline" :data-id="slot.id"
                      class="btnDeleteSlot col-2 m-0 mt-auto mb-auto p-0 btn tx-24 tx-danger"></ion-icon>
                     
                  </div>
                  <hr class="col-12 mt-2 mb-2 bg-clomos-black">
                  <div class="col-12 d-flex flex-column tx-left align-middle p-0" :style="slot.showInfo && slot.showInfo !== undefined ? 'height: 19rem;' : 'height: 8rem;'">
                    <span class="tx-13 tx-bold">{{ dictionary['Tipo'][session.user.language] + ": " }}</span>
                    <span class="tx-11 tx-start">{{ slot.type }}</span>
                    <span class="tx-13 tx-bold">{{ dictionary['Identificador'][session.user.language] + ": " }}</span>
                    <span class="tx-11 tx-start">{{ slot.id }}</span>
                    <span class="tx-13 tx-bold">{{ dictionary['Coordenadas'][session.user.language] + ": " }}</span>
                    <span class="tx-11 tx-start">{{ `lat: ${Number(slot.geoJSON.geometry.coordinates[0])} lon: ${Number(slot.geoJSON.geometry.coordinates[1])}` }}</span>
                    <div v-if="slot.showInfo !== undefined && slot.showInfo" class="col-12 d-flex flex-column tx-left align-middle p-0">
                      <hr class="col-12 mt-2 mb-2 bg-clomos-black">
                      <span  v-if="slot.variables.dynamic.idPedido.value" class="tx-13 tx-bold">{{ dictionary['Pedido'][session.user.language] + ": " }}</span>
                      <span v-if="slot.variables.dynamic.idPedido.value" class="tx-11 tx-start">{{ slot.variables.dynamic.idPedido.value?slot.variables.dynamic.idPedido.value:"-" }}</span>
                      <span class="tx-13 tx-bold">{{ dictionary['Estado puerta'][session.user.language] + ": " }}</span>
                      <span class="tx-11 tx-start"> {{ slot.variables.digital.cerrado.value === "true" ? dictionary['Cerrada'][session.user.language] : dictionary["Abierta"][session.user.language] }}</span> 
                      <span v-if="slot.token" class="tx-13 tx-bold">{{ dictionary['Token'][session.user.language] + ": " }}</span>
                      <span  v-if="slot.token" class="tx-11 tx-start">{{ slot.token }}</span>
                      <span class="tx-13 tx-bold">{{ dictionary['Apertura remota'][session.user.language] + ": " }}</span>
                       <p class="tx-11 tx-start  "> <span class="tx-11 tx-start">{{ slot.variables.digital.solicitaAperturaRemota.value === "true" ? "Habilitado" : "Deshabilitado" }}</span>      
                        <ion-icon name="exit-outline" @click="forceSlotOpening(slot.id)" :data-id="slot.id" class="btnOpen btn col-2 m-0 mt-auto mb-auto p-0 btn tx-24 tx-danger"></ion-icon>
                       </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex flex-row m-0 p-2">
                <div v-for="page, index in slotCardsByPages" :key="page" class="border rounded c-pointer m-0 me-2 tx-13"
                  :class="(slotCardsPage === index) ? 'border-dark' : ''"
                  style="height:2.5rem; width:2.5rem; padding: inherit;" @click="onSetCardPage(index)">
                  <span class="tx-bold">{{ index + 1 }}</span>
                </div>
              </div>
            </div>
          </div>

          <div v-show="displayType === 'cards' && this.slotList[0] == undefined" class="m-3 p-3 border rounded">
            <span class="tx-13">
              {{ dictionary['No hay dispositivos disponibles'][session.user.language] }}
            </span>
          </div>

        </div>
      </div>

    </div>
  </div>
 

  <!-- MODAL New Slot -->
  <NewSlotModal v-if="display.newSlotModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" :targetLockerId="targetLockerId"
    v-on:toggleNewSlotModal="toggleNewSlotModal">
  </NewSlotModal>


  <!-- MODAL Edit Slot -->
  <EditSlotModal v-if="display.editSlotModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" :targetLockerId="targetLockerId" :slotId="targetSlotId"
    v-on:toggleEditSlotModal="toggleEditSlotModal">
  </EditSlotModal>

  <!-- MODAL Delete Text Alert -->
  <TextAlertModal v-if="display.deleteSlotModal" :session="session" :dictionary="dictionary" :countries="countries"
    :alertHeader="dictionary['Hueco a eliminar'][session.user.language] + ': '" :alertTarget="targetSlotId"
    v-on:acceptAlert="onDeleteSlot" v-on:dismissAlert="toggleDeleteSlotModal">
  </TextAlertModal>
</template>

<script>
import $ from 'jquery';
//import StatusComponent from '@/components/StatusComponent';
import NewSlotModal from '@/components/lockers/NewSlotModal';
import EditSlotModal from '@/components/lockers/EditSlotModal';
import TextAlertModal from '@/components/other/TextAlertModal';
// Clomos
import api from '@/services/api';

export default {
  name: "SlotComponent",
  components: { /* StatusComponent,*/ EditSlotModal, NewSlotModal, TextAlertModal },
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    targetLockerId: {
      type: String,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    }
  },
  watch: {
    currentCompany: async function () {
      if (this.$route.params.currentDisplay === 'slots' || this.targetLockerId == '') {
        await this.getBundleListByCompany();
      }
      else {
        await this.getLockerData();
        await this.getSlotListByLocker();
      }
      this.onChangeDisplayType(this.displayType);
      if (this.slotList[0] != undefined && this.targetSlotId === "")
        this.targetSlotId = this.slotList[0].id;
    },
    targetLockerId: async function () {
      if (this.$route.params.currentDisplay === 'slots' || this.targetLockerId == '') {
        await this.getBundleListByCompany();
      }
      else {
        await this.getLockerData();
        await this.getSlotListByLocker();
      }
      this.onChangeDisplayType(this.displayType);
      if (this.slotList[0] != undefined && this.targetSlotId === "")
        this.targetSlotId = this.slotList[0].id;
    }
  },
  data() {
    return {
      slotsColumns: [
        {
          text: "Identificador", data: "slotId", width: "25%", className: "tx-left align-middle dt-nowrap",
          render: (data) => {
            return "<ion-icon name='copy-outline' data-id=" + data + " class='btnCopyId btn tx-18 tx-clomos-midtone'></ion-icon>"
              + "<span data-id=" + data + " class='btnTargetProduct c-pointer tx-bold tx-clomos-midtone text-nowrap align-middle'>" + data + "</span>";
          }
        },
        { text: "Etiqueta", data: "slotLabel", width: "15%", className: "tx-left align-middle" },
       /*  { text: "Tipo", data: "slotType", width: "10%", className: "tx-left align-middle" }, */
        {
          text: "Emplazamiento", data: "slotEmplacement", width: "10%", className: "tx-left align-middle",
          render: (data) => {
            let emplacementLabel = data;
            if (this.lockerList.length > 0) {
              for (let emplacement in this.lockerList)
                if (this.lockerList[emplacement].id === data)
                  emplacementLabel = this.lockerList[emplacement].label;
            }
            return emplacementLabel;
          }
        },
        { text: "Token", data: "slotToken", width: "15%", className: "tx-left align-middle" },
        {
          text: "Fecha de comunicación", data: "slotCommunicationDateTime", width: "10%", className: "align-middle",
          render: (data) => {
            return (data != "")
              ? "<span style='display: none;'>" + data.getTime() + "</span> <spam>" + api.parseDateUserTimezone(data, this.session.user, this.$dayjs) + "</spam>"
              : "<span style='display: none;'></span> <spam>-</spam>";
          }
        },
        {
          text: "Coordenadas", data: "slotGeoJSON", width: "15%", className: "tx-left align-middle",
          render: (data) => {
            if (data.geometry != undefined && data.geometry.type === "Point") return "<div class='d-flex pt-1'><span class='me-2 ms-2 tx-black'>Lat:</span> <span>" + Number(data.geometry.coordinates[0]) + "</span> <span class='me-2 ms-2 tx-black'>Lon:</span> <span>" + Number(data.geometry.coordinates[1])
            else return "<span class='me-2 ms-2 tx-primary'>Lat:</span> <span>" + 0 + "</span>  <span class='me-2 ms-2 tx-primary'> Lon:</span></div> " + 0
          }
        },
        {
          text: "Apertura remota", data: "slotId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='exit-outline' data-id=" + data + " class='btnOpen btn tx-24 tx-primary-adn'></ion-icon>";
          }
        },
        {
          text: "Configuración", data: "slotId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='settings-outline' data-id=" + data + " class='btnEditSlot btn tx-24 tx-primary-adn'></ion-icon>"
          }
        },
        {
          text: "Eliminar", data: "slotId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='trash-outline' data-id=" + data + " class='btnDeleteSlot btn tx-24 tx-primary-adn'></ion-icon>"
          }
        },
      ],
      targetLockerData: {},
      targetSlotId: this.$route.params.targetEntity,
      slotList: [],
      lockerList: [],
      lockerSlotsGrid: [],
      displayType: 'locker',
      slotCardsPage: 0,
      display: {
        newSlotModal: false,
        editSlotModal: false,
        linkSlotModal: false,
        deleteSlotModal: false,
      },
      linkAction: "link",
      slotFilter: "",
      showSpinner: false,     
    }
  },
  computed: {
    slotCardsByPages() {
      let slotCardsByPages = []
      let i = 0;
      while (i * 12 < this.slotList.length) {
        slotCardsByPages.push(this.slotList.slice(i * 12, i * 12 + 12));
        i++;
      }
      return slotCardsByPages;
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    moreInfo(slotData) {      
      slotData.id===""?this.slotFilter=undefined :this.slotFilter = slotData;
    },
    async onForceSlot(event){    
      let slotID = event.target.getAttribute('data-id')  
      await this.forceSlotOpening(slotID)
    },
    async forceSlotOpening(slotID) {      
      this.showSpinner=true;
      let response = await api.forceSlotOpening(slotID);
     
      if (response["status-code"] === "200") {       
         await this.getLockerData();
        await this.getSlotListByLocker();
        
        for (let i = 0; i < this.slotList.length; i++) {
          if(this.slotList[i].id===this.slotFilter.id){
            this.slotFilter=this.slotList[i]
          }
          
        }
        let slotCardsByPages = []
      let x = 0;
      while (x * 12 < this.slotList.length) {
        slotCardsByPages.push(this.slotList.slice(x * 12, x * 12 + 12));
        x++;
      } 
      if (this.displayType === "table") this.displaySlotsDataTable();
       
      }
      this.showSpinner=false;
    },
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({ params: { currentCompanyNifCif: value } })
      this.$emit('setCurrentCompany', value);
    },
    onChangeDisplayType(type) {
      this.displayType = type;
      setTimeout(() => {
        if (type === "locker") this.displaySlotsLocker();
        if (type === "table") this.displaySlotsDataTable();
        this.setButtonFunctions();
      }, 100);
    },
    onSetCardPage(index) {
      this.slotCardsPage = index;
      this.setButtonFunctions();
    },
    displaySlotsLocker() {
      if (this.targetLockerData.variables === undefined) return;
      if (this.targetLockerData.variables.analog.numeroFilas === undefined
        || this.targetLockerData.variables.analog.numeroColumnas === undefined)
        return;
      let rows = parseInt(this.targetLockerData.variables.analog.numeroFilas.value);
      let columns = parseInt(this.targetLockerData.variables.analog.numeroColumnas.value);
      this.lockerSlotsGrid = [];
      let r0 = 0;
      while (r0 < rows) {
        this.lockerSlotsGrid.push([]);
        let c0 = 0;
        while (c0 < columns) {
          this.lockerSlotsGrid[r0][c0] = { "id": "", "label": "", "variables": { "analog": { "estado": { "value": "" } }, "digital": { "cerrado": { "value": "" } } } };
          for (let slot in this.slotList) {
            if (this.slotList[slot].geoJSON != undefined
              && this.slotList[slot].geoJSON.geometry != undefined
              && this.slotList[slot].geoJSON.geometry.coordinates != undefined
              && this.slotList[slot].geoJSON.geometry.coordinates[0] == r0
              && this.slotList[slot].geoJSON.geometry.coordinates[1] == c0) {
              this.lockerSlotsGrid[r0][c0] = this.slotList[slot]
            }
          }
          c0 += 1;
        }
        r0 += 1;
      }
      this.setButtonFunctions();
    },
    onRefresh() {
      this.getLockerData();
      this.getSlotListByLocker(this.displaySlotsLocker);
      this.slotFilter = "";
    },
    displaySlotsDataTable() {
      $("#slotsDataTable").DataTable().clear()
      for (let i in this.slotList) {
        $("#slotsDataTable").DataTable().rows.add([{
          slotId: this.slotList[i].id,
          slotEmplacement: this.slotList[i].parent,
          slotLocation: this.slotList[i].parent,
          slotLabel: this.slotList[i].label,
          slotType: this.slotList[i].type,
          slotStatus: this.slotList[i].variables.analog.estado.value,
          slotCommunicationDateTime: (this.slotList[i].communicationDateTime != undefined && this.slotList[i].communicationDateTime != "") ? new Date(this.slotList[i].communicationDateTime) : "",
          slotGeoJSON: this.slotList[i].geoJSON,
          slotToken: this.slotList[i].token
        }]);
      }
      $("#slotsDataTable").DataTable().draw();
      this.setButtonFunctions();
      $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
      if ($("#slotsDataTable_paginate").get()[0] != undefined) $("#slotsDataTable_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
    },
    async toggleNewSlotModal() {
      if (this.display.newSlotModal) {
        if (this.$route.params.currentDisplay === 'slots' || this.targetLockerId == '') {
          await this.getBundleListByCompany();
          this.displaySlotsDataTable();
        }
        else {
          await this.getLockerData();
          await this.getSlotListByLocker();
          this.displaySlotsDataTable();
        }
      }
      this.display.newSlotModal = !this.display.newSlotModal;
    },
    async toggleEditSlotModal() {
      if (this.display.editSlotModal) {
        if (this.$route.params.currentDisplay === 'slots' || this.targetLockerId == '') {
          await this.getBundleListByCompany();
          this.displaySlotsDataTable();
        }
        else {
          await this.getLockerData();
          await this.getSlotListByLocker();
          this.displaySlotsDataTable();
        }
      }
      this.display.editSlotModal = !this.display.editSlotModal;
    },
    async toggleLinkSlotModal() {
      if (this.display.linkSlotModal) {
        if (this.$route.params.currentDisplay === 'slots' || this.targetLockerId == '') {
          await this.getBundleListByCompany();
          this.displaySlotsDataTable();
        }
        else {

          await this.getLockerData();
          await this.getSlotListByLocker();
          this.displaySlotsDataTable();
        }
      }
      this.display.linkSlotModal = !this.display.linkSlotModal;
    },
    async toggleDeleteSlotModal() {
      if (this.display.deleteSlotModal) {
        if (this.$route.params.currentDisplay === 'slots' || this.targetLockerId == '') {
          await this.getBundleListByCompany();
          this.displaySlotsDataTable();
        }
        else {
          await this.getLockerData();
          await this.getSlotListByLocker();
          this.displaySlotsDataTable();
        }
        if (this.slotList[0] != undefined) this.targetSlotId = this.slotList[0].id;
      }
      this.display.deleteSlotModal = !this.display.deleteSlotModal;
    },
    toggleInfo(slot){      
    slot.showInfo = !slot.showInfo;
    
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(() => {
        $(".btnTargetSlot").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetSlot);
        });
        $(".btnEditSlot").get().forEach(btn => {
          btn.addEventListener('click', this.onEditSlot);
        });
        $(".btnLinkSlot").get().forEach(btn => {
          btn.addEventListener('click', this.onLinkSlot);
        });
        $(".btnOpen").get().forEach(btn => {
          btn.addEventListener('click', this.onForceSlot);
        });        
        $(".btnUnlinkSlot").get().forEach(btn => {
          btn.addEventListener('click', this.onUnlinkSlot);
        });
        $(".btnDeleteSlot").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteSlot);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });       
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    // GET
    async getBundleListByCompany(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getBundleListByCompany(request);
      this.slotList = api.parseBundlesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.slotList);
    },
    async getSlotListByLocker(callback) {
      let request = {
        lockerId: this.targetLockerId,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getSlotListByLocker(request);
      this.slotList = api.parseBundlesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.slotList);
    },
    async getLockerData(callback) {
      let response = await api.getLockerData(this.targetLockerId);
      this.targetLockerData = api.parseMachineFromResponse(response);
      if (callback && typeof callback === "function") callback(this.targetLockerData);
    },
    async getLockersDataByCompany(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getLockerListByCompany(request);
      this.lockerList = api.parseLockersFromResponse(response);
      if (callback && typeof callback === "function") callback(this.lockerList);
    },
    // TARGET 
    onTargetSlot(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetSlotId = event.target.getAttribute('data-id')      
      this.$router.replace({ params: { targetEntity: this.targetSlotId } })
    },
    // SET 
    onEditSlot(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetSlotId = event.target.getAttribute('data-id');
      this.$router.replace({ params: { targetEntity: this.targetSlotId } })
      this.toggleEditSlotModal();
    },
    // LINK 
    onLinkSlot(event) {
      console.log('LINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetSlotId = event.target.getAttribute('data-id')
      this.$router.replace({ params: { targetEntity: this.targetSlotId } })
      this.linkAction = "link"
      this.toggleLinkSlotModal();
    },
    // UNLINK 
    onUnlinkSlot(event) {
      console.log('UNLINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetSlotId = event.target.getAttribute('data-id')
      this.$router.replace({ params: { targetEntity: this.targetSlotId } })
      this.linkAction = "unlink"
      this.toggleLinkSlotModal();
    },
    // DELETE
    async onDeleteSlot(event) {
      if (!this.display.deleteSlotModal) {
        console.log('DELETE WHERE ID:');
        console.log(this.targetSlotId);
        this.targetSlotId = event.target.getAttribute('data-id');
        this.$router.replace({ params: { targetEntity: this.targetSlotId } })
      }
      else {
        let response = await api.deleteSlot(this.targetSlotId);
        if (response["status-code"] === "200") {
          window.alert("El hueco ha sido eliminado satisfactoriamente.");
        }
        else {
          window.alert("Error al eliminar el hueco.");
        }
      }
      await this.toggleDeleteSlotModal();
    }
  },
  async created() {
    await this.getLockersDataByCompany();
    if (this.$route.params.currentDisplay === 'slots') {
      await this.getBundleListByCompany();
    }
    else if (this.targetLockerId != '') {
      await this.getLockerData();
      await this.getSlotListByLocker();
    }
    this.$emit('getAvailableCompanies', this.onChangeDisplayType(this.displayType));
    if (this.slotList[0] != undefined && this.targetSlotId === "")
      this.targetSlotId = this.slotList[0].id;
  },
  mounted() {
       $("#slotsDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn background-adn' },
          { "extend": 'copy', "className": 'btn background-adn' },
          { "extend": 'csv', "className": 'btn background-adn' },
          { "extend": 'print', "className": 'btn background-adn me-0' },
        ],
        columns: this.slotsColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>

<style>
.btonCopyId:hover,
.btnTargetSlot:hover,
.btnLinkSlot:hover,
.btnUnlinkSlot:hover,
.btnEditSlot:hover,
.btnOpen:hover,
.btnDeleteSlot:hover {
  color: #FED54F !important;
}

.background-adn {
  background-color: #FED54F !important;
  color: black;
}

.tx-primary-adn {
  color: #7D8235;
}
</style>