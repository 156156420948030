<template>
  <!-- Cabeza -->
  <section id="logInSection " class="fontAdn">
    <div class="m-0 p-0 background_Alava d-flex flex-column " style="min-height: 100vh; height: 100%;  ">

      <div class="d-flex col-lg-12 col-md-12 col-11 text-start p-3 flex-column flex-sm-row">
        <div class="col-sm-2 d-flex align-items-center justify-content-center" id="logo">
          <img title="Logo" src="@/assets/img/logo-ADN.png" class="img-fluid m-auto" @click="navigateToUrl"
          style="max-width: 193px; max-height: 60px; aspect-ratio: auto 193 / 100;">
        </div>
        
        <div id="topMenu"
          class="border-bottom col-sm-10 d-flex align-items-center justify-content-sm-end justify-content-center p-3"
          style="border-color: #d8d8d8;">
          <div v-for="(language, index) in languages" :key="index">
            <span v-if="language != session.user.language" @click="toggleLanguage(language)"
              class="text-end text-black tx-25 text-uppercase p-1 language">{{ language }}</span>
          </div>
        </div>
      </div>

      <div class="row m-0 p-0">
        <div id="ocultar"
          class="row col-lg-8 col-md-12 col-12 m-0 p-3 pt-0 pb-0 pt-0 text-black justify-content-center align-items-center">
          <div class="box justify-content-center align-items-center  m-0 p-0">
            <div class="row justify-content-center align-items-center m-0 p-0">

              <img title="Locker" src="@/assets/img/LOCKER-FINAL-OK.png" class="img-fluid m-auto"
                style="max-height: 700px; max-width: 700px;">
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-4 col-12 m-0 p-3 pt-0 ">
          <div class="login-box border-1 border-black box sombra-izq rounded-5">
            <div class="card-body pt-2 p-3 rounded-5">
              <!-- Language -->
              <div class="col-md-12">
                <div class="ms-auto me-auto mt-3 form-group text-center rounded-5">
                  <h5 class="text-black text-uppercase">{{dictionary['Acceso usuarios'][session.user.language]}}</h5>
                  <hr style="background-color: #fed54f; border:none; overflow: visible;opacity: 1;height: 4px">
                </div>
              </div>
              <!-- FORM Iniciar Sesion -->
              <form v-if="visibility.formLogIn" id="formLogIn" class="form-horizontal text-center">

                <div class="form-group p-3 mt-0">
                  <span class="tx-14 tx-black">{{ errorMsg }}</span><br>
                  <div class="col-xs-10">
                    <input v-model="formLogIn.userId" id="userId" type="text" autofocus
                      class="p-2 text-black form-control"
                      :placeholder="dictionary['Nombre de usuario'][session.user.language] + '/' + dictionary['E-mail'][session.user.language]">
                  </div>
                </div>

                <div class="form-group p-3">
                  <div class="col-xs-10">
                    <input v-model="formLogIn.password" id="password" type="password" autocomplete="off"
                      class="p-2 text-black  form-control" @keydown.enter="onLogInRequest"
                      :placeholder="dictionary['Contraseña'][session.user.language]">
                  </div>
                </div>

                <input v-model="formLogIn.rememberMe" @click="formLogIn.rememberMe = !formLogIn.rememberMe"
                  id="rememberMe" type="checkbox">
                <label for="rememberMe"
                  class="ps-1 text-black">{{ dictionary['Recuérdame'][session.user.language] }}</label>
                <div class="form-group text-center mt-3">
                  <div class="col-xs-12 justify-content-center d-flex">
                    <button id="btnLogin" @click="onLogInRequest" type="button" class="button-ok  mt-1 rounded-5">
                      <span>
                        <span class="highlight-bg"></span>
                        <span class="button-text">
                          {{ dictionary["Iniciar sesión"][session.user.language] }}
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <div class="row align-items-center">
                      <div class="ps-0 pt-3">
                        <a @click="toggleRecoveryForm" id="to-recover" class="tx-black">
                          <ion-icon name="lock-open" class="menu-item-icon tx-14"></ion-icon>
                          {{ dictionary["¿Has olvidado tu contraseña?"][session.user.language] }}
                        </a>
                      </div>
                      <div class="ps-0 pt-3">
                        <a @click="toggleValidateClientForm" id="to-validate" class="tx-black">
                          <ion-icon name="checkmark-circle" class="menu-item-icon tx-14"></ion-icon>
                          {{ dictionary["Validar cuenta"][session.user.language] }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <!--       <div class="form-group text-center">
                  <div class="mt-3 p-3">
                    <label for="language" class="w-100 p-1 text-black">
                    {{dictionary["Idioma"][session.user.language]}}
                    </label>
                    <select 
                      :value="session.user.language"
                      id="languageSelector" class="p-2 input-black form-control"
                      @change="toggleLanguage" 
                      name="languageSelector" title="Language">
                      <option value="es">
                        {{dictionary["Español"][session.user.language]}}
                      </option>
                      <option value="en">
                        {{dictionary["Inglés"][session.user.language]}}
                      </option>
                    </select>
                  </div>
                </div> -->
              </form>
              <!-- FORM Recuperar password -->
              <form v-if="visibility.formPasswordLost" id="formPasswordLost" class="form-horizontal">
                <div class="form-group ">
                  <div class="col-xs-12">
                    <h3 class="tx-black tx-14 mt-3">
                      {{ dictionary["Recuperar contraseña"][session.user.language] }}
                    </h3>
                    <p class="tx-black tx-13">
                      {{ dictionary["Introduce un correo electrónico al que enviar instrucciones."][session.user.language]}}
                    </p>
                  </div>
                </div>
                <div class="form-group ">
                  <div class="col-md-12 p-3">
                    <input v-model="formPasswordLost.email" placeholder="Email" id="inputPasswordLost"
                      class="p-2 text-black form-control" type="text" requiredclass="p-1 form-control">
                  </div>
                </div>
                <div class="form-group text-center m-t-20">
                  <div class="col-md-12 col-12 justify-content-center d-flex">
                    <button id="btnRePass" class="button-ok bg-clomos-black mt-1 rounded-5" type="button"
                      @click="onPasswordLost">
                      <p class="button-text tx-16 pt-2 mt-1">{{ dictionary["Recuperar contraseña"][session.user.language]}}</p>
                    </button>
                  </div>
                  <div class="col-md-12 justify-content-center d-flex mt-3">
                    <button id="btnBackToLogIn" @click="toggleRecoveryForm"
                      class="button-ok bg-clomos-black mt-1 rounded-5" type="button">
                      <p class="button-text tx-16 pt-2 mt-1">{{ dictionary["Volver"][session.user.language] }}</p>
                    </button>
                  </div>
                </div>
              </form>
              <!-- FORM Validar email -->
              <form v-if="visibility.formValidateClient" id="formValidateClient" class="form-horizontal">
                <div class="form-group ">
                  <div class="col-md-12 col-12 justify-content-center">
                    <h3 class="tx-black tx-14 mt-3">
                      {{ dictionary["Validar cuenta"][session.user.language] }}
                    </h3>
                  </div>
                </div>
                <div class="form-group ">
                  <div class="mb-2 col-md-12">
                    <p class="tx-black tx-13 mt-3">
                      {{ dictionary["Completa el siguiente formulario para proceder con tu validación como usuario."][session.user.language]}}
                    </p>
                  </div>
                </div>
                <div class="mt-2 p-3">
                  <div class="form-label-group">
                    <input v-model="formValidateClient.token" :placeholder="dictionary['Token'][session.user.language]"
                      id="inputToken" type="text" class="p-2 text-black form-control">
                    <label for="inputToken"></label>
                  </div>
                  <div class="form-label-group">
                    <input v-model="formValidateClient.password"
                      :placeholder="dictionary['Contraseña'][session.user.language]" id="tokenPass" type="password"
                      class="p-2 text-black form-control" required>
                    <label for="tokenPass"></label>
                  </div>
                  <div class="form-label-group">
                    <input v-model="formValidateClient.passwordRe"
                      :placeholder="dictionary['Repite la contraseña'][session.user.language]" id="tokenRePass"
                      type="password" required class="p-2 text-black form-control">
                    <label for="tokenRePass"></label>
                  </div>
                  <div class="col-md-12 col-12 justify-content-center d-flex mt-2">
                    <button id="btnValidarCuenta" class="button-ok bg-clomos-black mt-1 rounded-5" type="button"
                      @click="onValidateAccount">
                      <p class="button-text tx-16 pt-2 mt-1"> {{ dictionary["Validar cuenta"][session.user.language] }}
                      </p>
                    </button>
                  </div>
                  <div class="col-md-12 col-12 justify-content-center d-flex mt-3">
                    <button id="btnBackToLogIn" @click="toggleValidateClientForm" type="button"
                      class="button-ok bg-clomos-black mt-1 rounded-5">
                      <p class="button-text tx-16 pt-2 mt-1">{{ dictionary["Volver"][session.user.language] }}</p>
                    </button>
                  </div>
                </div>
              </form>

            </div>
          </div>
          <div class="login-box border-1 border-black box sombra-izq rounded-5 mt-3">
            <div class="card-body pt-2 p-3 rounded-5">
              <div class="col-md-12">
                <div class="ms-auto me-auto mt-3 form-group text-center rounded-5">
                  <h5 class="text-black text-uppercase">{{ dictionary["Seguimiento de pedido"][session.user.language] }}</h5>
                  <hr style="background-color: #fed54f; border:none; overflow: visible;   opacity: 1;    
                        height: 4px;
                      ">
                </div>
              </div>
              <div class="col-xs-12 justify-content-center d-flex mb-3">
                <button id="btnLogin" @click="goToTracking()" type="button" class="button-ok  mt-1 rounded-5">
                  <span>
                    <span class="highlight-bg"></span>
                    <span class="button-text">
                      {{dictionary["Buscar pedido"][session.user.language]}}
                    </span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>

      <!-- Pié -->
      <div class="row mt-5 pt-4 border-top border-black justify-content-around">
        <div
          class="col-lg-3 col-12">
          <div class="mb-2" >
            <img 
              src="https://landa-merkataritza.araba.eus/wp-content/uploads/elementor/thumbs/logo-next-generation-qkddho5k5s75luq79e139c9pk83j6wxi3oxo58bfae.png"
              title="Logo Next Generation EU" alt="Logo Next Generation EU" loading="lazy">
          </div>
        </div>
        <div
          class="col-lg-3 col-12">
          <div class="mb-2">
            <img
              src="https://landa-merkataritza.araba.eus/wp-content/uploads/elementor/thumbs/logo-mece-footer-qn4gxitwqkhp5nxysgu3t83ua2eqr8cqmvpkg29qte.png"
              title="logo-mece-footer" alt="logo-mece-footer" loading="lazy">
          </div>
        </div>
        <div
          class="col-lg-3 col-12">
          <div class="mb-2">
            <img
              src="https://landa-merkataritza.araba.eus/wp-content/uploads/elementor/thumbs/logo-PRTR-qkddfh73q5oq21l41b0y0azneglq9zq8hihy6lzlhm.png"
              title="Logo Plan de Recuperación, Transformación y Resiliencia"
              alt="Logo Plan de Recuperación, Transformación y Resiliencia" loading="lazy">
          </div>
        </div>
        <div
          class="col-lg-3 col-12">
          <div class="mb-2" >
            <img
              src="https://landa-merkataritza.araba.eus/wp-content/uploads/elementor/thumbs/logo-DFA-qkddbz8qrwenauzuhxgf5rz8285ikwc0niptiwr908.png"
              title="logo Diputación Foral de Alava" alt="logo Diputación Foral de Alava" loading="lazy">
          </div>
        </div>
      </div>
      <p class="tx-14 pt-3">©2024 Todos los derechos reservados. <a style="text-decoration: none !important; color:black !important; font-weight: 500 !important" href="https://web.araba.eus/es/aviso-legal&quot;" target="_blank">Aviso Legal</a> - <a style="text-decoration: none !important; color:black !important; font-weight: 500 !important" href="https://web.araba.eus/es/politica-de-privacidad" target="_blank">Política de Privacidad</a> - <a style="text-decoration: none !important; color:black !important; font-weight: 500 !important" href="https://web.araba.eus/es/accesibilidad" target="_blank">Accesibilidad</a> - <a style="text-decoration: none !important; color:black !important; font-weight: 500 !important" href="https://centinela.lefebvre.es/public/concept/1834447?access=48fp5sSMiQN8FTC62cVteVTU46KcYkxONFKwGn0LYAU%3D" target="_blank">Canal de denuncias</a></p>

    </div>
  </section>
</template>

<script>
// Clomos
import api from '@/services/api';

export default {
  name: "LogInView",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    }
  },
  data() {
    return {
      errorMsg: "",
      languages: ["es", "en", "eus"], //add here available languages
      formLogIn: {
        userId: localStorage.getItem("ClomosUserId") || "",
        password: "",
        rememberMe: localStorage.getItem("ClomosRememberMe") || true,
      },
      formPasswordLost: {
        email: "",
      },
      formValidateClient: {
        token: "",
        password: "",
        passwordRe: "",
      },
      visibility: {
        formLogIn: true,
        formPasswordLost: false,
        formValidateClient: false,
      }
    }
  },
  emits: ["logInRequest", "toggleLanguage"],
  methods: {
    goToTracking() {
      this.$router.push('/tracking');
    },
    navigateToUrl() {
      window.location.href = 'https://landa-merkataritza.araba.eus/'
    },
    // DISPLAY
    toggleLanguage(event) {

      if (event.target) {
      
        this.$emit('toggleLanguage', event.target.value);
      } else {

        this.$emit('toggleLanguage', event);
      }
    },
    toggleRecoveryForm() {
      if (this.visibility.formPasswordLost) {
        this.visibility.formPasswordLost = false;
        this.visibility.formValidateClient = false;
        this.visibility.formLogIn = true;
      }
      else {
        this.visibility.formPasswordLost = true;
        this.visibility.formValidateClient = false;
        this.visibility.formLogIn = false;
      }
    },
    toggleValidateClientForm() {
      if (this.visibility.formValidateClient) {
        this.visibility.formPasswordLost = false;
        this.visibility.formValidateClient = false;
        this.visibility.formLogIn = true;
      }
      else {
        this.visibility.formValidateClient = true;
        this.visibility.formPasswordLost = false;
        this.visibility.formLogIn = false;
      }
    },
    // GET
    onLogInRequest() {
      this.$emit('logInRequest', this.formLogIn, (response) => {
        if (response["status-code"] == undefined) {
          this.formLogIn.password = "";
          this.errorMsg = "Error de conexión.";
        }
        else if (response.session.user != undefined) {
          let path;
          const session = response.session;
          const user = session.user;
          const availableModules = session.authorisation.availableModules;          
          if (Object.keys(availableModules).length > 0) {            
            for (const moduleKey in availableModules) {
              const module = availableModules[moduleKey];              
              if (moduleKey === "smartLockers") {                
                for (const pageKey in module) {                  
                  if (pageKey === "lockings") {
                    path = `/${user.language}/${user.nifCif}/smartLockers/lockings/`;
                    this.$router.replace({ path: path });
                    return;
                  }
                }                
                const firstSubmodule = Object.keys(module)[0];
                path = `/${user.language}/${user.nifCif}/smartLockers/${firstSubmodule}`;
                this.$router.replace({ path: path });
                return;
              }
            }            
            const firstModuleKey = Object.keys(availableModules)[0];
            const firstModule = availableModules[firstModuleKey];
            const firstSubmoduleKey = Object.keys(firstModule)[0];
            path = `/${user.language}/${user.nifCif}/${firstModuleKey}/${firstSubmoduleKey}`;
          } else {            
            path = `/${user.language}/${user.nifCif}/general/profile/`;
          }       
          
          this.$router.replace({ path: path });
        }
        else if (response["status-code"] === "401") {
          this.formLogIn.password = "";
          this.errorMsg = "Credenciales incorrectas."
        }
        else if (response["status-code"] === "500") {
          this.formLogIn.password = "";
          this.errorMsg = "Error en el servidor.";
        }
        else {
          this.formLogIn.password = "";
          this.errorMsg = "Límite de tiempo agotado.";
        }
        clearTimeout(this.errorMsgTimeout);
        this.errorMsgTimeout = setTimeout(() => {
          this.errorMsg = "";
        }, 5000)
      });
    },
    // SET
    async onValidateAccount() {
      if (this.formValidateClient.password != this.formValidateClient.passwordRe) return;
      let response = await api.validateAccount(this.formValidateClient);
      if (response) {
        this.visibility.formPasswordLost = false;
        this.visibility.formValidateClient = false;
        this.visibility.formLogIn = true;
      }
      else {
        this.formValidateClient.password = "";
        this.formValidateClient.passwordRe = "";
      }
    },
    async onPasswordLost() {
      let response = await api.passwordLost(this.formPasswordLost.email);
      if (response) {
        this.visibility.formPasswordLost = false;
        this.visibility.formValidateClient = false;
        this.visibility.formLogIn = true;
      }
    },
  }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.background_Alava {
  background-image: url("@/assets/img/inea-separacion-amarilla-gr.png");
  background-position-x: 50%;
  background-position-y: 50%;
  background-repeat: repeat-x;
}

.fontAdn {
  font-family: 'Montserrat', 'Segoe UI', sans-serif !important;
}

.language {
  cursor: pointer;
}

.language:hover {
  color: #FED54F !important;
}

#btnLogin:hover {
  color: black !important;
  background-color: #FED54F !important;
  border-color: #FED54F !important;
}

@media (max-width: 1000px) {
  #topMenu {
    border-bottom: none !important;
  }
}
</style>
